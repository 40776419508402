import { createReducer, Action, on } from '@ngrx/store';
import { EventRequestSingleType } from '../../shared/services/event-request/event-request.type';
import { eventActions } from '../actions/event.actions';

export const EVENT_FEATURE_KEY = 'event';

export interface EventState {
  selectedEvent: EventRequestSingleType;
}

export const initialState: EventState = {
  selectedEvent: undefined
};

const reducer = createReducer(
  initialState,
  on(eventActions.SetEventSuccess, (state, { payload }) => ({
    ...state,
    selectedEvent: payload
  })),
  on(eventActions.SetEventFailure, state => ({
    ...state,
    selectedEvent: null
  })),
  on(eventActions.GetEvent, state => ({
    ...state,
    selectedEvent: null
  }))
);

export function eventReducer(state: EventState, action: Action): EventState {
  return reducer(state, action);
}
