import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ScrollStrategyOptions } from '@angular/cdk/overlay';
import { first } from 'rxjs';
import { NotificationsDialogComponent } from '../../../../shared/components/dialogs/notifications-dialog/notifications-dialog.component';
import { DialogComponent } from '../../../../shared/components/dialogs/dialog-base/dialog.component';
import { FcmNotificationsService } from '../../../../shared/services/fcm-notifications/fcm-notifications.service';
import { DialogType } from '../../../../shared/models/dialogs';
import { AppState } from '../../../../store/reducers/app.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-quote-request-container',
  template: '<router-outlet></router-outlet>'
})
export class QuoteRequestContainer implements OnInit {
  constructor(
    public dialog: MatDialog,
    private sso: ScrollStrategyOptions,
    private _fcmNotificationsService: FcmNotificationsService,
    private _store: Store<AppState>
  ) {}

  ngOnInit(): void {
    switch (window.Notification?.permission) {
      case 'default':
        this.openNotificationsDialog();
        break;
      case 'granted':
        this.updateToken();
        break;
    }
  }

  /**
   * Get the latest FCM token and update it in the RTDB.
   */
  private updateToken() {
    if (window.sessionStorage.getItem('fcmTokenSent')) {
      return;
    }

    this._fcmNotificationsService
      .registerForNotifications()
      .pipe(first())
      .subscribe({
        next: () => {
          // Set in Session Storage in order to persist within the current session.
          window.sessionStorage.setItem('fcmTokenSent', 'true');
        },
        error: err => {
          console.error('Error updating FCM token', err);
        }
      });
  }

  /**
   * @todo Only open the dialog if the cookie has not been set or.
   */
  private openNotificationsDialog() {
    if (!this._fcmNotificationsService.shouldDisplayModal()) {
      return;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '500px',
      height: 'auto',
      maxWidth: '95vw',
      maxHeight: '550px',
      hasBackdrop: true,
      panelClass: 'confirmation-dialog',
      scrollStrategy: this.sso.noop(),
      data: {
        type: DialogType.NOTIFICATION,
        modalComponentRef: NotificationsDialogComponent
      }
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe({
        error: err => {
          console.error(err);
        }
      });
  }
}
