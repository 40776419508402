import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, HostListener, Input, OnInit } from '@angular/core';
import { InfoTagListComponent } from '../components/info-tag-list/info-tag-list.component';
import { FirebaseFeatureFlags } from '../../shared/enums/firebase-custom-claims.enum';
import { InvitesListing } from '../../shared/models/invites-list.interface';

@Component({
  selector: 'app-invite-carousel-slide',
  templateUrl: './invite-carousel-slide.component.html',
  styleUrls: ['./invite-carousel-slide.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, InfoTagListComponent]
})
export class InviteCarouselSlideComponent implements OnInit {
  FirebaseFeatureFlags = FirebaseFeatureFlags;
  innerHeight: number;
  innerWidth: number;
  serviceTags: string[];

  @Input() invite: InvitesListing;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  constructor() {
    this.onResize();
  }

  ngOnInit(): void {
    this.serviceTags = this.invite?.services.map(service => service.title);
  }
}
