import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceListService } from './services/services-list/service-list.service';
import { NewEventRoutingModule } from './new-request-routing.module';
import { NewRequestComponent } from './components/new-request/new-request.component';

@NgModule({
  imports: [CommonModule, NewEventRoutingModule, NewRequestComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NewRequestComponent],
  providers: [ServiceListService]
})
export class NewEventModule {}
