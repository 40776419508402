/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { timer, Observable } from 'rxjs';
import { delayWhen, filter, tap } from 'rxjs/operators';
import { FlashMessageService } from 'src/app/shared/services/flash-message/flash-message.service';
import { appActions } from '../actions/app.actions';
import { AppState } from '../reducers/app.reducer';
import { Store } from '@ngrx/store';
import { WindowService } from '../../shared/services/window/window.service';

@Injectable()
export class ApplicationEffects {
  constructor(
    private actions$: Actions,
    private _flashMessageService: FlashMessageService,
    private _store: Store<AppState>,
    private _windowService: WindowService
  ) {}

  /**
   * Hides a flash message after it's given timeout
   */
  hideFlashMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.SetFlashMessage),
        delayWhen((action: any): Observable<number> => {
          return timer(action.flashMessage.timeout);
        }),
        tap((action: any): void => {
          if (action.flashMessage.timeout || action.flashMessage.timeout > 0) {
            this._flashMessageService.hide();
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Hide the sidebar if the Notification Menu is opened.
   */
  hideSidebar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.ToggleShowingNotificationsMenu),
        filter(({ payload }) => !!payload && this._windowService.isMobile()),
        tap((): void => {
          this._store.dispatch(appActions.ToggleShowingSidenav({ payload: false }));
        })
      ),
    { dispatch: false }
  );

  /**
   * Hide the sidebar if the Notification Menu is opened.
   */
  hideNotificationMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.ToggleShowingSidenav),
        filter(({ payload }) => !!payload && this._windowService.isMobile()),
        tap((): void => {
          this._store.dispatch(appActions.ToggleShowingNotificationsMenu({ payload: false }));
        })
      ),
    { dispatch: false }
  );
}
