import { createAction, props } from '@ngrx/store';
import { QuoteResponse } from '../../features/quotes/services/quote.type';

const GetQuote = createAction('[Quotes] Get Quote', props<{ payload: { id: string; queryParams?: { [key: string]: string } } }>());

const SetQuoteSuccess = createAction('[Quotes] Set Quote Success', props<{ payload: QuoteResponse }>());

const SetQuoteFailure = createAction('[Quotes] Set Quote Failure');

const QuotesAccepted = createAction(
  '[Quotes] Quote Accepted',
  props<{
    payload: {
      ids: string[];
      acceptReasons?: string[];
      keepRequestOpen?: boolean;
    };
  }>()
);

const UndoQuoteAccepted = createAction('[Quotes] Undo Quote Accepted', props<{ payload: { id: string } }>());

const UndoQuoteDeclined = createAction('[Quotes] Undo Quote Declined', props<{ payload: { id: string } }>());

const QuotesDeclined = createAction('[Quotes] Quote Declined', props<{ payload: { ids: string[]; declineReason: string } }>());

const MessageSent = createAction('[Quotes] Message Sent');

export const quotesActions = {
  SetQuoteSuccess,
  SetQuoteFailure,
  QuotesAccepted,
  QuotesDeclined,
  MessageSent,
  GetQuote,
  UndoQuoteAccepted,
  UndoQuoteDeclined
};
