import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  @Input() message: {
    uuid?: string;
    date: string;
    message?: string;
    isUser?: boolean;
    profilePicture?: string;
    profileShortName?: string;
    showProfile?: boolean;
  };
}
