<div #servicesElement class="tw-flex tw-flex-wrap tw-gap-3 tw-overflow-hidden">
  <!--  <app-info-tag class="tw-font-semibold tw-text-slate-800" *ngFor="let tag of tags" [text]="tag"></app-info-tag>-->
  <div
    class="tw-flex tw-h-[20px] tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-slate-300 tw-bg-slate-50 tw-px-3 tw-py-1"
    *ngFor="let tag of reducedTags; let last = last"
    [class.link-item]="last && reducedTags.length > maxServices"
    (click)="last && reducedTags.length > maxServices ? showAllServices() : false"
    [matTooltip]="last && reducedTags.length > maxServices ? 'View all services' : ''">
    <p class="tw-m-0 tw-font-semibold tw-text-gray-800" [class.tw-text-[12px]]="innerWidth < 1200">{{ tag }}</p>
  </div>
</div>
