<div
  *ngIf="visible"
  class="tw-flex tw-h-fit tw-flex-col tw-items-start tw-justify-center tw-gap-5 tw-self-stretch tw-rounded-xl tw-border tw-border-solid tw-border-amber-300 tw-bg-amber-100 tw-p-4 tw-px-4 md:tw-flex-row md:tw-justify-between">
  <div class="tw-flex tw-items-start tw-gap-4 tw-self-stretch md:tw-flex-[1_0_0] md:tw-items-center">
    <div class="tw-flex tw-flex-[1_0_0] tw-items-start tw-gap-3">
      <div class="tw-size-6"><ate-icon class="tw-size-6" name="info-v2"></ate-icon></div>
      <section class="tw-self-stretch">
        <h3 *nfIg="title" class="tw-mb-2 tw-text-base tw-font-bold tw-text-slate-800">{{ title }}</h3>
        <ng-content></ng-content>
      </section>
    </div>
    <div *ngIf="showDismissButton" class="tw-flex tw-size-3.5 tw-items-center tw-justify-center tw-p-[5px] md:tw-hidden" (click)="visible = false">
      <ate-icon class="tw-size-5 tw-flex-shrink-0" name="close-v2"></ate-icon>
    </div>
  </div>
  <div *ngIf="linkHref && linkText" class="tw-flex tw-items-start tw-self-stretch">
    <a [href]="linkHref" target="_blank" class="btn-action btn-action-sm btn-primary tw-flex-[1_0_0]">
      {{ linkText }}
    </a>
  </div>
  <div class="tw-flex tw-items-start tw-self-stretch" *ngIf="showNewRequestButton">
    <button (click)="openRequestQuotes()" class="gradient-button !tw-mt-0 tw-flex-[1_0_0]">
      <ate-icon class="tw-h-5 tw-w-5 tw-shrink-0" name="plus-v3"></ate-icon>Submit New Request
    </button>
  </div>
  <div
    *ngIf="showDismissButton"
    class="tw-hidden tw-size-3.5 tw-cursor-pointer tw-items-center tw-justify-center tw-p-[5px] md:tw-flex md:tw-self-center"
    (click)="visible = false">
    <ate-icon class="tw-size-5 tw-flex-shrink-0" name="close-v2"></ate-icon>
  </div>
</div>
