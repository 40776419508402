/* eslint-disable @typescript-eslint/no-explicit-any */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { defineCustomElements as components } from '@addtoevent/web-components/dist/loader';
import { defineCustomElements as icons } from '@addtoevent/icons/dist/loader';

import * as Sentry from '@sentry/angular-ivy';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: environment.sentry.tracePropagationTargets,
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 1.0,
  environment: environment.production ? 'production' : 'uat',
  release: environment.version
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

components(window);
icons(window);

/**
 * Extending the global to allow for stricter typing.
 */
declare global {
  interface Window {
    ateV2OpenForm: (formLaunchConfig: { [key: string]: any }) => void;
  }
}
