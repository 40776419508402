import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxComponent } from './lightbox/lightbox.component';
import { LightboxImageComponent } from './lightbox/lightbox-image/lightbox-image.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemComponent } from './carousel/carousel-item/carousel-item.component';

@NgModule({
  declarations: [LightboxComponent, LightboxImageComponent, CarouselComponent, CarouselItemComponent],
  exports: [LightboxComponent, LightboxImageComponent, CarouselComponent, CarouselItemComponent],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CarouselLightboxModule {}
