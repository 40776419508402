import { Component, Input } from '@angular/core';
import { StatusConfig } from '../../models/misc';

@Component({
  selector: 'app-status-indicator',
  templateUrl: './status-indicator.component.html',
  styleUrls: ['./status-indicator.component.scss']
})
export class StatusIndicatorComponent {
  @Input() statusConfig: StatusConfig;
}
