import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { EventRequestColorEnum } from '../../../../../ui/components/status-pill/status-pill-color.enum';
import { WindowService } from '../../../../../shared/services/window/window.service';
import { QuoteBodyType } from '../../quote-body/quote-body.type';
import { QuoteResponse } from '../../../services/quote.type';
import { SupplierListingModalComponent } from '../../../../../shared/standalone/modals/supplier-listing-modal/supplier-listing-modal.component';
import { QuoteStatus } from '../../../../../api';

@Component({
  selector: 'app-quote-request-desktop',
  templateUrl: './quote-request-desktop.component.html',
  styleUrls: ['./quote-request-desktop.component.scss']
})
export class QuoteRequestDesktopComponent {
  @HostListener('window:resize')
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
  }

  innerHeight: number;
  innerWidth: number;

  @Input() public quote: QuoteResponse;
  @Input() public quoteBody: QuoteBodyType;
  @Input() public statusText = '';
  @Input() public statusColor: EventRequestColorEnum;
  @Input() public quoteUuid: string;
  @ViewChild(SupplierListingModalComponent) supplierListingModalComponent: SupplierListingModalComponent;

  constructor(
    public windowService: WindowService,
    private _router: Router
  ) {
    this.onResize();
  }

  goBack(): void {
    if (this.quote.quoteStatus === QuoteStatus.ACCEPTED_FUTURE || this.quote.quoteStatus === QuoteStatus.ACCEPTED_PAST) {
      this._router.navigateByUrl(`event-request/${this.quote.event.eventUuid}/${this.quote.event.eventRequestUuid}/bookings`);
    } else {
      this._router.navigateByUrl(`event-request/${this.quote.event.eventUuid}/${this.quote.event.eventRequestUuid}/quotes`);
    }
  }

  /**
   * Triggers a phone call to the number provided.
   */
  public callPhoneNumber() {
    if (!this.quote?.listing?.phoneNumber) {
      return;
    }
    this.windowService.open(`tel:${this.quote?.listing?.phoneNumber}`);
  }

  public goToListing(): void {
    this.supplierListingModalComponent.open();
  }
}
