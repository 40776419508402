import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState, USER_FEATURE_KEY } from '../reducers/user.reducer';
import { NotificationType } from '../../shared/enums/notification-type.enum';

const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const getUserDetails = createSelector(getUserState, state => state?.details);

const getUserNotifications = createSelector(getUserState, state => state?.notifications);

const getNewQuoteNotifications = createSelector(getUserState, state =>
  state?.notifications.filter(notification => notification.type === NotificationType.NEW_QUOTE)
);

const getInterestedState = createSelector(getUserState, state => state?.hasInterests);

export const userQuery = {
  getUserNotifications,
  getUserDetails,
  getNewQuoteNotifications,
  getInterestedState
};
