import { Component, Inject, Input } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { EventOverviewPageConfig } from '../../models/event-overview-page-config.interface';
import { MatDialog } from '@angular/material/dialog';
import { ChangeParentEventModalComponent } from '../../standalone/modals/change-parent-event-modal/change-parent-event-modal.component';
import { IMainEventData } from '../../models/events';

@Component({
  selector: 'app-event-request',
  templateUrl: './event-request-sheet.component.html',
  styleUrls: ['./event-request-sheet.component.scss']
})
export class EventRequestSheetComponent {
  @Input() public iconName = '';

  constructor(
    private _router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      iconName: string;
      route: ActivatedRoute;
      config: EventOverviewPageConfig;
      eventUuid: string;
      eventDate: string;
      title: string;
      events: IMainEventData[];
    },
    private _bottomSheetRef: MatBottomSheetRef<EventRequestSheetComponent>,
    public dialog: MatDialog
  ) {}

  openEventRequestDetails(): void {
    this.close();
    this._router.navigate(['details'], { relativeTo: this.data.route });
  }

  openCloseEventRequest(): void {
    this.close();
    this._router.navigate([{ outlets: { modalOutlet: ['close'] } }], { relativeTo: this.data.route });
  }

  close() {
    this._bottomSheetRef.dismiss();
  }

  openChangeParentEventModal() {
    this.close();
    this.dialog.open(ChangeParentEventModalComponent, {
      panelClass: 'change-parent-event-dialog',
      width: '488px',
      maxWidth: '100vw',
      data: {
        eventUuid: this.data.eventUuid,
        eventDate: this.data.eventDate,
        title: this.data.title,
        events: this.data.events
      }
    });
  }
}
