<section *ngIf="message?.uuid" class="message-box" [ngClass]="{ isUser: message.isUser }">
  <div [innerHTML]="message.message | linebreak"></div>

  <section class="meta">
    <span>{{ message.date }}</span>
    <app-profile-picture
      class="small"
      *ngIf="message.showProfile"
      [shortName]="message.profileShortName"
      [image]="message.profilePicture"
      [isUser]="message.isUser"></app-profile-picture>
  </section>
</section>

<p *ngIf="!message?.uuid" class="date-row">{{ message.date }}</p>
