/* eslint-disable @typescript-eslint/no-explicit-any */
import { catchError, timeout } from 'rxjs/operators';
import { Observable, TimeoutError, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import * as Sentry from '@sentry/angular-ivy';
import { ErrorHandlerService } from '../services/errors/error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsInterceptor implements HttpInterceptor {
  private readonly ignoreStatusCodes: number[] = [304, 422, 401, 0];

  constructor(private _errorHandlerService: ErrorHandlerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      timeout(30000),
      catchError<HttpEvent<any>, any>(error => {
        console.error('ERROR', error);
        return this.handleHttpError(req, error);
      })
    );
  }

  private handleHttpError(req: HttpRequest<any>, error: any): Observable<any> {
    this._errorHandlerService.handleError(error?.status);

    if (error instanceof HttpErrorResponse && !this.ignoreStatusCodes.includes(error.status)) {
      Sentry.captureMessage(error.message);
    }

    if (error instanceof TimeoutError) {
      Sentry.captureMessage(`Requests to: ${req.url} timed out`);
    }

    return throwError(() => error);
  }
}
