<header>
  <div class="title">
    <ate-icon class="icon-button" *ngIf="showBackButton" (click)="goBack.emit()" name="chevron-left-v2"></ate-icon>
    <app-profile-picture
      [image]="(quote?.listing?.images)[0] ?? '' | replace: '{style}' : 'square_thumbnail'"
      [shortName]="quote?.listing?.user | shortname"></app-profile-picture>

    <section class="listing-headline" data-cy="listing-headline">
      <h1 (click)="goToListing()">
        {{ quote?.listing.title }}
        <ate-icon name="chevron-right-v2"></ate-icon>
      </h1>
      <p *ngIf="showReviews" class="review-rating">
        <ate-star-bar color="orange" [attr.percent]="quote?.listing.reviewRating * 20"></ate-star-bar>
        ({{ quote?.listing?.reviewCount }})
      </p>
    </section>
    <ng-content select="[slot=context-menu]"></ng-content>
  </div>
  <ng-content class="test" select="[slot=status]"></ng-content>
</header>
