import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EventRequestColorEnum } from '../../../../../ui/components/status-pill/status-pill-color.enum';
import { QuoteBodyType } from '../../quote-body/quote-body.type';
import { QuoteResponse } from '../../../services/quote.type';
import { QuoteStatus } from '../../../../../api';
import { appActions } from '../../../../../store/actions/app.actions';
import { AppState } from '../../../../../store/reducers/app.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-quote-request-mobile',
  templateUrl: './quote-request-mobile.component.html',
  styleUrls: ['./quote-request-mobile.component.scss']
})
export class QuoteRequestMobileComponent implements OnInit {
  @Input() public quote: QuoteResponse;
  @Input() public quoteBody: QuoteBodyType;
  @Input() public statusText = '';
  @Input() public statusColor: EventRequestColorEnum;
  @Input() public quoteUuid: string;
  @Input() status: QuoteStatus;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this._store.dispatch(appActions.ToggleShowingSidenav({ payload: false }));
  }

  goBack(): void {
    if (this.quote.quoteStatus === QuoteStatus.ACCEPTED_FUTURE || this.quote.quoteStatus === QuoteStatus.ACCEPTED_PAST) {
      this._router.navigateByUrl(`event-request/${this.quote.event.eventUuid}/${this.quote.event.eventRequestUuid}/bookings`);
    } else {
      this._router.navigateByUrl(`event-request/${this.quote.event.eventUuid}/${this.quote.event.eventRequestUuid}/quotes`);
    }
  }

  goToListing(): void {
    this._router.navigate(['listing', this.quote?.listing?.uuid], {
      relativeTo: this.route
    });
  }
}
