/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { IMessage } from './message/message.type';
import { AuthUser } from '../../../store/models';
import { first, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { authenticationQuery } from '../../../store/selectors/authentication.selectors';
import { format, isSameDay } from 'date-fns';
import { QuoteListing } from '../../../features/quotes/services/quote.type';
import { ChatService } from '../services/chat/chat.service';
import { userQuery } from '../../../store/selectors/user.selectors';

interface FormattedMessage {
  uuid?: string;
  date: string;
  message?: string;
  isUser?: boolean;
  profilePicture?: string;
  profileShortName?: string;
  showProfile?: boolean;
}

@Component({
  selector: 'app-messages-panel',
  templateUrl: './messages-panel.component.html',
  styleUrls: ['./messages-panel.component.scss']
})
export class MessagesPanelComponent implements OnInit {
  @Input() quoteUuid: string;
  @Input() listing: QuoteListing;
  public formattedMessages: Observable<FormattedMessage[]>;

  constructor(
    private _chatService: ChatService,
    private _store: Store
  ) {}

  ngOnInit(): void {
    this.formattedMessages = this.setUpChat$().pipe(map(value => value[1]));
  }

  public setUpChat$(): Observable<[AuthUser, FormattedMessage[]]> {
    const userDetails$ = this._store.select(userQuery.getUserDetails);
    return this._store.select(authenticationQuery.getUser).pipe(
      first(),
      switchMap(user => {
        return combineLatest([userDetails$, of(user)]).pipe(
          switchMap(([userDetails, user]: [any, any]) => {
            return this._chatService.getChatThread(this.quoteUuid).pipe(
              map(([messages]: [IMessage[], string[]]): [AuthUser, FormattedMessage[]] => [
                user as unknown as AuthUser,
                this.mapMessages(
                  { ...user, photoURL: userDetails?.profileImageUrl },
                  messages.filter(message => message.text),
                  this.listing?.title
                )
              ])
            );
          })
        );
      })
    );
  }

  public mapMessages(user: AuthUser, messages: IMessage[], listingTitle: string): FormattedMessage[] {
    const formattedMessages = messages.map((message: IMessage): FormattedMessage => {
      const timestampDate = new Date(0); // Set Date to the dawn of time eg: 1970
      if (typeof message.timestamp === 'number') {
        timestampDate.setUTCMilliseconds(message.timestamp);
      }

      const profileName = message.userId === user.userId ? user.displayName : listingTitle;

      const formatted: FormattedMessage = {
        uuid: message.id,
        message: message.text,
        isUser: message.userId === user.userId,
        profilePicture: message.userId === user.userId ? user.photoURL : this.listing.profileImage,
        date: timestampDate.toISOString(),
        profileShortName: this.formatName(profileName),
        showProfile: false
      };

      return formatted;
    });

    const messagesWithDates = [];

    if (formattedMessages.length) {
      // add first date row
      messagesWithDates.push({
        date: format(new Date(formattedMessages[0].date), 'EEE d MMM')
      });
    }

    // add date lines and handle if profile picture should be shown
    formattedMessages.forEach((message, index) => {
      let messageAfter: FormattedMessage;
      let messageBefore: FormattedMessage;
      if (formattedMessages.length - 1 !== index) {
        messageAfter = formattedMessages[index + 1];
      }

      if (index !== 0) {
        messageBefore = formattedMessages[index - 1];
      }

      const currentMessage: FormattedMessage = {
        ...message
      };

      if (messageBefore && !isSameDay(new Date(messageBefore.date), new Date(message.date))) {
        messagesWithDates.push({
          date: format(new Date(message.date), 'EEE d MMM')
        });
      }

      if (!messageAfter || messageAfter.isUser !== message.isUser) {
        currentMessage.showProfile = true;
      }

      currentMessage.date = format(new Date(message.date), 'HH:mm');

      messagesWithDates.push(currentMessage);
    });

    return messagesWithDates;
  }

  formatName(name: string): string {
    if (!name) return name;

    // get each word, get the first letter and only the first two words
    return name
      .split(' ')
      .map(word => word[0])
      .slice(0, 2)
      .join('');
  }
}
