import { createAction, props } from '@ngrx/store';
import { FlashMessage } from '../models/flash-message.model';
import { FirebaseFeatureFlags } from '../../shared/enums/firebase-custom-claims.enum';
import { IMainEventData } from '../../shared/models/events';

const IncrementLoadingCount = createAction('[App] Increment Loading Count');

const DecrementLoadingCount = createAction('[App] Decrement Loading Count');

const ToggleShowingFlashMessage = createAction('[App] Toggle Showing Flash Message', props<{ payload: boolean }>());

export const SetFlashMessage = createAction('[App] Set Flash Message', props<{ flashMessage: FlashMessage }>());

export const SetFeatureFlags = createAction('[App] Set Feature Flags', props<{ featureFlags: FirebaseFeatureFlags[] }>());

export const ToggleShowingNotificationsMenu = createAction('[App] Toggle Notifications Menu', props<{ payload: boolean }>());

export const ToggleShowingSidenav = createAction('[App] Toggle Sidenav', props<{ payload: boolean }>());

export const ToggleShowingHeader = createAction('[App] Toggle Header', props<{ payload: boolean }>());

const RemoveFlashMessage = createAction('[App] Remove Flash Message');

export const SetEvents = createAction('[App] Set Events', props<{ events: IMainEventData[] }>());

export const appActions = {
  IncrementLoadingCount,
  DecrementLoadingCount,
  ToggleShowingFlashMessage,
  SetFlashMessage,
  SetFeatureFlags,
  RemoveFlashMessage,
  ToggleShowingNotificationsMenu,
  ToggleShowingSidenav,
  ToggleShowingHeader,
  SetEvents
};
