/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { IFeatureFlagConfig } from '../interceptors/feature-flag.interceptor';

@Injectable()
export class FeatureFlagConfigService {
  constructor(@Inject('environment') private environment) {}

  public getUrl(req: HttpRequest<any>): Observable<IFeatureFlagConfig> {
    // CANCEL REQUEST COUNT IF FEATURE FLAG ENABLED
    if (this.isListRequestCount(req.url)) {
      return of({
        url: '',
        urlChanged: true,
        cancelled: true
      });
    }

    // return new features
    return of({ url: req.url, urlChanged: false });
  }

  private isListRequestCount(url: string): boolean {
    return url.includes('cac/request-inbox-view') && url.includes('count');
  }
}
