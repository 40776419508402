<section>
  <header class="animated fadeIn">
    <div class="tw-box-border tw-max-w-[1280px]">
      <h1 class="tw-text-2xl tw-font-bold tw-text-slate-800">{{ title }}</h1>
    </div>
  </header>

  <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    (selectedIndexChange)="tabSelected($event)"
    disablePagination=""
    animationDuration="0"
    [mat-stretch-tabs]="isMobile"
    disableRipple="">
    <mat-tab *ngFor="let item of quotesConfig" [showIndicator]="item.indicator && (hasNewQuotes$ | async)">
      <ng-template mat-tab-label>
        {{ item.title }}
        <span
          *ngIf="(hasNewQuotes$ | async) && item.title === QuoteTabEnum.ACTIVE"
          class="tw-ml-2 tw-mt-0.5 tw-size-2 tw-grow-0 tw-rounded-full tw-bg-brand-maroon"></span>
      </ng-template>
      <div
        *ngIf="(isLoading | async) === false && !isError && quotes && quotes.length"
        class="tw-grid tw-gap-5 tw-p-5 md:tw-grid-cols-2 md:tw-p-6 xl:tw-grid-cols-3 xl:tw-p-8">
        <app-quote-tile
          *ngFor="let quote of quotes; let i = index"
          [tabIndex]="i + 1"
          [quote]="quote"
          class="animated fadeIn"
          (click)="goToQuote(quote)"></app-quote-tile>
      </div>
      <div class="tw-flex tw-justify-center">
        <app-utility-message
          *ngIf="(isLoading | async) === false && (!quotes || isError || (quotes && !quotes.length))"
          [config]="emptyStateConfig"
          class="tw-m-5 tw-box-border tw-flex !tw-w-auto tw-max-w-[1280px] tw-flex-auto tw-flex-wrap tw-justify-start tw-gap-5 tw-overflow-hidden tw-rounded-xl tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-5 tw-py-6 md:tw-px-12 md:tw-py-20"></app-utility-message>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
