import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { MessageComponent } from './messages-panel/message/message.component';
import { MessagesPanelComponent } from './messages-panel/messages-panel.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { ChatComponent } from './chat.component';
import { ChatService } from './services/chat/chat.service';
import { TextAutoGrowDirective } from './directives/textareaAutoGrow/text-auto-grow.directive';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [MessageComponent, MessagesPanelComponent, SendMessageComponent, ChatComponent, TextAutoGrowDirective],
  exports: [ChatComponent, SendMessageComponent, MessagesPanelComponent],
  imports: [CommonModule, SharedModule, ComponentsModule],
  providers: [ChatService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChatModule {}
