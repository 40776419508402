import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotesRoutingModule } from './quotes-routing.module';
import { SharedModule } from '../../shared/shared.module';

import { ContainerModule } from '../../ui/containers/container.module';
import { ComponentsModule } from '../../ui/components/components.module';
import { ChatModule } from '../../ui/chat/chat.module';
import { CarouselLightboxModule } from '../../ui/carousel-lightboxes/carousel-lightbox.module';
import { QuoteBodyComponent } from './components/quote-body/quote-body.component';
import { QuoteEventsComponent } from './components/quote-events/quote-events.component';
import { DownloadFileComponent } from './components/download-file/download-file.component';
import { QuoteAccordionComponent } from './components/quote-accordion/quote-accordion.component';
import { StatusInfoComponent } from './components/quote-status-info/status-info.component';
import { EffectsModule } from '@ngrx/effects';
import { QuotesEffects } from '../../store/effects/quotes.effects';
import { QuoteRequestDesktopComponent } from './components/quote-request/quote-request-desktop/quote-request-desktop.component';
import { QuoteRequestMobileComponent } from './components/quote-request/quote-request-mobile/quote-request-mobile.component';
import { QuoteRequestComponent } from './components/quote-request/quote-request.component';
import { QuoteRequestContainer } from './components/quote-request/quote-request.container';
import { QuoteContextMenuComponent } from './components/quote-context-menu/quote-context-menu.component';
import { AcceptQuoteModalComponent } from '../../shared/standalone/modals/accept-quote-modal/accept-quote-modal.component';
import { SupplierListingModalComponent } from '../../shared/standalone/modals/supplier-listing-modal/supplier-listing-modal.component';
import { DeclineQuoteModalComponent } from '../../shared/standalone/modals/decline-quote-modal/decline-quote-modal.component';
import { RequestActionsComponent } from '../../shared/standalone/request-actions/request-actions.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { EventModule } from '../event-request/event.module';
import { QuoteTileComponent } from './components/tiles/quote-tile/quote-tile.component';
import { InviteTileComponent } from './components/tiles/invite-tile/invite-tile.component';
import { MaterialModule } from '../../shared/material/material.module';

@NgModule({
  declarations: [
    QuoteRequestComponent,
    QuoteRequestMobileComponent,
    QuoteRequestDesktopComponent,
    QuoteBodyComponent,
    QuoteEventsComponent,
    DownloadFileComponent,
    QuoteAccordionComponent,
    StatusInfoComponent,
    QuoteRequestContainer,
    QuoteContextMenuComponent,
    QuotesComponent
  ],
  imports: [
    CommonModule,
    QuotesRoutingModule,
    SharedModule,
    ContainerModule,
    ComponentsModule,
    ChatModule,
    CarouselLightboxModule,
    EffectsModule.forFeature([QuotesEffects]),
    AcceptQuoteModalComponent,
    DeclineQuoteModalComponent,
    SupplierListingModalComponent,
    RequestActionsComponent,
    EventModule,
    QuoteTileComponent,
    InviteTileComponent,
    MaterialModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class QuotesModule {}
