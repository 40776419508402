import { createReducer, on } from '@ngrx/store';
import { SetInterestedState, SetUserDetails, SetUserNotifications } from '../actions/user.actions';
import { IUserNotification } from '../../shared/services/notifications/notification.interface';

export const USER_FEATURE_KEY = 'user';

export interface AuthenticationPartialState {
  readonly [USER_FEATURE_KEY]: UserState;
}

export interface UserState {
  details: {
    isSupplier: boolean;
    profileImageUrl: string;
  };
  notifications: IUserNotification[];
  hasInterests: boolean;
}

export const initialState: UserState = {
  // We don't know if the user is a supplier until we check if they have listings.
  details: undefined,
  notifications: [],
  hasInterests: false
};

export const userReducer = createReducer(
  initialState,
  on(SetUserDetails, (state, { payload }) => {
    return {
      ...state,
      details: payload
    };
  }),
  on(SetUserNotifications, (state, { payload }) => {
    return {
      ...state,
      notifications: payload
    };
  }),
  on(SetInterestedState, (state, { payload }) => {
    return {
      ...state,
      hasInterests: payload
    };
  })
);
