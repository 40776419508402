import { OrganiserInviteStatus } from '../../../../../shared/enums/organiser-invite-status.enum';
import { EnhancedStatusPillConfig } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill-config.interface';

export const InviteStatusConfig: { [key in OrganiserInviteStatus]: EnhancedStatusPillConfig } = {
  [OrganiserInviteStatus.DECLINED]: {
    statusText: 'Supplier has declined your invite',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: ''
  },
  [OrganiserInviteStatus.EVENT_CLOSED_REASON]: {
    statusText: 'This supplier invite has expired',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'You have cancelled the request'
  },
  [OrganiserInviteStatus.EVENT_EXPIRED]: {
    statusText: 'This supplier invite has expired',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'Suppliers can no longer quote'
  },
  [OrganiserInviteStatus.EVENT_PASSED]: {
    statusText: 'This supplier invite has expired',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'The event has passed'
  },
  [OrganiserInviteStatus.LIVE]: {
    statusText: 'Supplier invited to send a quote',
    iconName: 'hearts',
    iconColour: 'tw-text-blue-700',
    textColour: 'tw-text-blue-800',
    backgroundColour: 'tw-bg-blue-50',
    secondaryText: ''
  }
};
