/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren
} from '@angular/core';
import { ReplaySubject, catchError, Observable, tap, throwError, first } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { CommonModule } from '@angular/common';
import { QuoteStatus } from '../../../../api';
import { EventRequestService } from '../../../services/event-request/event-request.service';
import { EventRequestSingleType, EventRequestQuotes } from '../../../services/event-request/event-request.type';
import { ComponentsModule } from '../../../../ui/components/components.module';
import { ListingItem } from '../../../../ui/components/listing-list/listing-list-item/listing-list-item.component';
import { QuoteActionType } from '../../../enums/quote-action-type.enum';
import { SharedModule } from '../../../shared.module';
import { Store } from '@ngrx/store';
import { ModalTypeEnum } from '../../../enums/modal-type.enum';
import { QuotesState } from '../../../../store/reducers/quotes.reducer';

@Component({
  selector: 'app-close-event-request-modal',
  templateUrl: './close-event-request-modal.component.html',
  styleUrls: ['./close-event-request-modal.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, SharedModule, ComponentsModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloseEventRequestModalComponent implements OnInit, AfterViewInit {
  @ViewChildren('footerStep0, footerStep1, footerStep2, footerStep3, footerStep4', {
    read: TemplateRef
  })
  public footerTemplates: QueryList<TemplateRef<any>>;

  @ViewChildren('bodyStep0, bodyStep1, bodyStep2, bodyStep3, bodyStep4', {
    read: TemplateRef
  })
  public bodyTemplates: QueryList<TemplateRef<any>>;

  @Output() public eventClosed = new EventEmitter<void>();
  @Output() public onClose = new EventEmitter();
  @Output() public onQuoteAction = new EventEmitter<{
    modalType: ModalTypeEnum;
    listingItem: ListingItem;
  }>();
  @Input() public eventRequest: EventRequestSingleType;

  @Input() public isMobile = false;
  // Modals have their own loading logic, as it needs to be contained in the dialog.
  public isLoading = false;
  public headerIcon = 'close-v2';
  public footer$ = new ReplaySubject<TemplateRef<any>>();
  public body$ = new ReplaySubject<TemplateRef<any>>();
  public listingItems: ListingItem[] = [];
  public title = 'Cancel Service Request';
  public step = 0;
  public stepTitle = '';
  public stepSubTitle = '';

  public checkboxSelection = JSON.stringify([
    { label: 'I found someone elsewhere', key: 'I found someone elsewhere' },
    {
      label: 'I couldn’t afford the quotes',
      key: 'I couldn’t afford the quotes'
    },
    {
      label: 'None of the quotes were suitable',
      key: 'None of the quotes were suitable'
    },
    {
      label: 'I’m no longer holding this event',
      key: 'I’m no longer holding this event'
    },
    {
      label: 'I no longer wish to use this service',
      key: 'I no longer wish to use this service'
    },
    { label: 'Other (please specify)', key: 'other', customInput: true }
  ]);

  public somewhereElseSelection = JSON.stringify([
    { label: 'Price', key: 'price' },
    { label: 'Quality', key: 'quality' },
    { label: 'Reviews', key: 'reviews' },
    { label: 'Communication', key: 'communication' },
    { label: 'Other (please specify)', key: 'other', customInput: true }
  ]);

  public closeRequest: {
    selectReason?: string;
    somewhereElseReason?: { [key: string]: boolean };
    somewhereElse?: string;
  };

  constructor(
    public eventRequestService: EventRequestService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _quoteStore: Store<QuotesState>,
    private _cd: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    if (this.isMobile) {
      this.headerIcon = 'chevron-left-v2';
    }
  }

  public ngAfterViewInit(): void {
    this.updateStep(0);

    const outstandingQuotes = this.filterForOutstandingQuotes(this.eventRequest?.quotes);
    this.listingItems = this.getListingItemsFromQuotes(outstandingQuotes);
    this._cd.detectChanges();
  }

  /**
   * Filter out any quotes that have not expired or that have already been responded to.
   *
   * @param eventRequestQuotes The array of quotes associated with the event request
   * @returns
   */
  public resetSelectReason(): void {
    this.closeRequest = { ...this.closeRequest, selectReason: '' };
  }

  private filterForOutstandingQuotes(eventRequestQuotes: EventRequestQuotes[] = []): EventRequestQuotes[] {
    const responseOutstandingStatuses = [
      QuoteStatus.PUBLISHED,
      QuoteStatus.PUBLISHED_PAST,
      QuoteStatus.PUBLISHED_MESSAGED,
      QuoteStatus.PUBLISHED_NEW,
      QuoteStatus.PUBLISHED_VIEWED
    ];

    return eventRequestQuotes.filter(quote => responseOutstandingStatuses.includes(quote.quoteOrganiserStatus as QuoteStatus));
  }

  /**
   * Creates a list of listing items from the quotes array.
   *
   * @returns An array of listing items or an empty array if the quotes array is empty.
   */
  private getListingItemsFromQuotes(eventRequestQuotes: EventRequestQuotes[]): ListingItem[] {
    return eventRequestQuotes?.length !== 0
      ? eventRequestQuotes?.map<ListingItem>(quote => ({
          uuid: quote.uuid,
          title: quote.listingTitle,
          images: [quote.listingServiceImageURI],
          ratings: quote.listingReviewCount,
          stars: quote.listingReviewRating,
          quoteUuid: quote.uuid,
          actionButtons: false,
          viewQuoteButton: true,
          selected: false
        }))
      : [];
  }

  /**
   * Creates a list of listing items from the quotes array.
   *
   * @returns An array of listing items or an empty array if the quotes array is empty.
   */
  public getListingItemsWithActionButtons(listingItems: ListingItem[]): ListingItem[] {
    return listingItems?.length !== 0
      ? listingItems?.map<ListingItem>(listingItem => ({
          ...listingItem,
          actionButtons: true,
          viewQuoteButton: false
        }))
      : [];
  }

  /**
   * Moves the modal flow to the given step.
   *
   * @param newStep The step to move the modal flow to.
   * @param submitClose Add this flag if we should fire a close request after updating.
   * @returns
   */
  public updateStep(newStep: number, submitClose = false) {
    if (submitClose) {
      return this.submitClose()
        .pipe(first())
        .subscribe({
          next: () => {
            this.updateStepContent(newStep);
          }
        });
    }
    this.updateStepContent(newStep);
  }

  /**
   * Updates the text and content of the modal flow.
   *
   * @param newStep The step we want to move to.
   */
  private updateStepContent(newStep: number) {
    this.step = newStep;
    this.body$.next(this.bodyTemplates.toArray()[this.step]);
    this.footer$.next(this.footerTemplates.toArray()[this.step]);

    switch (this.step) {
      case 0:
        this.title = 'Cancel Service Request';
        this.stepTitle = `Are you sure you want to cancel your ${this.eventRequest?.primaryServiceTitle} request?`;

        if (this.eventRequest?.quotes.length) {
          this.stepSubTitle = `You still have live quotes for your ${this.eventRequest?.primaryServiceTitle} Request from the following suppliers:`;
        } else {
          this.stepSubTitle = `You won’t receive any further quotes or reminders.`;
        }
        break;
      case 1:
        this.title = 'Service Request Cancelled';
        this.stepTitle = `Service Request Cancelled`;
        this.stepSubTitle = `You have closed your service request for ${this.eventRequest?.primaryServiceTitle}.`;
        break;
      case 2:
        this.title = 'Service Request Cancelled';
        this.stepTitle = `Tell us why you cancelled your service request`;
        this.stepSubTitle = `If you’re no longer looking for someone please complete the simple form below. `;
        break;
      case 3:
        this.title = 'I found someone elsewhere';
        this.stepTitle = ``;
        this.stepSubTitle = ``;
        break;
      case 4:
        this.title = 'Your Other Quotes';
        this.stepTitle = `Please accept or decline the following quotes`;
        this.stepSubTitle = `You still have live quotes from the following suppliers. Please let them know whether you want to book or decline them.`;
        break;
    }
  }

  @HostListener('onValueChanges', ['$event'])
  onClick(event: {
    detail: {
      selectReason?: { value: string };
      somewhereElse?: { value: string };
      somewhereElseReason?: { value: { [key: string]: boolean } };
    };
  }) {
    this.closeRequest = {
      selectReason: event?.detail?.selectReason?.value || this.closeRequest?.selectReason,
      somewhereElse: event?.detail?.somewhereElse?.value || this.closeRequest?.somewhereElse,
      somewhereElseReason: event?.detail?.somewhereElseReason?.value || this.closeRequest?.somewhereElseReason
    };
  }

  public foundSomewhereElse() {
    if (this.closeRequest?.selectReason === 'I found someone elsewhere') {
      this.updateStep(3);
      return;
    }

    return this.submitAndGoToFinalStep();
  }

  public submitFoundSomewhereElse() {
    return this.submitAndGoToFinalStep();
  }

  public submitAndGoToFinalStep() {
    return this.submitClose()
      .pipe(first())
      .subscribe({
        next: () => {
          if (this.listingItems.length > 0) {
            this.updateStep(4);
            return;
          }
          this.onClose.emit();
        }
      });
  }

  public goBack() {
    let newStep = this.step - 1;
    if (newStep < 2) {
      return;
    }
    // Skip step 3.
    if (newStep === 3) {
      newStep = 2;
    }
    this.updateStep(newStep);
    this.resetSelectReason();
  }

  public navigateToQuote(event: { action: QuoteActionType; listingItem: ListingItem }) {
    if (event.action === QuoteActionType.VIEW) {
      this._router.navigate(['/quote/', event.listingItem.uuid]);
    }
  }

  public emitQuoteAction({ action, listingItem }: { action: QuoteActionType; listingItem: ListingItem }): void {
    if (action === QuoteActionType.BOOK) {
      this.onQuoteAction.emit({
        modalType: ModalTypeEnum.ACCEPT_QUOTE,
        listingItem
      });
    } else if (action === QuoteActionType.DECLINE) {
      this.onQuoteAction.emit({
        modalType: ModalTypeEnum.DECLINE_QUOTE,
        listingItem
      });
    }
  }

  public submitClose(): Observable<any> {
    const { paramMap } = this._route.parent.snapshot;
    const eventUuid = paramMap.get('eventUuid');
    const eventRequestUuid = paramMap.get('eventRequestUuid');

    this.isLoading = true;

    const reasons = {
      notSelectedReason: this.closeRequest?.selectReason,
      extSupplierName: this.closeRequest?.somewhereElse,
      selectedReason: this.closeRequest?.somewhereElseReason ? Object.keys(this.closeRequest?.somewhereElseReason) : null
    };

    return this.eventRequestService.closeEvent(eventRequestUuid, eventUuid, reasons).pipe(
      tap(() => {
        this.isLoading = false;
        this.eventClosed.emit();
      }),
      catchError(msg => {
        console.error(msg);
        this.isLoading = false;
        return throwError(() => new Error(msg));
      })
    );
  }
}
