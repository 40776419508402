import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-count',
  templateUrl: './quote-count.component.html',
  styleUrls: ['./quote-count.component.scss']
})
export class QuoteCountComponent implements OnInit {
  @Input() count = 0;
  @Input() text = 'Quote';

  ngOnInit(): void {
    if (this.count !== 1) {
      this.text = this.text + 's';
    }
  }
}
