import { QuoteStatus } from '../../../../../api';
import { EnhancedStatusPillConfig } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill-config.interface';

export const QuoteStatusConfig: { [key in QuoteStatus]: EnhancedStatusPillConfig } = {
  [QuoteStatus.PUBLISHED_PAST]: {
    statusText: 'Your event date has passed',
    iconName: 'quote-status-pending-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100'
  },
  [QuoteStatus.PUBLISHED_MESSAGED]: {
    statusText: 'Awaiting response',
    iconName: 'check-circle-filled-v3',
    textColour: 'tw-text-blue-800',
    iconColour: 'tw-text-blue-600',
    backgroundColour: 'tw-bg-blue-50',
    showRightSideText: true
  },
  [QuoteStatus.REJECTED_FUTURE]: {
    statusText: 'You have declined this supplier',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-red-800',
    iconColour: 'tw-text-red-600',
    backgroundColour: 'tw-bg-red-50'
  },
  [QuoteStatus.REJECTED_PAST]: {
    statusText: 'You have declined this supplier',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-red-800',
    iconColour: 'tw-text-red-600',
    backgroundColour: 'tw-bg-red-50'
  },
  [QuoteStatus.ACCEPTED_FUTURE]: {
    statusText: 'You have chosen this supplier',
    iconName: 'check-circle-filled-v3',
    textColour: 'tw-text-green-800',
    iconColour: 'tw-text-green-700',
    backgroundColour: 'tw-bg-green-50'
  },
  [QuoteStatus.ACCEPTED_PAST]: {
    statusText: 'Leave a review for this supplier',
    iconName: 'quote-status-reviewed-standard-v3',
    textColour: 'tw-text-amber-800',
    iconColour: 'tw-text-amber-600',
    backgroundColour: 'tw-bg-amber-50'
  },
  [QuoteStatus.PUBLISHED_REVIEWED]: {
    statusText: 'You left a review for this supplier',
    iconName: 'quote-status-reviewed-standard-v3',
    textColour: 'tw-text-green-800',
    iconColour: 'tw-text-green-700',
    backgroundColour: 'tw-bg-green-50'
  },

  [QuoteStatus.PUBLISHED_NEW]: {
    statusText: 'New Quote!',
    iconName: 'airplane-circle-v3',
    textColour: 'tw-text-brand-purple',
    iconColour: 'tw-text-brand-purple',
    backgroundColour: 'tw-bg-fuchsia-50',
    showRightSideText: true
  },
  [QuoteStatus.PUBLISHED_VIEWED]: {
    statusText: 'Respond to supplier',
    iconName: 'quote-status-pending-v3',
    textColour: 'tw-text-amber-800',
    iconColour: 'tw-text-orange-500',
    backgroundColour: 'tw-bg-amber-50',
    showRightSideText: true
  },
  [QuoteStatus.UNPUBLISHED]: undefined,
  [QuoteStatus.PUBLISHED]: undefined,
  [QuoteStatus.ARCHIVED_AFTER]: undefined,
  [QuoteStatus.ARCHIVED_BEFORE]: undefined
};
