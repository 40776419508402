import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';

@Component({
  selector: 'app-gradient-message',
  templateUrl: './gradient-message.component.html',
  styleUrls: ['./gradient-message.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule]
})
export class GradientMessageComponent {
  @Input() title = '';
  @Input() message = '';
  @Input() image = '';
}
