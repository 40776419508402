export enum EventRequestTypeEnum {
  ANNIVERSARY = 'ANNIVERSARY',
  BIRTHDAY_PARTY = 'BIRTHDAY_PARTY',
  CHARITY_EVENT = 'CHARITY_EVENT',
  CHILDRENS_PARTY = 'CHILDRENS_PARTY',
  CHRISTENING = 'CHRISTENING',
  CORPORATE_EVENT = 'CORPORATE_EVENT',
  FESTIVAL = 'FESTIVAL',
  HEN_PARTY = 'HEN_PARTY',
  STAG_PARTY = 'STAG_PARTY',
  WEDDING = 'WEDDING',
  UNSPECIFIED_EVENT = 'UNSPECIFIED_EVENT'
}
