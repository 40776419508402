import packageInfo from '../../package.json';

const webUrl = 'https://addtoevent.co.uk';

export const environment = {
  production: true,
  sentry: {
    dsn: 'https://177107c974814a94965bc634f42b0543@o139805.ingest.sentry.io/4504638031265792',
    tracePropagationTargets: ['localhost', 'https://dev-organiser-web-client.web.app/']
  },
  googleMapsApiKey: 'AIzaSyA7ZlDJ0LjfutjZH-PeqAHS5XYxdYQYPEI',
  apiUrl: 'https://prod.ateapi.co.uk',
  version: packageInfo.version,
  webUrl: 'https://addtoevent.co.uk',
  logoutUrl: `${webUrl}/user/logout`,
  supplierApp: 'https://app.addtoevent.co.uk/',
  requestQuotesUrl: `${webUrl}/request-quotes?form=open`,
  browseSuppliersUrl: `${webUrl}/browse-suppliers`,
  blogUrl: `${webUrl}/blog`,
  termsOfUseUrl: `${webUrl}/terms-of-use`,
  privacyAndCookiePolicyUrl: `${webUrl}/privacy-and-cookie-policy`,
  eventOrganiserSupportUrl: 'https://organiser-support.addtoevent.co.uk/',
  loginUrl: `${webUrl}/user/login?destination=user/me`,
  firebase: {
    apiKey: 'AIzaSyAPE7Az_xw1IiMCTNe73n7QTBqgVe7FrRM',
    authDomain: 'ate-mp-production-0a18.firebaseapp.com',
    databaseURL: 'https://ate-mp-production-0a18-default-rtdb.firebaseio.com',
    projectId: 'ate-mp-production-0a18',
    storageBucket: 'ate-mp-production-0a18.appspot.com',
    messagingSenderId: '916464402137',
    appId: '1:916464402137:web:763687ef3c9f4bc1d1c8c8'
  },
  vapidPublicKey: 'BMIvn_BfllEXAlLLdU6oyYWpBFrVFEIbTMhZcYI9eLw_CD8opt-18gUh6IBzn3OWn6buXtQ5ucNLVXJm1Xt5MkI',
  supportEmailAddress: 'support-team@addtoevent.co.uk'
};
