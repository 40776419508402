import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ServiceListItem } from './service-list-item.interface';

@Injectable()
export class ServiceListService {
  constructor(
    private _http: HttpClient,
    @Inject('environment') private environment
  ) {}

  getServiceList() {
    return this._http.get<ServiceListItem[]>(`${this.environment.apiUrl}/org/service/list`, {
      headers: {
        'Content-Type': 'application/ate.organiser.v1+json'
      }
    });
  }
}
