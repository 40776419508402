import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MaintenanceService } from '../../../shared/services/maintence/maintenance.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) readonly document: Document,
    private _maintenanceService: MaintenanceService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.navigateOnMaintenanceModeOff();
  }

  /**
   * When maintainence mode is turned off in Firebase, we need to route back to the
   * home page on refresh, so the user isn't stuck on this page.
   */
  private navigateOnMaintenanceModeOff() {
    this._maintenanceService
      .getMaintenanceMode()
      .pipe(first())
      .subscribe(snapshot => {
        const maintenanceMode: boolean = snapshot.val();

        if (!maintenanceMode) {
          this.navigateToHomePage();
        }
      });
  }

  private navigateToHomePage(): void {
    this._router.navigate(['/']);
  }
}
