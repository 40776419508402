import { Component, Input, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-view-more-details',
  templateUrl: './view-more-details.component.html',
  styleUrls: ['./view-more-details.component.scss']
})
export class ViewMoreDetailsComponent implements AfterViewInit {
  @Input() details = '';
  @Input() open = false;
  @ViewChild('paragraph') paragraph: ElementRef;

  needsReadMore = false;

  ngAfterViewInit() {
    setTimeout(() => this.onResize());
  }

  @HostListener('window:resize')
  onResize() {
    if (this.open && this.needsReadMore) {
      return;
    }
    const domEl = this.paragraph?.nativeElement;
    if (!domEl) {
      return;
    }
    this.needsReadMore = domEl.scrollHeight > domEl.clientHeight;
  }

  toggleOpen() {
    this.open = !this.open;
  }
}
