import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { EventRequestService } from '../../shared/services/event-request/event-request.service';
import { EventRequestSingleType } from '../../shared/services/event-request/event-request.type';
import { appActions } from '../actions/app.actions';
import { AppState } from '../reducers/app.reducer';
import { Store } from '@ngrx/store';
import { eventActions } from '../actions/event.actions';

@Injectable()
export class EventEffects {
  constructor(
    private _actions$: Actions,
    private _eventRequestService: EventRequestService,
    private _store: Store<AppState>
  ) {}

  getEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(eventActions.GetEvent),
      switchMap(({ payload }) => {
        this._store.dispatch(appActions.IncrementLoadingCount());
        return this._eventRequestService.getRequest(payload.eventUuid, payload.eventRequestUuid).pipe(
          first(),
          map((result: EventRequestSingleType) => {
            this._store.dispatch(appActions.DecrementLoadingCount());
            return eventActions.SetEventSuccess({
              payload: result
            });
          }),
          catchError(() => {
            this._store.dispatch(appActions.DecrementLoadingCount());
            return of(eventActions.SetEventFailure());
          })
        );
      })
    )
  );
}
