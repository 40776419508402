/* eslint-disable @typescript-eslint/no-explicit-any */
export class DialogData {
  type: DialogType;
  title: string;
  showHeader: boolean;
  record?: { [key: string]: any };
}

export enum DialogType {
  SUGGESTED = 'suggested',
  INTERESTED = 'interested',
  INVITED = 'invited',
  CONFIRMATION = 'confirmation',
  NOTIFICATION = 'notification'
}
