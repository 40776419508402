import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-listing-profile-picture',
  templateUrl: './listing-profile-picture.component.html',
  styleUrls: ['./listing-profile-picture.component.scss']
})
export class ListingProfilePictureComponent {
  @Input() listingImage = '';
}
