import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EVENT_FEATURE_KEY, EventState } from '../reducers/event.reducer';

const getEventState = createFeatureSelector<EventState>(EVENT_FEATURE_KEY);

const getEvent = createSelector(getEventState, (state: EventState) => state.selectedEvent);

export const selectEvent = {
  getEvent: getEvent
};
