<div class="tw-flex tw-h-full tw-flex-col">
  <div class="tw-mb-auto">
    <app-tile-base [item]="interest"></app-tile-base>
  </div>
  <app-enhanced-status-pill class="tw-mt-4 lg:tw-mt-5" [config]="statusPillConfig"></app-enhanced-status-pill>

  <div class="tw-mt-4 tw-flex tw-justify-stretch tw-gap-4" *ngIf="showButtons">
    <button
      (click)="declineInterest(eventRequestUuid, interest.listingUuid); $event.stopPropagation()"
      [disabled]="loadingSkipped"
      class="tw-relative tw-box-border tw-flex tw-h-10 tw-w-10 tw-shrink-0 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border-2 tw-border-solid tw-border-slate-300 tw-bg-white tw-text-base tw-font-bold tw-text-slate-600 tw-duration-200 hover:tw-bg-slate-50 lg:tw-h-12 lg:tw-w-12">
      <ate-icon class="tw-absolute tw-left-3 tw-size-6 tw-animate-spin tw-text-slate-400" name="loading-spinner-v3" *ngIf="loadingSkipped"></ate-icon>
      <ate-icon class="tw-size-6 tw-text-slate-600" name="cancel" *ngIf="!loadingSkipped"></ate-icon>
    </button>

    <button
      (click)="inviteSupplier(interest.listingUuid); $event.stopPropagation()"
      class="btn-action btn-action-md btn-outline tw-w-full"
      [disabled]="loadingInvited">
      <ate-icon class="tw-size-6 tw-animate-spin tw-text-brand-gradientfrom" name="loading-spinner-v3" *ngIf="loadingInvited"></ate-icon>
      <ate-icon class="btn-outline-icon-left" name="hearts" *ngIf="!loadingInvited"></ate-icon>
      <span>Invite to Quote</span>
    </button>
  </div>
</div>
