import { EnhancedStatusPillConfig } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill-config.interface';
import { OrganiserInviteStatus } from '../../../../../shared/enums/organiser-invite-status.enum';

export const InterestedStatusConfig: { [key in OrganiserInviteStatus]: EnhancedStatusPillConfig } = {
  [OrganiserInviteStatus.DECLINED]: {
    statusText: 'You have declined this interest',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'This is not what I’m looking for'
  },
  [OrganiserInviteStatus.EVENT_CLOSED_REASON]: {
    statusText: 'This supplier interest has expired',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'You have cancelled the request'
  },
  [OrganiserInviteStatus.EVENT_EXPIRED]: {
    statusText: 'This supplier interest has expired',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'Suppliers can no longer quote'
  },
  [OrganiserInviteStatus.EVENT_PASSED]: {
    statusText: 'This supplier interest has expired',
    iconName: 'circle-cross-filled-v3',
    textColour: 'tw-text-slate-600',
    iconColour: 'tw-text-slate-500',
    backgroundColour: 'tw-bg-slate-100',
    secondaryText: 'The event has passed'
  },
  [OrganiserInviteStatus.LIVE]: {
    statusText: 'Supplier interested in your event',
    iconName: 'hearts-inverted',
    iconColour: 'tw-text-violet-700',
    textColour: 'tw-text-violet-800',
    backgroundColour: 'tw-bg-violet-50',
    secondaryText: ''
  }
};
