import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerModule } from '../../ui/containers/container.module';
import { SharedModule } from '../../shared/shared.module';
import { EventsListComponent } from './components/events-list/events-list.component';
import { EventDetailsComponent } from './components/event-details/event-details.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'upcoming',
    pathMatch: 'full'
  },
  {
    path: ':eventState',
    component: EventsListComponent
  },
  {
    path: ':eventState/:eventUuid',
    component: EventDetailsComponent
  },
  {
    path: ':eventState/:eventUuid/add-request',
    component: EventDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, ContainerModule],
  exports: [RouterModule]
})
export class EventsRoutingModule {}
