import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, first, switchMap, tap } from 'rxjs';
import { QuoteAPIService } from '../../../api';
import { QuoteResponse } from './quote.type';
import { EventRequestColorEnum } from '../../../ui/components/status-pill/status-pill-color.enum';
import { EventDeclineReason } from '../../../api/enums';
import { quotesActions } from '../../../store/actions/quotes.actions';
import { QuotesState } from '../../../store/reducers/quotes.reducer';
import { selectQuotes } from '../../../store/selectors/quotes.selectors';
import { QuoteStatus } from '../../../api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { QuotesListModel } from '../../../shared/models/quotes';
import { eventActions } from '../../../store/actions/event.actions';
import { EventState } from '../../../store/reducers/event.reducer';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  baseUrl: string = environment.apiUrl;

  constructor(
    private quoteAPIService: QuoteAPIService,
    private _quoteStore: Store<QuotesState>,
    private _eventStore: Store<EventState>,
    private _http: HttpClient
  ) {}

  private dispatchGetEvent(eventUuid: string, eventRequestUuid: string): void {
    this._eventStore.dispatch(eventActions.GetEvent({ payload: { eventUuid, eventRequestUuid } }));
  }

  private dispatchUndoAcceptQuote(quoteUuid: string): void {
    this._quoteStore.dispatch(
      quotesActions.UndoQuoteAccepted({
        payload: { id: quoteUuid }
      })
    );
  }
  private dispatchUndoDeclineQuote(quoteUuid: string): void {
    this._quoteStore.dispatch(
      quotesActions.UndoQuoteDeclined({
        payload: { id: quoteUuid }
      })
    );
  }

  public getOneQuote(uuid: string): Observable<QuoteResponse> {
    return this.quoteAPIService.getOneQuote(uuid);
  }

  acceptQuotes(uuids: string[], acceptReasons?: string[], keepRequestOpen?: boolean): Observable<QuoteResponse> {
    return this.quoteAPIService.acceptQuotes(uuids, acceptReasons, keepRequestOpen).pipe(
      tap(() =>
        this._quoteStore.dispatch(
          quotesActions.QuotesAccepted({
            payload: { ids: uuids, acceptReasons, keepRequestOpen }
          })
        )
      )
    );
  }

  getQuotes(quoteStatus: string, viewSource: string): Observable<QuotesListModel> {
    let params: HttpParams = new HttpParams();
    params = params.set('filter', quoteStatus);
    if (viewSource) {
      params = params.append('viewSource', viewSource);
    }

    return this._http.get<QuotesListModel>(`${this.baseUrl}/org/quote-list`, { params: params });
  }

  /**
   * Reverts the quote to the state it was in before accepting.
   *
   * @param quoteUuid - The UUID of the quote to update.
   */
  public undoAcceptQuote(quoteUuid: string): Observable<QuoteResponse> {
    return this._quoteStore.select(selectQuotes.getQuote).pipe(
      first(),
      switchMap(quote =>
        this.quoteAPIService.undoAcceptQuote(quoteUuid).pipe(
          tap(() => {
            this.dispatchUndoAcceptQuote(quoteUuid);
            // Ensure the quotes on the event are up-to-date
            this.dispatchGetEvent(quote.event.eventUuid, quote.event.eventRequestUuid);
          })
        )
      )
    );
  }

  /**
   * Reverts the quote to the state it was in before declining.
   *
   * @param quoteUuid - The UUID of the quote to update.
   */
  public undoDeclineQuote(quoteUuid: string): Observable<QuoteResponse> {
    return this._quoteStore.select(selectQuotes.getQuote).pipe(
      first(),
      switchMap(quote =>
        this.quoteAPIService.undoDeclineQuote(quoteUuid).pipe(
          tap(() => {
            this.dispatchUndoDeclineQuote(quoteUuid);
            // Ensure the quotes on the event are up-to-date
            this.dispatchGetEvent(quote.event.eventUuid, quote.event.eventRequestUuid);
          })
        )
      )
    );
  }

  public declineQuotes(uuids: string[], declineReason?: string): Observable<QuoteResponse> {
    return this.quoteAPIService.declineQuotes(uuids, declineReason).pipe(
      tap(() =>
        this._quoteStore.dispatch(
          quotesActions.QuotesDeclined({
            payload: { ids: uuids, declineReason }
          })
        )
      )
    );
  }

  public processStatus(status: QuoteStatus): { statusText: string; statusColor: EventRequestColorEnum } {
    return this.quoteAPIService.processStatus(status);
  }

  processSupplierDelineReason(reason: EventDeclineReason): string {
    return this.quoteAPIService.processSupplierDelineReason(reason);
  }
}
