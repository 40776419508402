import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { first, map, startWith } from 'rxjs/operators';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ServiceListService } from '../../services/services-list/service-list.service';
import { ServiceListItem } from '../../services/services-list/service-list-item.interface';
import { ServiceListItemOption } from '../../services/services-list/service-list-item-option.interface';
import { Observable } from 'rxjs';

/**
 * @title Option groups autocomplete
 */
@Component({
  selector: 'app-service-selector',
  templateUrl: 'service-selector.component.html',
  styleUrl: './service-selector.component.scss',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, AsyncPipe, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ServiceSelectorComponent implements OnInit {
  control = new FormControl('');
  serviceOptionGroups: ServiceListItemOption[] = [];
  filteredOptionGroup$: Observable<ServiceListItemOption[]>;
  @Output() panelOpen = new EventEmitter<boolean>();
  @Output() selectedFormUuid = new EventEmitter<{ tid: string; formUuid: string }>();

  constructor(private _serviceListService: ServiceListService) {}

  ngOnInit() {
    this._serviceListService
      .getServiceList()
      .pipe(first())
      .subscribe(result => {
        this.serviceOptionGroups = this.processServiceList(result);

        this.filteredOptionGroup$ = this.control.valueChanges.pipe(
          startWith(''),
          map(value => this.filter(value || ''))
        );
      });
  }

  clearInput() {
    this.control.setValue('');
  }

  emitSelectedService(service: ServiceListItemOption) {
    this.selectedFormUuid.emit({ tid: service.tid, formUuid: service.formUuid });
  }

  emitPanelOpened() {
    this.panelOpen.emit(true);
  }

  emitPanelClosed() {
    this.panelOpen.emit(false);
  }

  highlight(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const regex = new RegExp(`(${search.trim()})`, 'gi');

    return text.replace(regex, `<span class="autocomplete-match-highlight">$1</span>`);
  }

  private processServiceList(serviceList: ServiceListItem[] = []): ServiceListItemOption[] {
    const services: ServiceListItemOption[] = [];

    if (serviceList.length === 0) {
      console.error('Empty service list');
      return [];
    }
    if (serviceList[0].depth !== 0) {
      console.error('First element of service list array must be of depth 0');
      return [];
    }

    let currentGroup: ServiceListItemOption | null = null;

    for (const service of serviceList) {
      if (service.depth === 0) {
        currentGroup = { ...service, services: [] };
        services.push(currentGroup);
      } else if (service.depth === 1 && currentGroup) {
        const synonyms = service.synonyms || []; // Ensure synonyms is an array
        currentGroup.services.push({ ...service, synonyms });
      } else {
        console.error('Service list contains items of invalid depth or misaligned structure');
        return [];
      }
    }

    return services;
  }

  private normaliseValue(value: string): string {
    return value.toLowerCase().trim();
  }

  private filter(value: string): ServiceListItemOption[] {
    const filterValue = this.normaliseValue(value);

    return this.serviceOptionGroups.reduce((acc, group) => {
      // Check if the group name or its synonyms match the filter
      const groupNameMatches = this.normaliseValue(group.name).includes(filterValue);
      const filteredGroupSynonyms = group.synonyms?.filter(synonym => this.normaliseValue(synonym).includes(filterValue));

      // Filter services based on the filterValue
      const filteredServices = group.services.filter(service => {
        // Check if the service name matches
        const serviceNameMatches = this.normaliseValue(service.name).includes(filterValue);

        // Filter the synonyms of the service
        const filteredServiceSynonyms = service.synonyms?.filter(synonym => this.normaliseValue(synonym).includes(filterValue));

        // Return true if the name or any synonyms match
        return serviceNameMatches || filteredServiceSynonyms?.length > 0;
      });

      // Include the group if the group name, its synonyms, or any of its services match the filter
      if (groupNameMatches || filteredServices.length > 0 || filteredGroupSynonyms?.length > 0) {
        acc.push({ ...group, services: filteredServices, synonyms: filteredGroupSynonyms });
      }

      return acc;
    }, [] as ServiceListItemOption[]);
  }
}
