import { TooltipPosition } from './ui/components/filter-bar/models/filter.interface';

export const appConfig = {
  date_short: 'd MMM y',
  distanceToKmConversionRate: 1.609
};

export const navigationFilters = {
  eventRequests: [
    {
      url: 'suggested',
      name: 'Suggested',
      icon: null,
      counter: 0,
      showCounter: false,
      show: true,
      gradient: true,
      tooltip: `View all suggested suppliers for this request`,
      tooltipPosition: TooltipPosition.BELOW
    },
    {
      url: 'invited',
      name: 'Invited',
      icon: 'hearts',
      counter: 0,
      showCounter: true,
      show: true,
      tooltip: `View all suppliers you invited to quote on this request`,
      tooltipPosition: TooltipPosition.BELOW
    },
    {
      url: 'interested',
      name: 'Interested',
      icon: 'hearts',
      showCounter: true,
      counter: 0,
      show: true,
      enhanced: true,
      tooltip: `3 suppliers are interested in this request!`,
      tooltipPosition: TooltipPosition.BELOW
    },
    {
      url: 'quotes',
      name: 'Quotes',
      icon: 'send-v3',
      showCounter: true,
      counter: 0,
      show: true,
      tooltip: `View all quotes for this request`,
      tooltipPosition: TooltipPosition.BELOW
    },
    {
      url: 'bookings',
      name: 'Booked',
      icon: 'check-circle-v2',
      showCounter: true,
      counter: 0,
      show: true,
      tooltip: `View all suppliers you have booked for this request`,
      tooltipPosition: TooltipPosition.BELOW
    }
  ]
};
