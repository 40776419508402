<div class="animated fadeIn tw-flex tw-flex-auto tw-flex-col">
  <header class="tw-mb-4 tw-flex tw-w-full" *ngIf="data?.showHeader">
    <div class="tw-flex tw-w-5"></div>
    <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
      <h2>Services</h2>
    </div>
    <div class="tw-flex tw-w-5 tw-items-start tw-justify-end">
      <ate-icon (click)="closeDialog(null)" slot="corner-button" name="ate-close-v2" class="close link-item tw-size-3"></ate-icon>
    </div>
  </header>

  <div
    *ngIf="data?.type === DialogType.SUGGESTED || data?.type === DialogType.INTERESTED || data?.type === DialogType.INVITED"
    class="dialog-invites-container">
    <div
      class="tw-flex tw-flex-auto tw-grow-0 tw-items-center tw-justify-between tw-px-4 tw-py-2"
      [ngClass]="data?.type === DialogType.INTERESTED ? 'dialog-interested-header' : 'dialog-invites-header'">
      <div class="tw-flex tw-items-center tw-gap-x-2">
        <div class="tw-flex tw-items-center">
          <ate-icon [name]="data?.record?.inviteHeader?.icon" class="close link-item tw-size-4"></ate-icon>
        </div>
        <div class="tw-flex tw-items-center">
          <p class="tw-mb-0">
            <span class="tw-font-semibold">{{ data?.record?.inviteHeader?.intro }}</span>
            <span>{{ data?.record?.inviteHeader?.main }}</span>
          </p>
        </div>
      </div>
      <div class="tw-flex tw-items-center">
        <ate-icon (click)="closeDialog(null)" name="ate-close-v2" class="close link-item tw-size-4"></ate-icon>
      </div>
    </div>

    <div class="supplier-info">
      <app-supplier [dialogData]="data" (selectedAction)="receiveEmmitedActionFromDialog($event)"></app-supplier>
    </div>
  </div>

  <div class="tw-flex-w tw-flex-auto">
    <ng-container #modalContainer></ng-container>
  </div>
</div>
