<div class="tw-flex tw-flex-auto tw-flex-col tw-overflow-hidden">
  <!-- *** Dialog header *** -->
  <div class="tw-border-0 tw-border-b tw-border-solid tw-border-slate-300 tw-p-3">
    <ate-icon
      (click)="closeDialog(null)"
      name="ate-close-v2"
      class="link-item tw-ml-auto tw-block tw-size-5 tw-text-slate-500 lg:tw-size-6"></ate-icon>
  </div>

  <!-- *** Dialog body *** -->
  <section class="tw-mx-6 tw-mt-5 lg:tw-mt-6">
    <p class="tw-m-auto tw-mb-6 tw-max-w-[340px] tw-text-center tw-text-lg tw-font-bold tw-text-slate-800">
      Do you want to request a service for a new or existing event?
    </p>

    <app-event-item [listSource]="'new-request'" (eventSelected)="receiveEmittedEvent($event)"></app-event-item>

    <p class="tw-mb-4 tw-mt-6 tw-text-center tw-font-semibold tw-text-slate-600">Your Existing Events</p>
  </section>

  <div class="tw-mx-3 tw-mb-6 tw-max-h-[360px] tw-overflow-y-auto tw-px-3">
    <app-event-item
      class="tw-mb-4 tw-block"
      *ngFor="let event of data.events"
      [event]="event"
      [listSource]="'new-request'"
      (eventSelected)="receiveEmittedEvent(event)"></app-event-item>
  </div>
</div>
