import { createReducer, on, Action } from '@ngrx/store';
import { isBefore } from 'date-fns';
import { QuoteStatus } from '../../api';
import { QuoteResponse } from '../../features/quotes/services/quote.type';

import { quotesActions } from '../actions/quotes.actions';

export const QUOTES_FEATURE_KEY = 'quotes';

export interface QuotesState {
  selectedQuote: QuoteResponse;
}

export const initialState: QuotesState = {
  selectedQuote: undefined
};

const reducer = createReducer(
  initialState,
  on(quotesActions.SetQuoteSuccess, (state, { payload }) => ({
    ...state,
    selectedQuote: payload
  })),
  on(quotesActions.SetQuoteFailure, state => ({
    ...state,
    selectedQuote: null
  })),
  on(quotesActions.QuotesAccepted, (state, { payload }) => {
    if (payload.ids.includes(state.selectedQuote.uuid)) {
      return {
        ...state,
        selectedQuote: {
          ...state.selectedQuote,
          organiserAccepted: true,
          quoteStatus: isBefore(new Date(state.selectedQuote.event.startDate), new Date()) ? QuoteStatus.ACCEPTED_PAST : QuoteStatus.ACCEPTED_FUTURE
        }
      };
    }

    const otherQuotes = state.selectedQuote.listings.map(listing => {
      if (payload.ids.includes(listing.quoteUuid)) {
        return {
          ...listing,
          organiserAccepted: true
        };
      }
      return listing;
    });

    return {
      ...state,
      selectedQuote: {
        ...state.selectedQuote,
        listings: otherQuotes
      }
    };
  }),
  on(quotesActions.UndoQuoteAccepted, (state, { payload }) => {
    const hasChatMessages = state.selectedQuote.hasChatMessages;

    if (payload.id === state.selectedQuote.uuid) {
      return {
        ...state,
        selectedQuote: {
          ...state.selectedQuote,
          organiserAccepted: false,
          quoteStatus: hasChatMessages ? QuoteStatus.PUBLISHED_MESSAGED : QuoteStatus.PUBLISHED_VIEWED
        }
      };
    }

    const otherQuotes = state.selectedQuote.listings.map(listing => {
      if (payload.id === listing.quoteUuid) {
        return {
          ...listing,
          organiserAccepted: false
        };
      }
      return listing;
    });

    return {
      ...state,
      selectedQuote: {
        ...state.selectedQuote,
        listings: otherQuotes
      }
    };
  }),
  on(quotesActions.UndoQuoteDeclined, (state, { payload }) => {
    const hasChatMessages = state.selectedQuote.hasChatMessages;

    if (payload.id === state.selectedQuote.uuid) {
      return {
        ...state,
        selectedQuote: {
          ...state.selectedQuote,
          organiserDeclined: false,
          quoteStatus: hasChatMessages ? QuoteStatus.PUBLISHED_MESSAGED : QuoteStatus.PUBLISHED_VIEWED
        }
      };
    }

    const otherQuotes = state.selectedQuote.listings.map(listing => {
      if (payload.id === listing.quoteUuid) {
        return {
          ...listing,
          organiserDeclined: false
        };
      }
      return listing;
    });

    return {
      ...state,
      selectedQuote: {
        ...state.selectedQuote,
        listings: otherQuotes
      }
    };
  }),
  on(quotesActions.QuotesDeclined, (state, { payload }) => {
    if (payload.ids.includes(state.selectedQuote.uuid)) {
      return {
        ...state,
        selectedQuote: {
          ...state.selectedQuote,
          organiserDeclined: true,
          quoteStatus: isBefore(new Date(state.selectedQuote.event.startDate), new Date()) ? QuoteStatus.REJECTED_PAST : QuoteStatus.REJECTED_FUTURE
        }
      };
    }

    const otherQuotes = state.selectedQuote.listings.map(listing => {
      if (payload.ids.includes(listing.quoteUuid)) {
        return {
          ...listing,
          organiserDeclined: true
        };
      }
      return listing;
    });

    return {
      ...state,
      selectedQuote: {
        ...state.selectedQuote,
        listings: otherQuotes
      }
    };
  }),
  on(quotesActions.MessageSent, state => {
    const quoteStatus =
      state.selectedQuote.quoteStatus === QuoteStatus.PUBLISHED_NEW || state.selectedQuote.quoteStatus === QuoteStatus.PUBLISHED_VIEWED
        ? QuoteStatus.PUBLISHED_MESSAGED
        : state.selectedQuote.quoteStatus;
    return {
      ...state,
      selectedQuote: {
        ...state.selectedQuote,
        quoteStatus
      }
    };
  })
);

export function quotesReducer(state: QuotesState, action: Action) {
  return reducer(state, action);
}
