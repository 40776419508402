import { catchError, from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Database, DatabaseReference, DataSnapshot, get, ref } from '@angular/fire/database';

@Injectable()
export class MaintenanceService {
  private _maintenanceModeRef: DatabaseReference;

  constructor(private _database: Database) {
    this._maintenanceModeRef = ref(this._database, `maintenance_mode`);
  }

  public getMaintenanceMode(): Observable<DataSnapshot | { val: () => false }> {
    return from(get(this._maintenanceModeRef)).pipe(catchError(() => of({ val: () => false })));
  }
}
