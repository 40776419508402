import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from '../../shared/services/common/common.service';
import { LightboxComponent } from '../carousel-lightboxes/lightbox/lightbox.component';

@Component({
  selector: 'app-image-grid',
  templateUrl: './image-grid.component.html',
  styleUrls: ['./image-grid.component.scss']
})
export class ImageGridComponent implements OnChanges {
  @ViewChild(LightboxComponent) lightbox: LightboxComponent;

  @Input() imageUrls: string[];
  imageCount: number = 0;

  constructor(private _commonService: CommonService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.imageCount = this.imageUrls.length;
    }
  }

  selectImage(imageIndex = 0) {
    this.lightbox.selectImage(imageIndex);
    this._commonService.publishGalleryState(true);
  }
}
