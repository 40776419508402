<!-- <p *ngIf="acceptedText" class="accepted">
  <ate-icon name="check-circle-v2"></ate-icon>{{acceptedText}}</p> -->
<div *ngIf="selectedSupplier" class="selected-supplier">
  <ate-icon name="check-circle-v2"></ate-icon>
  <div>
    <h4>You have chosen this supplier!</h4>
    <p>
      You have selected <b>{{ quote?.listing?.title }}</b> for your {{ quote?.event?.eventType | eventType }} Request.
    </p>
  </div>
</div>

<p *ngIf="eventPassed" class="passed"><ate-icon name="clock"></ate-icon> The event date has passed</p>

<p *ngIf="declinedText" class="declined"><ate-icon class="circle" name="close-v2"></ate-icon>{{ declinedText }}</p>

<div class="info-panel decline-panel" *ngIf="quote?.quoteStatus === 'REJECTED_FUTURE' || quote?.quoteStatus === 'REJECTED_PAST'">
  <ate-icon name="close-v2"></ate-icon>
  <div>
    <h4>You have declined this supplier.</h4>
    <p>
      You have declined <b>{{ quote?.listing?.title }}</b> for your {{ quote?.event?.title }} Request.
    </p>
  </div>
</div>

<div class="info-panel" *ngIf="quote?.quoteStatus === 'PUBLISHED_PAST'">
  <ate-icon name="info-v2"></ate-icon>
  <div>
    <h4>The date for this event has passed.</h4>
    <p>
      Did you hire <b>{{ quote?.listing?.title }}</b> for your {{ quote.event.title }} Request? If you did, leave them a review. If you didn't, mark
      the supplier as not used.
    </p>
  </div>
</div>

<div class="no-messages" *ngIf="quote?.quoteStatus === 'PUBLISHED_NEW' || quote?.quoteStatus === 'PUBLISHED_VIEWED'">
  <div *ngIf="!isMobile; else noMessageMobile">
    <img src="/assets/quote-page/start-conversation.svg" />
    <h4 class="no-messages__header">Start a conversation with {{ quote?.listing?.title }}</h4>
    <p class="no-messages__subtext">
      They have sent you a personalised quote and would love to know what you think. Please message them using the text input below.
    </p>
    <img class="no-messages__arrow" src="/assets/quote-page/arrow-down.svg" />
  </div>

  <ng-template #noMessageMobile>
    <div>
      <h4 class="no-messages__header">Start a conversation with {{ quote?.listing?.title }}</h4>
      <p class="no-messages__subtext">They have sent you a personalised quote and would love to know what you think</p>
    </div>
  </ng-template>
</div>

<div class="review" *ngIf="quote?.quoteStatus === 'ACCEPTED_PAST'">
  <ate-icon name="star-empty"></ate-icon>
  <div>
    <h4>Leave a review for this supplier!</h4>
    <p>
      How did they do? Leave a review for <b>{{ quote?.listing?.title }}</b
      >.
    </p>
    <ate-button-control (click)="leaveReview()" color="primary">Leave a review</ate-button-control>
  </div>
</div>

<div class="review" *ngIf="quote?.review?.dateCreated">
  <ate-icon name="star-empty"></ate-icon>
  <div>
    <h4>You left a {{ quote?.review?.stars }}-star review for {{ quote?.listing?.title }}!</h4>
    <p>
      Thanks for your feedback! Your review will help other organisers choose the best suppliers and help our suppliers to improve their services.
    </p>
    <ate-button-control (click)="viewReview()" color="primary">View review</ate-button-control>
  </div>
</div>
