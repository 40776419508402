<app-basic-header class="tw-sticky tw-top-0" (closeClick)="goToEventOverviewPage()"></app-basic-header>
<section class="tw-m-6 tw-mb-[97px] sm:tw-mx-auto sm:tw-max-w-[600px] lg:tw-my-14 lg:tw-max-w-[900px]">
  <div class="tw-mx-auto tw-mb-4 tw-flex tw-w-fit tw-items-center tw-gap-2 lg:tw-mb-5 lg:tw-gap-2.5">
    <ate-icon name="check-circle-filled-v3" class="tw-size-5 tw-text-green-700 lg:tw-size-6"></ate-icon>
    <p class="tw-m-0 tw-text-sm tw-font-semibold tw-text-green-800 lg:tw-text-base">We've posted your request</p>
  </div>

  <h2 class="tw-slate-800 lg:tw-text-3xlxl tw-mb-4 tw-text-center tw-text-xl tw-font-bold">Anything else you still need to book?</h2>

  <p class="tw-mb-6 tw-text-center tw-text-sm tw-font-semibold tw-text-slate-600 lg:tw-mb-10 lg:tw-text-lg">
    We recommend these services based on your event
  </p>
  <div class="tw-mb-6 tw-grid tw-cursor-pointer tw-grid-cols-2 tw-gap-4 lg:tw-mb-10 lg:tw-flex">
    @for (upsell of cardUpsells; track upsell.tid) {
      <app-image-card
        tabindex="0"
        class="tw-max-h-[132px] lg:tw-max-h-[198px]"
        (click)="openForm(upsell)"
        [imageUrl]="upsell.imgUrl"
        [title]="upsell.name"></app-image-card>
    }
  </div>

  @if (pillUpsells.length > 0) {
    <p class="tw-mb-6 tw-cursor-pointer tw-text-center tw-text-sm tw-font-semibold tw-text-slate-600 lg:tw-text-lg">
      Add to Event can also help you find...
    </p>
    <div class="tw-flex tw-flex-wrap tw-justify-center tw-gap-2 lg:tw-gap-5">
      @for (upsell of pillUpsells; track upsell.tid) {
        <app-image-pill
          tabindex="0"
          class="tw-h-[50px] tw-cursor-pointer lg:tw-h-[58px]"
          (click)="openForm(upsell)"
          [imageUrl]="upsell.imgUrl"
          [title]="upsell.name"></app-image-pill>
      }
    </div>
  }
</section>
<div class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-border-0 tw-border-t tw-border-solid tw-border-slate-300 tw-bg-white tw-px-4 tw-py-3">
  <ate-button-control class="tw-ml-auto tw-rounded-lg lg:tw-m-auto lg:tw-flex lg:tw-w-fit" color="cta" (click)="goToEventOverviewPage()">
    Skip
  </ate-button-control>
</div>
