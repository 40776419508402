/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject, first } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
  TemplateRef,
  ElementRef,
  Component,
  ViewChild,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
  OnDestroy
} from '@angular/core';

import { CloseEventRequestModalComponent } from '../modals/close-event-request-modal/close-event-request-modal.component';
import { AcceptQuoteModalComponent } from '../modals/accept-quote-modal/accept-quote-modal.component';
import { CommonModule } from '@angular/common';
import { QuoteService } from '../../../features/quotes/services/quote.service';
import { QuoteResponse } from '../../../features/quotes/services/quote.type';
import { ComponentsModule } from '../../../ui/components/components.module';
import { ListingItem } from '../../../ui/components/listing-list/listing-list-item/listing-list-item.component';
import { WindowService } from '../../services/window/window.service';
import { SharedModule } from '../../shared.module';
import { DeclineQuoteModalComponent } from '../modals/decline-quote-modal/decline-quote-modal.component';
import { ModalTypeEnum } from '../../enums/modal-type.enum';
import { EventRequestSingleType } from '../../services/event-request/event-request.type';
import { QuotesState } from '../../../store/reducers/quotes.reducer';
import { selectQuotes } from '../../../store/selectors/quotes.selectors';
import { quotesActions } from '../../../store/actions/quotes.actions';
import { AppState } from '../../../store/reducers/app.reducer';
import { appActions } from '../../../store/actions/app.actions';
import { EventState } from '../../../store/reducers/event.reducer';
import { selectEvent } from '../../../store/selectors/event.selectors';
import { eventActions } from '../../../store/actions/event.actions';

@Component({
  selector: 'app-request-actions',
  templateUrl: './request-actions.component.html',
  styleUrls: ['./request-actions.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, SharedModule, ComponentsModule, DeclineQuoteModalComponent, CloseEventRequestModalComponent, AcceptQuoteModalComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestActionsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modal', { read: ElementRef })
  private modal: ElementRef;

  @ViewChild('accept', { read: TemplateRef })
  private acceptTemplate: TemplateRef<any>;

  @ViewChild('decline', { read: TemplateRef })
  private declineTemplate: TemplateRef<any>;

  @ViewChild('closeEventRequest', { read: TemplateRef })
  private closeEventRequestTemplate: TemplateRef<any>;

  private action: ModalTypeEnum;
  public ModalTypeEnum = ModalTypeEnum;
  public eventRequest: EventRequestSingleType;
  public modalBodyTemplate$ = new ReplaySubject<TemplateRef<any>>();
  public isError = false;
  public showAccept = false;
  public quote: QuoteResponse;
  public listingItems: ListingItem[];
  public currentListing: ListingItem;
  public isMobile: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _windowService: WindowService,
    private _quoteStore: Store<QuotesState>,
    private _eventStore: Store<EventState>,
    private _cd: ChangeDetectorRef,
    public quoteService: QuoteService,
    private _store: Store<AppState>
  ) {}

  private subscribeToEvent(): void {
    this.isError = false;
    this._store.dispatch(appActions.IncrementLoadingCount());

    this._eventStore
      .select(selectEvent.getEvent)
      .pipe()
      .subscribe({
        next: data => {
          if (data) {
            this._store.dispatch(appActions.DecrementLoadingCount());
            this.eventRequest = data;
            this._cd.detectChanges();
            if (this.eventRequest?.quoteCount > 0) {
              // We can use any quoteUuid here to get the quotes response, so take the first.
              const firstQuoteUuid = this.eventRequest.quotes[0].uuid;
              this.dispatchGetQuote(firstQuoteUuid);
            }
          }
        },
        error: msg => {
          console.error(msg);
          this._store.dispatch(appActions.DecrementLoadingCount());
          this._cd.detectChanges();
        }
      });
  }

  private dispatchGetQuote(firstQuoteUuid: string) {
    this._quoteStore.dispatch(quotesActions.GetQuote({ payload: { id: firstQuoteUuid } }));
  }

  private dispatchGetEvent(eventUuid: string, eventRequestUuid: string): void {
    this._eventStore.dispatch(eventActions.GetEvent({ payload: { eventUuid, eventRequestUuid } }));
  }

  /**
   * SET outstanding list of quotes to accept or decline
   * filter out current listing's quote
   * fitler out already declined or accepted quotes
   */
  private setOutstandingQuotes() {
    const listings = this.quote.listings.filter(l => !(l.uuid === this.quote.listing.uuid || l.organiserAccepted || l.organiserDeclined));

    this.listingItems =
      listings.length !== 0
        ? listings.map(listing => ({
            uuid: listing.uuid,
            title: listing.title,
            images: listing?.images,
            ratings: listing?.reviewCount,
            stars: listing?.reviewRating,
            quoteUuid: listing.quoteUuid,
            actionButtons: true,
            selected: false
          }))
        : [];
  }

  private subscribeToQuote(): void {
    this.isError = false;
    this._store.dispatch(appActions.IncrementLoadingCount());

    this._quoteStore
      .select(selectQuotes.getQuote)
      .pipe()
      .subscribe({
        next: data => {
          if (data) {
            this._store.dispatch(appActions.DecrementLoadingCount());
            this.quote = data;
            this.currentListing = {
              uuid: this.quote?.listing.uuid,
              title: this.quote?.listing.title,
              images: this.quote?.listing?.images,
              ratings: this.quote?.listing?.reviewCount,
              stars: this.quote?.listing?.reviewRating,
              selected: true,
              declined: this.action === 'decline',
              quoteUuid: this.quote.uuid
            };
            this.setOutstandingQuotes();
          }
          this._cd.detectChanges();
        },
        error: msg => {
          console.error(msg);
          this._store.dispatch(appActions.DecrementLoadingCount());
          this.isError = true;
          this._cd.detectChanges();
        }
      });
  }

  public ngOnInit(): void {
    this.action = this._route.snapshot.data?.action;

    this.isMobile = this._windowService.isMobile();
    if (this.action === ModalTypeEnum.CLOSE_REQUEST) {
      this.subscribeToEvent();
    }
    this.subscribeToQuote();
  }

  public ngAfterViewInit(): void {
    this.switchModal({
      listingItem: this.currentListing,
      modalType: this.action
    });
    this._cd.detectChanges();
  }

  public ngOnDestroy(): void {
    const eventRequestUuid = this._route.parent.snapshot.paramMap.get('eventRequestUuid');
    const eventUuid = this.eventRequest?.event?.uuid;

    if (eventRequestUuid && eventUuid) {
      this.dispatchGetEvent(eventUuid, eventRequestUuid);
      return;
    }

    this.getEventFromQuotePage();
  }

  /**
   * The quote page doesn't have access to the eventRequestUuid or the eventUuid
   * in the URL, so we need to get these from the selected quote.
   */
  private getEventFromQuotePage(): void {
    this._quoteStore
      .select(selectQuotes.getQuote)
      .pipe(first())
      .subscribe(quote => {
        this.dispatchGetEvent(quote.event.eventUuid, quote.event.eventRequestUuid);
      });
  }

  @HostListener('window:resize')
  public onResize() {
    this.isMobile = this._windowService.isMobile();
  }

  /**
   * Switches the modal we display to the user whilst retaining the listing data in
   * memory.
   *
   * @param listing The listing passed from the previous modal.
   * @param modalType The type of modal we should open.
   */
  public switchModal({ listingItem, modalType }: { listingItem: ListingItem; modalType: ModalTypeEnum }): void {
    switch (modalType) {
      case ModalTypeEnum.ACCEPT_QUOTE:
        this.currentListing = {
          ...listingItem,
          actionButtons: false,
          declined: false,
          selected: true
        };
        this.modalBodyTemplate$.next(this.acceptTemplate);
        break;
      case ModalTypeEnum.DECLINE_QUOTE:
        this.currentListing = {
          ...listingItem,
          actionButtons: false,
          declined: true,
          selected: true
        };
        this.modalBodyTemplate$.next(this.declineTemplate);
        break;
      case ModalTypeEnum.CLOSE_REQUEST:
        this.action = ModalTypeEnum.CLOSE_REQUEST;
        this.modalBodyTemplate$.next(this.closeEventRequestTemplate);
        break;
    }
  }

  public close() {
    if (!this.isMobile) {
      this.modal.nativeElement.close();
    } else {
      this.navigateBack();
    }
  }

  public navigateBack() {
    this._router.navigate(['..'], {
      relativeTo: this._route
    });
  }
}
