<div class="inner-container tw-h-full">
  <header>
    <div class="title">
      <ate-icon data-cy="back-button" (click)="goBack()" name="chevron-left-v2"></ate-icon>
      <app-profile-picture
        [image]="(quote?.listing?.images)[0] | replace: '{style}' : 'one_half'"
        [shortName]="quote?.listing?.user | shortname"></app-profile-picture>

      <section>
        <h1 (click)="goToListing()" data-cy="listing-headline-mobile">
          {{ quote?.listing.title }}
          <ate-icon name="chevron-right-v2"></ate-icon>
        </h1>
        <p class="review-rating">
          <ate-star-bar color="orange" [attr.percent]="quote?.listing.reviewRating * 20"></ate-star-bar>
          ({{ quote?.listing?.reviewCount }})
        </p>
      </section>

      <app-quote-context-menu
        [quoteUuid]="quoteUuid"
        [listingUuid]="quote?.listing?.uuid"
        [listingTitle]="quote?.listing?.title"
        [listing]="quote?.listing"
        [status]="quote?.quoteStatus"
        [review]="quote?.review"></app-quote-context-menu>
    </div>
    <div class="status">
      <app-quote-status-info [quoteEstimate]="quote?.quotePrice" [status]="quote?.quoteStatus"></app-quote-status-info>

      <app-status-pill *ngIf="statusText" [text]="statusText" [status]="statusColor"></app-status-pill>
    </div>
  </header>

  <app-quote-body [quoteBody]="quoteBody" [listingTitle]="quote?.listing?.title"></app-quote-body>

  <app-chat
    [quoteUuid]="quoteUuid"
    [phoneNumber]="quote?.listing?.phoneNumber"
    [listing]="quote?.listing"
    [fixedMessageBox]="true"
    [displayControls]="true"
    [status]="quote?.quoteStatus">
    <app-quote-events [quote]="quote"></app-quote-events>
  </app-chat>
</div>
