import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventRequestStatusEnum } from '../../../../shared/services/event-request/event-request-status.enum';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProgressComponent {
  @Input() status: EventRequestStatusEnum;

  eventRequestStatusEnum = EventRequestStatusEnum;
}
