export enum BusinessType {
  'Sole Trader',
  'Limited Company Ltd',
  'Partnership',
  'Limited Liability Partnership',
  'Public Limited Company ',
  'Charity',
  'Co-operative',
  'Non Profit Organisation'
}
