import { Component, Input } from '@angular/core';
import { IUserNotification } from '../../services/notifications/notification.interface';
import { CommonModule } from '@angular/common';
import { NotificationItemComponent } from '../notification-item/notification-item.component';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  standalone: true,
  imports: [CommonModule, NotificationItemComponent]
})
export class NotificationListComponent {
  @Input() notifications: IUserNotification[];
  @Input() large = false;
}
