import { Component, Input } from '@angular/core';
import { UtilityMessageConfig } from '../../models/misc';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-utility-message',
  templateUrl: './utility-message.component.html',
  styleUrls: ['./utility-message.component.scss']
})
export class UtilityMessageComponent {
  @Input() config: UtilityMessageConfig;

  constructor(public router: Router) {}

  onClick(event) {
    switch (event) {
      case 'invite':
        window.location.href = `${environment.webUrl}/request/${this.config.data.eventRequestUuid}/matches`;
        break;
      case 'invite-tab':
        this.router.navigate(['event-request', this.config.data.eventUuid, this.config.data.eventRequestUuid, 'invited']);
        break;
    }
  }
}
