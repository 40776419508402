import { Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import { ListingItem } from './listing-list-item/listing-list-item.component';
import { QuoteActionType } from '../../../shared/enums/quote-action-type.enum';

@Component({
  selector: 'app-listing-list',
  templateUrl: './listing-list.component.html',
  styleUrls: ['./listing-list.component.scss']
})
export class ListingListComponent implements OnChanges {
  @Input() public listingItems: ListingItem[] = [];
  @Input() public actionButtons = true;
  @Input() public displayViewQuoteButton = false;
  @HostBinding('class.quote-overflow') quoteOverflow = false;
  @Output() listingItemClicked = new EventEmitter<{
    action: QuoteActionType;
    listingItem: ListingItem;
  }>();

  public selectAction(action: QuoteActionType, listingItem: ListingItem): void {
    this.listingItemClicked.emit({ action, listingItem });
  }

  public ngOnChanges(): void {
    // More than 3 listing items is the point where we need to handle
    // overflow from the modal.
    if (this.listingItems?.length > 3) {
      this.quoteOverflow = true;
    }
  }
}
