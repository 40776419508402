export enum FirebaseFeatureFlags {
  GatewayEndpoints = 'GATEWAY ENDPOINTS',
  NavCreditCounter = 'Nav Credit Counter',
  QuotingAi = 'Quoting AI',
  PURCHASE_VIEW = 'PurchaseView',
  PROFILE_VIEW = 'ProfileView',
  ACCOUNTS_VIEW = 'AccountsView',
  TESTING_FEATURES = 'TESTING_FEATURES',
  SUPPLIER_INTEREST = 'SUPPLIER_INTEREST',
  ATE_DEVELOPER = 'FULL_ACCESS'
}
