<div *ngIf="config" [ngClass]="config?.class" class="empty-container tw-flex tw-flex-auto tw-flex-col tw-items-center tw-justify-center tw-gap-4">
  <div class="empty-icon tw-flex tw-items-center" *ngIf="config.icon">
    <ate-icon [attr.name]="config.icon.name" [ngClass]="config.icon.class"></ate-icon>
  </div>
  <div class="empty-image tw-flex tw-items-center" *ngIf="config.image">
    <img [src]="config.image" [alt]="config.title" />
  </div>
  <div class="empty-title" *ngIf="config.title">
    <h2>{{ config.title }}</h2>
  </div>
  <div class="empty-message" *ngIf="config.message">
    <p class="tw-mb-0">{{ config.message }}</p>
  </div>
  <div class="empty-action tw-flex tw-flex-col" *ngIf="config.actions">
    <button *ngFor="let button of config.actions" [ngClass]="button.class" (click)="emitActionTrigger(button.trigger)">
      <ate-icon *ngIf="button.icon" [attr.name]="button.icon"></ate-icon>
      <span class="tw-mb-0">{{ button.text }}</span>
    </button>
  </div>
</div>
