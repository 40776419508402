import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

// @TODO refactor
// those types should not be reuse here
import { EventRequestColorEnum } from '../../ui/components/status-pill/status-pill-color.enum';
import { QuoteResponse } from '../../features/quotes/services/quote.type';
import { EventDeclineReason, SUPPLIER_DECLINE_REASON_TEXT } from '../enums';
import { QuoteStatus } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class QuoteAPIService {
  constructor(
    private router: Router,
    private http: HttpClient
  ) {}

  public getOneQuote(uuid: string, queryParams: { [key: string]: string } = {}): Observable<QuoteResponse> {
    return this.http
      .get<QuoteResponse>(environment.apiUrl + '/org/quote/' + uuid, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        },
        params: queryParams
      })
      .pipe(catchError(err => this.handleError(err)));
  }

  public acceptQuotes(uuids: string[], acceptReasons?: string[], keepRequestOpen?: boolean): Observable<QuoteResponse> {
    return this.http
      .post<QuoteResponse>(
        environment.apiUrl + '/org/quote-accept',
        {
          quoteUuids: uuids,
          acceptReasons,
          keepRequestOpen
        },
        {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json'
          }
        }
      )
      .pipe(catchError(err => this.handleError(err)));
  }

  public undoAcceptQuote(quoteUuid: string): Observable<QuoteResponse> {
    return this.http
      .post<QuoteResponse>(
        `${environment.apiUrl}/org/quote-accept/undo/${quoteUuid}`,
        {},
        {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json'
          }
        }
      )
      .pipe(catchError(err => this.handleError(err)));
  }

  public undoDeclineQuote(quoteUuid: string): Observable<QuoteResponse> {
    return this.http
      .post<QuoteResponse>(
        `${environment.apiUrl}/org/quote-decline/undo/${quoteUuid}`,
        {},
        {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json'
          }
        }
      )
      .pipe(catchError(err => this.handleError(err)));
  }

  public declineQuotes(uuids: string[], declineReason?: string): Observable<QuoteResponse> {
    return this.http
      .post<QuoteResponse>(
        environment.apiUrl + '/org/quote-decline',
        {
          quoteUuids: uuids,
          declineReason
        },
        {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json'
          }
        }
      )
      .pipe(catchError(err => this.handleError(err)));
  }

  // @TODO
  // abstract
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }

    // if 400 error issue with app, wrong version?
    if (error.status >= 400 && error.status < 500 && error.status !== 401) {
      this.router.navigate(['error']);
      return throwError(() => new Error('error'));
    }

    // Service offline, not issue with app version so allow try again
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  processStatus(status: QuoteStatus): { statusText: string; statusColor: EventRequestColorEnum } {
    let statusText = '';
    let statusColor: EventRequestColorEnum;
    switch (status) {
      case QuoteStatus.UNPUBLISHED:
        break;
      case QuoteStatus.ARCHIVED_BEFORE:
        break;
      case QuoteStatus.PUBLISHED_NEW:
        statusText = 'NEW QUOTE';
        statusColor = EventRequestColorEnum.BLUE;
        break;
      case QuoteStatus.PUBLISHED_VIEWED:
        statusText = 'RESPOND';
        statusColor = EventRequestColorEnum.BROWN;
        break;
      case QuoteStatus.PUBLISHED_MESSAGED:
        break;
      case QuoteStatus.ACCEPTED_FUTURE:
        statusText = 'CHOSEN';
        statusColor = EventRequestColorEnum.GREEN;
        break;
      case QuoteStatus.REJECTED_FUTURE:
        statusText = 'DECLINED';
        statusColor = EventRequestColorEnum.GREY;
        break;
      case QuoteStatus.ACCEPTED_PAST:
        statusText = 'LEAVE REVIEW';
        statusColor = EventRequestColorEnum.BLUE;
        break;
      case QuoteStatus.PUBLISHED_PAST:
        break;
      case QuoteStatus.REJECTED_PAST:
        statusText = 'DECLINED';
        statusColor = EventRequestColorEnum.GREY;
        break;
      case QuoteStatus.PUBLISHED_REVIEWED:
        statusText = 'REVIEWED';
        statusColor = EventRequestColorEnum.GREEN;
        break;
      case QuoteStatus.ARCHIVED_AFTER:
        break;
    }

    return { statusColor, statusText };
  }

  processSupplierDelineReason(reason: EventDeclineReason): string {
    return SUPPLIER_DECLINE_REASON_TEXT.get(reason);
  }
}
