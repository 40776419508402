import { createAction, props } from '@ngrx/store';
import { IUserNotification } from '../../shared/services/notifications/notification.interface';

export enum UserActionEnum {
  SetUserDetailsState = '[User] Setting User Details State',
  SetUserNotificationsState = '[User] Setting User Notifications State',
  SetInterestedState = '[User] Setting Interested State'
}

export const SetUserDetails = createAction(
  UserActionEnum.SetUserDetailsState,
  props<{
    payload: {
      isSupplier: boolean;
      profileImageUrl: string;
    };
  }>()
);

export const SetUserNotifications = createAction(
  UserActionEnum.SetUserNotificationsState,
  props<{
    payload: IUserNotification[];
  }>()
);

export const SetInterestedState = createAction(
  UserActionEnum.SetInterestedState,
  props<{
    payload: boolean;
  }>()
);
