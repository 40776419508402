import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { SharedModule } from '../../shared/shared.module';
import { ActionPanelComponent } from './action-panel/action-panel.component';
import { ComponentsModule } from '../components/components.module';
import { NotificationsSidebarComponent } from './notifications-sidebar/notifications-sidebar.component';
import { NotificationListComponent } from '../../shared/components/notification-list/notification-list.component';
import { MatTooltip } from '@angular/material/tooltip';

@NgModule({
  declarations: [FooterComponent, HeaderComponent, SidebarComponent, NotificationsSidebarComponent, SubheaderComponent, ActionPanelComponent],
  exports: [FooterComponent, HeaderComponent, SidebarComponent, NotificationsSidebarComponent, SubheaderComponent, ActionPanelComponent],
  imports: [CommonModule, SharedModule, ComponentsModule, NotificationListComponent, MatTooltip],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContainerModule {}
