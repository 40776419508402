import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EmptyMessageActionTriggers, EmptyMessageConfig } from '../../../../shared/models/misc';

@Component({
  selector: 'app-empty-message',
  templateUrl: './empty-message.component.html',
  styleUrls: ['./empty-message.component.scss']
})
export class EmptyMessageComponent {
  @Input() config: EmptyMessageConfig;
  @Output() actionTrigger: EventEmitter<EmptyMessageActionTriggers> = new EventEmitter<EmptyMessageActionTriggers>();

  emitActionTrigger(actionTrigger: EmptyMessageActionTriggers) {
    this.actionTrigger.emit(actionTrigger);
  }
}
