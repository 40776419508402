<section>
  <header>
    <div class="tw-box-border tw-max-w-[1280px]">
      <h1 class="tw-text-2xl tw-font-bold tw-text-slate-800">My Requests</h1>
    </div>
  </header>

  <mat-tab-group
    (selectedIndexChange)="tabSelected($event)"
    disablePagination=""
    [mat-stretch-tabs]="isMobile"
    animationDuration="0"
    disableRipple="">
    <mat-tab class="tw-max-w-[1280px]" [label]="'Active'">
      <div *ngIf="(isLoading$ | async) === false && !isError && activeData?.length">
        <div class="tw-grid tw-gap-5 tw-p-5 md:tw-grid-cols-2 md:tw-p-6 xl:tw-grid-cols-3 xl:tw-p-8">
          <app-event-request-tile
            data-cy="event-request-list-item-active"
            class="tw-rounded-2xl tw-border tw-border-solid tw-border-slate-300 tw-bg-white"
            *ngFor="let eventRequest of activeData; let i = index"
            [attr.data-index]="i"
            [eventRequest]="eventRequest"
            [eventRequestActive]="eventRequestActive"
            [showInterestedCounter]="showInterestedCounter"
            [submitRequest]="false"
            (click)="setRedirection(eventRequest)"
            [tabIndex]="i + 1"></app-event-request-tile>
        </div>
        <div class="tw-p-5 md:tw-p-6 xl:tw-p-8">
          <app-event-request-tile
            class="tw-rounded-2xl"
            *ngIf="innerWidth > 1023"
            [ngClass]="
              getLastRowItemCount(activeData?.length) === 3
                ? 'md:tw-col-span-3'
                : getLastRowItemCount(activeData?.length) === 2
                  ? 'tw-col-span-1'
                  : 'md:tw-col-span-2'
            "
            [submitRequest]="true"
            [submitRequestText]="event ? 'Submit a new service request for this event' : 'Submit a new service request'"
            (click)="placeNewRequest()">
          </app-event-request-tile>
        </div>
      </div>
    </mat-tab>
    <mat-tab class="tw-max-w-[1280px]" [label]="'Inactive'">
      <div
        *ngIf="(isLoading$ | async) === false && !isError && inactiveData?.length"
        class="tw-grid tw-gap-5 tw-p-5 md:tw-grid-cols-2 md:tw-p-6 xl:tw-grid-cols-3 xl:tw-p-8">
        <app-event-request-tile
          data-cy="event-request-list-item-inactive"
          class="tw-rounded-2xl tw-border tw-border-solid tw-border-slate-300 tw-bg-white"
          *ngFor="let eventRequest of inactiveData; let i = index"
          [attr.data-index]="i"
          [eventRequest]="eventRequest"
          [eventRequestActive]="eventRequestActive"
          [submitRequest]="false"
          (click)="setRedirection(eventRequest)"
          [tabIndex]="i + 1"></app-event-request-tile>

        <app-event-request-tile
          class="tw-rounded-2xl"
          *ngIf="innerWidth > 1023"
          [ngClass]="
            getLastRowItemCount(inactiveData?.length) === 3
              ? 'md:tw-col-span-3'
              : getLastRowItemCount(inactiveData?.length) === 2
                ? 'tw-col-span-1'
                : 'md:tw-col-span-2'
          "
          [submitRequest]="true"
          [submitRequestText]="event ? 'Submit a new service request for this event' : 'Submit a new service request'"
          (click)="placeNewRequest()">
        </app-event-request-tile>
      </div>
    </mat-tab>
  </mat-tab-group>

  <app-empty-message
    *ngIf="(isLoading$ | async) === false && (isError || (activeTab === 0 && !activeData?.length) || (activeTab === 1 && !inactiveData?.length))"
    class="tw-m-5"
    [config]="emptyMessageConfig"
    (actionTrigger)="receiveActionTrigger($event)"></app-empty-message>

  <div class="tw-mb-5 tw-px-5" *ngIf="(isLoading$ | async) === false && innerWidth <= 1023">
    <ate-button-control color="cta" (click)="placeNewRequest()">
      <ate-icon name="plus" slot="start"></ate-icon>
      Add Service Request
    </ate-button-control>
  </div>
</section>
