import { Component, CUSTOM_ELEMENTS_SCHEMA, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IMainEventData } from '../../../../shared/models/events';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { EventItemComponent } from '../../event-item/event-item.component';

@Component({
  selector: 'app-event-request-dialog',
  templateUrl: './event-request-dialog.component.html',
  styleUrl: './event-request-dialog.component.scss',
  standalone: true,
  imports: [CommonModule, EventItemComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EventRequestDialogComponent implements OnDestroy {
  events: IMainEventData[];
  subscriptionList: Subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<EventRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  receiveEmittedEvent(event: IMainEventData) {
    if (event) {
      this.closeDialog(event);
    }
  }

  closeDialog(data): void {
    this.dialogRef.close(data);
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
