import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewMoreDetailsComponent } from './view-more-details/view-more-details.component';
import { ReadMoreComponent } from './view-more-details/read-more/read-more.component';
import { StatusPillComponent } from './status-pill/status-pill.component';
import { ServiceListItemComponent } from './service-list-item/service-list-item.component';
import { ReviewListItemComponent } from './listing-reviews/review-list-item/review-list-item.component';
import { ListingProfilePictureComponent } from './profile-picture/listing-profile-picture/listing-profile-picture.component';
import { ProfilePictureComponent } from './profile-picture/organiser-profile-picture/profile-picture.component';
import { EventRequestListItemComponent } from '../../features/event-request/components/event-request-list-item/event-request-list-item.component';
import { SharedModule } from '../../shared/shared.module';
import { QuoteCountComponent } from './quote-count/quote-count.component';
import { ListingListComponent } from './listing-list/listing-list.component';
import { ListingListItemComponent } from './listing-list/listing-list-item/listing-list-item.component';
import { ListingReviewsComponent } from './listing-reviews/listing-reviews.component';
import { LinkComponent } from './link/link.component';
import { MaterialModule } from '../../shared/material/material.module';
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { InviteCarouselSlideComponent } from './invite-carousel-slide/invite-carousel-slide.component';

@NgModule({
  declarations: [
    ViewMoreDetailsComponent,
    ReadMoreComponent,
    StatusPillComponent,
    ServiceListItemComponent,
    ListingReviewsComponent,
    ReviewListItemComponent,
    ListingProfilePictureComponent,
    ProfilePictureComponent,
    EventRequestListItemComponent,
    QuoteCountComponent,
    ListingListComponent,
    ListingListItemComponent,
    LinkComponent,
    FilterBarComponent
  ],
  exports: [
    ViewMoreDetailsComponent,
    ReadMoreComponent,
    StatusPillComponent,
    ServiceListItemComponent,
    ListingReviewsComponent,
    ReviewListItemComponent,
    ListingProfilePictureComponent,
    ProfilePictureComponent,
    EventRequestListItemComponent,
    QuoteCountComponent,
    ListingListComponent,
    ListingListItemComponent,
    LinkComponent,
    FilterBarComponent
  ],
  imports: [CommonModule, SharedModule, MaterialModule, InviteCarouselSlideComponent, RouterLinkActive, RouterLink],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
