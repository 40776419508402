<div class="tw-flex tw-h-full tw-flex-auto tw-flex-col tw-rounded-xl tw-border tw-border-solid tw-border-slate-300 tw-bg-white">
  <div class="tw-mt-15 tw-m-auto tw-flex tw-max-w-[720px] tw-flex-auto tw-flex-col tw-items-start tw-px-4 tw-py-6 lg:tw-px-12 lg:tw-py-14">
    <!-- Awaiting quotes -->
    <h2 class="tw-m-auto tw-mb-6 tw-text-xl tw-font-bold tw-text-slate-800 lg:tw-mb-8 lg:tw-text-2xl">Here's what happens next</h2>

    <section class="animated fadeIn tw-flex tw-max-w-[720px] tw-flex-grow-0 tw-flex-row tw-content-center tw-justify-center">
      <div class="tw-ml-0.5 tw-mr-4 tw-flex tw-shrink-0 tw-flex-col tw-items-center tw-justify-start lg:tw-ml-0 lg:tw-mr-5 lg:tw-mt-0.5 lg:tw-w-7">
        <ate-icon name="ate-check-circle" class="tw-mb-0.5 tw-h-5 tw-w-5 tw-shrink-0 tw-text-green-700"></ate-icon>
        <div class="tw-mx-0 tw-my-[3px] tw-h-full tw-w-0.5 tw-bg-green-700"></div>
      </div>
      <div class="tw-mb-6 tw-flex tw-max-w-[650px] tw-flex-col lg:tw-mb-10">
        <h3 class="tw-mb-0 tw-text-base tw-font-bold tw-text-green-900">Your request and invites have been sent!</h3>
        <p class="tw-mb-0 tw-mt-2 tw-text-sm tw-text-slate-600 lg:tw-block">
          Your service request is live and has been sent to matched suppliers. We've also notified your chosen suppliers that you're interested in
          receiving a quote from them.
        </p>
      </div>
    </section>

    <section class="animated fadeIn tw-flex tw-max-w-[720px] tw-flex-grow-0 tw-content-center tw-justify-center lg:tw-flex-row">
      <div class="tw-mr-4 tw-flex tw-flex-col tw-items-center tw-justify-start lg:tw-mr-5 lg:tw-mt-0.5 lg:tw-w-7">
        <div
          class="tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-gradient-to-r tw-from-brand-gradientfrom tw-to-brand-gradientto lg:tw-mb-0.5 lg:tw-h-7 lg:tw-w-7">
          <div class="tw-h-[14px] tw-w-[14px] tw-rounded-full tw-bg-white lg:tw-h-4 lg:tw-w-4"></div>
        </div>
        <ng-container *ngTemplateOutlet="defaultLine"></ng-container>
      </div>
      <div
        class="gradient-border tw-relative tw-mb-6 tw-box-content tw-flex tw-max-w-[650px] tw-flex-col tw-items-center tw-rounded-xl tw-border-2 tw-border-solid tw-border-transparent tw-bg-white tw-bg-clip-padding tw-p-6 lg:tw-mb-10 lg:tw-flex-row">
        <img src="assets/event-request-inbox/waiting-for-quotes.svg" class="tw-mr-6 tw-h-[100px] tw-w-[100px]" />
        <div class="tw-text-center lg:tw-text-left">
          <h3 class="tw-mb-0 tw-text-base tw-font-bold tw-text-slate-800 lg:tw-text-lg">You'll soon receive personalised quotes</h3>
          <p class="tw-mb-0 tw-mt-2 tw-text-sm tw-text-slate-600">
            Suppliers who are interested will send you a quote with an estimated price and more details on their services. You should start receiving
            your first quotes in a few hours. You’ll receive these quotes via email too.
          </p>
        </div>
      </div>
    </section>

    <section class="animated fadeIn tw-flex tw-max-w-[720px] tw-flex-grow-0 tw-flex-row tw-content-center tw-justify-center">
      <div class="tw-ml-0.5 tw-mr-4 tw-flex tw-shrink-0 tw-flex-col tw-items-center tw-justify-start lg:tw-ml-0 lg:tw-mr-5 lg:tw-mt-0.5 lg:tw-w-7">
        <ng-container *ngTemplateOutlet="defaultCircle"></ng-container>
      </div>
      <div class="tw-flex tw-max-w-[650px] tw-flex-col">
        <h3 class="tw-mb-0 tw-text-base tw-font-bold tw-text-slate-600">Chat with and book suppliers</h3>
        <p class="tw-mb-0 tw-mt-2 tw-text-sm tw-text-slate-600">
          Chat with suppliers, view their profiles, read reviews and view images so you can make an informed decision. When you find your perfect
          match, call or message your favourite suppliers to seal the deal. Click the 'Choose Supplier' button to confirm.
        </p>
      </div>
    </section>
  </div>
</div>

<ng-template #defaultCircle>
  <div class="tw-flex tw-h-5 tw-w-5 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-bg-slate-300 lg:tw-mb-0.5">
    <div class="tw-h-3 tw-w-3 tw-rounded-full tw-bg-white"></div>
  </div>
</ng-template>

<ng-template #defaultLine>
  <div class="tw-mx-0 tw-my-[3px] tw-h-full tw-w-0.5 tw-bg-slate-300"></div>
</ng-template>
