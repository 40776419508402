<div>
  <header class="tw-bg-white tw-px-5 tw-pb-1 tw-pt-5">
    <div class="tw-box-border tw-flex tw-max-w-[1280px] tw-flex-auto tw-justify-between">
      <h1 class="tw-text-2xl tw-font-bold tw-text-slate-800">My Events</h1>
    </div>
  </header>

  <mat-tab-group
    [(selectedIndex)]="selectedIndex"
    (selectedIndexChange)="tabSelected($event)"
    disablePagination=""
    [mat-stretch-tabs]="isMobile"
    animationDuration="0"
    disableRipple=""
    class="events-tab-group"
    *ngIf="events">
    <mat-tab class="tw-max-w-[1280px] tw-p-5" [label]="'Upcoming'">
      <app-information-box
        *ngIf="showMultipleEventsMessage"
        linkHref="https://organiser-support.addtoevent.co.uk/en/articles/10086302-changing-the-parent-event-for-a-request"
        linkText="Find Out How"
        [showDismissButton]="true">
        <p class="tw-mb-0 tw-text-sm lg:tw-text-base">It looks like you have multiple events that are the same. We can help you organise them</p>
      </app-information-box>
      <div *ngIf="events.length" class="tw-flex tw-flex-auto tw-flex-col tw-gap-y-4">
        <app-event-item *ngFor="let event of events" [event]="event" [listSource]="'active-events'"></app-event-item>
        <app-event-request-tile
          class="tw-rounded-2xl"
          [submitRequest]="true"
          [submitRequestText]="'Create a new event'"
          (click)="openRequestQuotes()"
          *ngIf="createNewEventActive">
        </app-event-request-tile>
      </div>
      <div *ngIf="!events.length && !loadingEvents" class="events-upcoming-empty">
        <app-empty-message class="animated fadeIn" [config]="emptyMessageConfig" (actionTrigger)="receiveActionTrigger($event)"></app-empty-message>
      </div>
    </mat-tab>
    <mat-tab class="tw-max-w-[1280px] tw-gap-4 tw-p-5" [label]="'Past'">
      <div *ngIf="events.length" class="tw-flex tw-flex-auto tw-flex-col tw-gap-y-4">
        <app-event-item *ngFor="let event of events" [event]="event" [listSource]="'inactive-events'"></app-event-item>
        <app-event-request-tile
          class="tw-rounded-2xl"
          [submitRequest]="true"
          [submitRequestText]="'Create a new event'"
          (click)="openRequestQuotes()"
          *ngIf="createNewEventActive">
        </app-event-request-tile>
      </div>
      <div *ngIf="!events.length && !loadingEvents" class="events-upcoming-empty">
        <app-empty-message class="animated fadeIn" [config]="emptyMessageConfig"></app-empty-message>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
