import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class WindowService {
  constructor(@Inject(DOCUMENT) readonly document: Document) {}

  /**
   * Utility function to get the window object.
   */
  public getWindowInnerWidth(): number {
    return this.document.defaultView.innerWidth;
  }

  /**
   * Utility function to refresh the page.
   */
  public refresh(): void {
    return this.document.defaultView.location.reload();
  }

  /**
   * Determine if the viewport is mobile sized or not.
   */
  public isMobile(): boolean {
    return this.getWindowInnerWidth() < 1024;
  }

  /**
   * Determine if the viewport is mobile sized or not.
   */
  public isSmallMobile(): boolean {
    return this.getWindowInnerWidth() < 768;
  }

  /**
   * Opens a URL.
   */
  public open(url = '', target = ''): Window {
    return this.document.defaultView.open(url, target);
  }

  public href = this.document.defaultView.location.href;
}
