import { createAction, props } from '@ngrx/store';
import { Auth } from '../models/auth.model';
import { AuthUser } from '../models';
import { Organisation } from '../models/organisation.model';

export const SetAuth = createAction('[Authentication] Setting Authentication State', props<{ payload: Auth }>());
export const RemoveAuth = createAction('[Authentication] Removing Authentication State');
export const SetUser = createAction('[Authentication] Setting User Authentication State', props<{ payload: AuthUser }>());
export const RemoveUser = createAction('[Authentication] Removing User Authentication State');
export const SetBeforeLogoutRoute = createAction('[Authentication] Setting Before Logout Route', props<{ payload: string }>());
export const RemoveBeforeLogoutRoute = createAction('[Authentication] Removing Before Logout Route');
export const RefreshToken = createAction('[Authentication] Refreshing token');
export const ToggleIsLoggedIn = createAction('[Authentication] Toggling isLoggedIn', props<{ payload: boolean }>());
export const SetOrganisation = createAction('[Authentication] Setting Organisation State', props<{ payload: Organisation }>());
export const RemoveOrganisation = createAction('[Authentication] Removing Organisation State');
export const AddForgotPasswordAttempt = createAction('[Authentication] Add forgot password attempt');
export const Login = createAction('[Authentication] Login');
export const Logout = createAction('[Authentication] Logout');
