import { Component, Input } from '@angular/core';
import { EventRequestColorEnum } from './status-pill-color.enum';

/**
 * @deprecated Use EnhancedStatusPillComponent instead
 */
@Component({
  selector: 'app-status-pill',
  templateUrl: './status-pill.component.html',
  styleUrls: ['./status-pill.component.scss']
})
export class StatusPillComponent {
  @Input() status: EventRequestColorEnum | number;
  @Input() text = '';
  @Input() small = false;
}
