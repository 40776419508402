<div class="image-list">
  <figure *ngFor="let image of images | slice: 0 : 3; let i = index" (click)="openImageLightbox(i)">
    <img [src]="image | replace: '{style}' : 'one_half'" />

    <div class="has-more" *ngIf="i === 2 && 3 < images.length">
      View all
      <br />
      {{ images.length }} images
    </div>
  </figure>
</div>
<app-lightbox>
  <app-lightbox-image *ngFor="let image of images" [url]="image | replace: '{style}' : 'x_large'"> </app-lightbox-image>
</app-lightbox>
