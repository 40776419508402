import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule]
})
export class NotificationItemComponent {
  @Input() title: string;
  @Input() image: string;
  @Input() date: string;
  @Input() url: string;
  @Input() type: string;
  @Input() large = false;
}
