<a [href]="url" class="tw-flex"
  ><div class="tw-relative">
    <figure [ngClass]="large ? 'tw-size-10' : 'tw-size-8'" class="tw-m-0 tw-mr-3 tw-shrink-0 tw-overflow-hidden tw-rounded-full">
      <img class="tw-w-full" [src]="image" />
    </figure>
    <div
      [ngClass]="large ? 'tw-size-3' : 'tw-size-[10px]'"
      class="tw-absolute -tw-left-0.5 -tw-top-0.5 tw-rounded-full tw-border-2 tw-border-solid tw-border-white tw-bg-red-600"></div>
  </div>

  <div class="tw-flex tw-flex-col">
    <h3 [ngClass]="large ? 'tw-text-base' : 'tw-text-sm'" class="tw-font-bold tw-text-slate-800">{{ title }}</h3>
    <p [ngClass]="large ? 'tw-pt-1' : 'tw-pt-0'" class="tw-m-0 tw-text-xs tw-font-normal tw-text-slate-600">{{ date | timeAgo }}</p>
  </div>
</a>
