import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { authenticationQuery } from '../../../store/selectors/authentication.selectors';
import { AuthUser } from '../../../store/models';
import { ChatService } from '../services/chat/chat.service';
import { quotesActions } from '../../../store/actions/quotes.actions';
import { QuoteStatus } from '../../../api/interfaces';
@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  @Input() public quoteUuid = '';
  @Input() public phoneNumber = '';
  @Input() public displayControls = false;
  @Input() public value = '';
  @Input() status: QuoteStatus;
  public placeholderText = '';
  private user: AuthUser;

  @Output() messageEvent = new EventEmitter<string>();
  @ViewChild('messageInput', { static: true, read: ElementRef })
  private messageInput: ElementRef;

  constructor(
    private _store: Store,
    private _chatService: ChatService
  ) {}

  public ngOnInit(): void {
    this._store.select(authenticationQuery.getUser).subscribe(user => {
      this.user = user;
    });
    this.placeholderText = [QuoteStatus.PUBLISHED_NEW, QuoteStatus.PUBLISHED_VIEWED].includes(this.status)
      ? 'Respond to quote…'
      : 'Message supplier…';
  }

  public updateMessage(e: Event): void {
    this.value = (e.target as HTMLTextAreaElement).value;
    this.messageEvent.emit((e.target as HTMLTextAreaElement).value);
  }

  public sendMessage(): void {
    if (!this.value) return;

    this._chatService.sendMessage(this.value, this.quoteUuid, this.user.userId).then(() => {
      this._store.dispatch(quotesActions.MessageSent());
    });

    this.resetValue();
    this.resetTextAreaRows();
  }

  private resetValue() {
    this.value = '';
    this.messageEvent.emit('');
  }

  private resetTextAreaRows(): void {
    this.messageInput.nativeElement.rows = 1;
  }
}
