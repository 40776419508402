import { Component, Input } from '@angular/core';
import { Review } from '../../../../shared/services/listing/interfaces';

@Component({
  selector: 'app-review-list-item',
  templateUrl: './review-list-item.component.html',
  styleUrls: ['./review-list-item.component.scss']
})
export class ReviewListItemComponent {
  @Input() review: Review;
  @Input() readAll = false;
  @Input() moreText = 'Read More';
  @Input() lessText = 'Read Less';
  @Input() toggleCallback: () => void;
}
