import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EventRequestType } from '../../../../shared/services/event-request/event-request.type';
import { RequestDescription, StatusConfig } from '../../../../shared/models/misc';
import { EventRequestStatusEnum } from '../../../../shared/services/event-request/event-request-status.enum';
import { EventRequestService } from '../../../../shared/services/event-request/event-request.service';

@Component({
  selector: 'app-event-request-tile',
  templateUrl: './event-request-tile.component.html',
  styleUrl: './event-request-tile.component.scss'
})
export class EventRequestTileComponent implements OnChanges {
  bgImage: string;
  quotesHighlight: boolean;
  requestDescription: RequestDescription;
  status: StatusConfig;
  @Input() eventRequest: EventRequestType;
  @Input() submitRequest: boolean;
  @Input() submitRequestText: string;
  @Input() eventRequestActive: boolean;
  @Input() showInterestedCounter: boolean;

  constructor(private _requestService: EventRequestService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.eventRequest) {
      this.bgImage = this.eventRequest.primaryServiceImageURI.replace(/ /g, '%20');
      this.status = this._requestService.setEventRequestData(this.eventRequest.eventRequestOrganiserStatus).status;
      this.requestDescription = this._requestService.setEventRequestData(this.eventRequest.eventRequestOrganiserStatus).requestDescription;
      this.setQuotesCounterState(this.eventRequest.eventRequestOrganiserStatus);
    }
  }

  /**
   * Set Quotes Counter state based on an event request status provided
   * @param eventRequestOrganiserStatus
   */
  setQuotesCounterState(eventRequestOrganiserStatus: EventRequestStatusEnum) {
    switch (eventRequestOrganiserStatus) {
      case EventRequestStatusEnum.NEW_QUOTE:
      case EventRequestStatusEnum.FIRST_QUOTE:
      case EventRequestStatusEnum.QUOTES_RECEIVED:
      case EventRequestStatusEnum.EXPIRED_ACTIVE_QUOTES:
        this.quotesHighlight = true;
        break;
      default:
        this.quotesHighlight = false;
        break;
    }
  }
}
