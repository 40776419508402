<ate-icon
  (click)="goBack()"
  class="tw-absolute tw-right-4 tw-top-4 tw-z-[100] tw-size-6 tw-cursor-pointer tw-text-white md:tw-right-6 md:tw-top-6 lg:tw-size-8"
  name="close-v2"></ate-icon>

<section [ngClass]="{ 'tw-mb-auto': panelOpen }" class="tw-z-10 tw-w-full tw-max-w-[560px]">
  <header class="tw-justify tw-flex tw-w-full tw-items-start tw-gap-2">
    <div class="tw-w-full">
      <h1 class="tw-mb-4 tw-text-base tw-text-white lg:tw-mb-2 lg:tw-text-center lg:tw-text-[30px] lg:tw-leading-[36px]">
        What do you need for your {{ eventType ? (eventType.replace('_', ' ') | lowercase) : 'event' }}?
      </h1>
      <h2 class="tw-mb-6 tw-hidden tw-text-center tw-text-sm tw-font-normal tw-text-white lg:tw-mb-10 lg:tw-block lg:tw-text-base">
        Use the search bar to find event services.
      </h2>
    </div>
  </header>
  <app-service-selector
    class="tw-w-full tw-self-start lg:tw-mt-48"
    (panelOpen)="setPanelOpen($event)"
    (selectedFormUuid)="openForm($event)"></app-service-selector>
</section>
<app-upsells
  *ngIf="event && event.upsells"
  class="[&_h2]:!tw-leading-md tw-relative tw-z-10 tw-w-full tw-max-w-[1012px] tw-text-center [&_h2]:!tw-text-base [&_h2]:tw-text-white"
  [eventRequest]="event.activeRequests.length ? event.activeRequests[0] : event.inactiveRequests[0]"
  [upsells]="event.upsells"
  [eventUpsells]="true"></app-upsells>

<div
  class="bg-item-1 md:right-[-143px] md:top-[-34px] tw-absolute tw-right-[-105px] tw-top-[-26px] tw-z-0 tw-size-[250px] tw-rounded-full md:tw-size-[400px] xl:tw-right-[-120px] xl:tw-top-[88px]"></div>

<div
  class="bg-item-2 tw-absolute tw-bottom-[-135px] tw-left-[-186px] tw-z-0 tw-size-[400px] tw-rounded-full md:tw-bottom-[-303px] md:tw-left-[-250px] md:tw-size-[600px] xl:tw-bottom-[-215px] xl:tw-left-[-202px]"></div>

<div
  class="bg-item-3 tw-absolute tw-left-[-128px] tw-top-[-114px] tw-z-0 tw-flex tw-h-[244px] tw-w-[151px] tw-rotate-[-36deg] tw-items-center tw-justify-center tw-rounded-[350px] md:tw-left-[-152px] md:tw-top-[-151px] md:tw-h-[346px] md:tw-w-[214px] xl:tw-left-[-96px] xl:tw-top-[-192px]">
  <div class="tw-h-[97%] tw-w-[93%] tw-rounded-[350px] tw-bg-brand-purple xl:tw-h-[95%] xl:tw-w-[91%]"></div>
</div>

<div
  class="bg-item-4 tw-absolute tw-bottom-[46px] tw-right-[-160px] tw-z-0 tw-flex tw-h-[272px] tw-w-[164px] tw-rotate-[-21deg] tw-items-center tw-justify-center tw-rounded-[350px] md:tw-bottom-[-44px] md:tw-right-[-163px] md:tw-h-[340px] md:tw-w-[205px] xl:tw-bottom-[-197px] xl:tw-right-[153px]">
  <div class="tw-h-[97%] tw-w-[93%] tw-rounded-[350px] tw-bg-brand-purple xl:tw-h-[95%] xl:tw-w-[91%]"></div>
</div>
