import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SupplierComponent } from './components/supplier/supplier.component';
import { SharedModule } from '../../shared/shared.module';
import { InviteCarouselSlideComponent } from '../../ui/invite-carousel-slide/invite-carousel-slide.component';

@NgModule({
  declarations: [SupplierComponent],
  imports: [CommonModule, InviteCarouselSlideComponent, SharedModule],
  exports: [SupplierComponent]
})
export class SupplierModule {}
