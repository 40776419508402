import { Component, Input } from '@angular/core';
import { SocialLink } from '../../../../shared/services/listing/interfaces';

@Component({
  selector: 'app-social-context-menu',
  templateUrl: './social-context-menu.component.html',
  styleUrls: ['./social-context-menu.component.scss']
})
export class SocialContextMenuComponent {
  @Input() socials: SocialLink[] = [];
  @Input() open = false;
  @Input() isMobile = true;

  toggleOpen(open?: boolean): void {
    if (typeof open === 'boolean') {
      this.open = open;
      return;
    }

    this.open = !this.open;
  }
}
