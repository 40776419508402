import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RequestActionsComponent } from '../../../../shared/standalone/request-actions/request-actions.component';
import { WindowService } from '../../../../shared/services/window/window.service';
import { environment } from '../../../../../environments/environment';
import { QuoteBodyType } from './quote-body.type';
import { QuoteService } from '../../services/quote.service';
import { first } from 'rxjs';
import { AppState } from '../../../../store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { appActions } from '../../../../store/actions/app.actions';
import { QuoteStatus } from '../../../../api';
import { ModalClickEvent } from '../../../../shared/enums/modal-click-event';

@Component({
  selector: 'app-quote-body',
  templateUrl: './quote-body.component.html',
  styleUrls: ['./quote-body.component.scss']
})
export class QuoteBodyComponent {
  @Input() quoteBody: QuoteBodyType;
  @Input() listingTitle = '';
  @Input() review: {
    url: string;
    dateCreated: string;
    stars: number;
  };

  @ViewChild('undoModal') undoModal: ElementRef;
  @ViewChild(RequestActionsComponent)
  RequestActionsComponent: RequestActionsComponent;
  constructor(
    private router: Router,
    private windowService: WindowService,
    private quoteService: QuoteService,
    private _store: Store<AppState>
  ) {}

  canBook(): boolean {
    // status that are before accepted or declined
    if (
      this.quoteBody?.quoteStatus === QuoteStatus.PUBLISHED_NEW ||
      this.quoteBody?.quoteStatus === QuoteStatus.PUBLISHED_VIEWED ||
      this.quoteBody?.quoteStatus === QuoteStatus.PUBLISHED_MESSAGED
    ) {
      return true;
    }

    return false;
  }

  leaveReview(): void {
    this.windowService.open(`${environment.webUrl}/node/${this.quoteBody.uuid}/review/submit`, '_self');
  }

  bookSupplier(): void {
    this.router.navigate(['/quote/', this.quoteBody?.uuid, 'accept'], {
      replaceUrl: true
    });
  }

  declineSupplier(): void {
    this.router.navigate(['/quote/', this.quoteBody?.uuid, 'decline'], {
      replaceUrl: true
    });
  }

  viewReview(): void {
    this.windowService.open(this.review.url, '_self');
  }

  isAccepted(): boolean {
    return this.quoteBody?.quoteStatus === QuoteStatus.ACCEPTED_FUTURE;
  }

  isDeclined(): boolean {
    const status = this.quoteBody?.quoteStatus;
    return status === QuoteStatus.REJECTED_FUTURE || status === QuoteStatus.REJECTED_PAST;
  }

  confirmUndo(event: CustomEvent<{ modalClickEvent: ModalClickEvent }>) {
    if (event.detail?.modalClickEvent === ModalClickEvent.CONFIRM_BUTTON_CLICKED) {
      this.undo(true);
    }
  }

  undo(confirmed = false) {
    const accepted = this.isAccepted();
    if (!accepted && !this.isDeclined()) {
      return;
    }
    if (!confirmed) {
      this.undoModal?.nativeElement?.open();
      return;
    }
    const uuid = this.quoteBody.uuid;
    const service = this.quoteService;
    this._store.dispatch(appActions.IncrementLoadingCount());
    const resp = accepted ? service.undoAcceptQuote(uuid) : service.undoDeclineQuote(uuid);
    resp.pipe(first()).subscribe({
      error: msg => {
        this._store.dispatch(appActions.DecrementLoadingCount());
        console.error(msg);
      },
      complete: () => {
        this._store.dispatch(appActions.DecrementLoadingCount());
      }
    });
  }
}
