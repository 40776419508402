<section class="lg:tw-overflow-auto lg:tw-border-0">
  <h2 class="tw-pt-5 tw-text-xl tw-font-bold tw-text-slate-800 lg:tw-pt-0 lg:tw-text-2xl">{{ invite?.title }}</h2>
  <p class="tw-pt-[6px] tw-text-gray-600">{{ invite?.subtitle }}</p>
  <div class="tw-flex tw-items-center">
    <ate-icon class="tw-h-6 tw-w-6 tw-pr-2 tw-text-amber-500" name="star"></ate-icon>
    <span class="tw-font-semibold tw-text-amber-800">{{ invite?.reviewRating }}&nbsp;</span>
    <span class="tw-text-slate-800">({{ invite?.reviewCount }} reviews)</span>
  </div>

  <div class="tw-mt-4 tw-flex tw-items-center">
    <ate-icon class="tw-h-6 tw-w-6 tw-shrink-0 tw-pr-2 tw-text-gray-800" name="handshake-v3"></ate-icon>
    <div
      class="tw-flex tw-h-[20px] tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-slate-300 tw-bg-slate-50 tw-px-3 tw-py-1">
      <p class="tw-m-0 tw-font-semibold tw-text-gray-800">
        <span>{{ invite?.bookingCount }}</span>
        <span *ngIf="invite?.bookingCount === 1">&nbsp;booking</span>
        <span *ngIf="invite?.bookingCount > 1 || invite?.bookingCount === 0">&nbsp;bookings</span>
      </p>
    </div>
  </div>

  <div class="tw-mt-4 tw-flex tw-items-center" *ngIf="invite?.distance">
    <ate-icon class="tw-h-6 tw-w-6 tw-shrink-0 tw-pr-2 tw-text-gray-800" name="location-pin-v2"></ate-icon>
    <div
      class="tw-flex tw-h-[20px] tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-solid tw-border-slate-300 tw-bg-slate-50 tw-px-3 tw-py-1">
      <p class="tw-m-0 tw-font-semibold tw-text-gray-800">
        <span>{{ invite?.distance }}</span>
        <span *ngIf="invite?.distance > 1 || invite?.distance === 0">&nbsp;miles away</span>
        <span *ngIf="invite?.distance === 1">&nbsp;mile away</span>
      </p>
    </div>
  </div>

  <div class="tw-mt-4 tw-flex tw-items-center">
    <ate-icon class="tw-h-6 tw-w-6 tw-shrink-0 tw-self-start tw-pr-2 tw-text-gray-800" name="tag-v2"></ate-icon>
    <app-info-tag-list *ngIf="serviceTags" [tags]="serviceTags"></app-info-tag-list>
  </div>

  <div class="tw-mt-4 tw-flex tw-items-center" *ngIf="invite?.lastReview">
    <ate-icon class="tw-h-6 tw-w-6 tw-shrink-0 tw-self-start tw-pr-2 tw-text-gray-800" name="thumbs-up-v3"></ate-icon>
    <section
      *ngIf="invite?.lastReview?.description"
      class="tw-flex tw-flex-col tw-rounded-lg tw-border tw-border-solid tw-border-slate-300 tw-bg-slate-50 tw-p-4 tw-text-gray-600">
      <ate-star-bar size="mediumlarge" color="orange" [attr.percent]="invite?.lastReview?.reviewRating * 20"></ate-star-bar>
      <h3 class="tw-mt-2 tw-text-base">{{ invite?.lastReview?.title }}</h3>
      <span class="tw-mt-3">{{ invite?.lastReview?.description }}</span>

      <div class="tw-mt-3 tw-flex tw-items-center">
        <span class="tw-font-semibold">{{ invite?.lastReview?.user?.firstName }} {{ invite?.lastReview?.user?.lastName }}&nbsp;•&nbsp;</span>
        <span class="tw-mr-2">{{ invite?.lastReview?.dateCreated | date: 'd MMM y' }}</span>
        <ate-icon class="tw-h-4 tw-w-4 tw-shrink-0 tw-self-start tw-self-center tw-pr-2" name="verified-v3"></ate-icon>
      </div>
    </section>
  </div>
</section>
