import { BookingsTabsEnum } from './enums/booking-tabs.enum';
import { QuoteTabsEnum } from './enums/quote-tabs.enum';

export interface TabConfig {
  title: QuoteTabsEnum | BookingsTabsEnum;
  indicator?: boolean;
  empty: { data: { icon: string; message: string; title: string } };
}

export const quotesTabConfig = {
  quotes: {
    title: 'My Quotes',
    tabs: [
      {
        title: QuoteTabsEnum.ACTIVE,
        empty: {
          data: {
            icon: 'projector',
            message: 'You don’t have any active quotes for your event requests.',
            title: 'All your active quotes will live here'
          }
        },
        indicator: true
      },
      {
        title: QuoteTabsEnum.INACTIVE,
        empty: {
          data: {
            icon: 'thumb-down',
            message:
              'You don’t have any inactive quotes for your event requests. Inactive quotes are quotes that you have declined or quotes where the event date has already passed.',
            title: 'All your inactive quotes will live here'
          }
        }
      }
    ]
  },
  bookings: {
    title: 'My Bookings',
    tabs: [
      {
        title: BookingsTabsEnum.UPCOMING,
        empty: {
          data: {
            icon: 'handshake',
            message: 'The suppliers you book for your service request(s) will be shown here.',
            title: 'All your supplier bookings will live here'
          }
        }
      },
      {
        title: BookingsTabsEnum.COMPLETED,
        empty: {
          data: {
            icon: 'handshake',
            message: 'The suppliers you booked for your completed service request(s) will be shown here.',
            title: 'All your completed supplier bookings will live here'
          }
        }
      },
      {
        title: BookingsTabsEnum.REVIEWED,
        empty: {
          data: {
            icon: 'handshake',
            message: 'The suppliers you booked and reviewed for your service request(s) will be shown here.',
            title: 'All your reviewed supplier bookings will live here'
          }
        }
      }
    ]
  }
};
