import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { compareVersions } from 'compare-versions';
import { environment } from '../../../../environments/environment';
import { AppversionService } from '../../../shared/services/version/appversion_service';
import { ModalClickEvent } from '../../../shared/enums/modal-click-event';

@Component({
  selector: 'updates-available-modal',
  templateUrl: './updates-available.component.html',
  styleUrls: ['./updates-available.component.scss']
})
export class UpdatesAvailableModalComponent implements OnInit {
  @ViewChild('ateModal') modal: ElementRef;

  constructor(private appVersionService: AppversionService) {}

  ngOnInit() {
    this.appVersionService.getAppVersionValueChanges().subscribe({
      next: ({ snapshot }) => {
        const appVersion = snapshot.val();
        const currentVersion = environment.version;

        if (compareVersions(currentVersion, appVersion) === -1) {
          this.promptUser();
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }

  private promptUser() {
    if (this.modal?.nativeElement && typeof this.modal.nativeElement.open === 'function') {
      this.modal.nativeElement.open();
    }
  }

  async reloadPage(event: CustomEvent<{ modalClickEvent: ModalClickEvent }>) {
    if (event.detail?.modalClickEvent === ModalClickEvent.CONFIRM_BUTTON_CLICKED) {
      this.reload();
    }
  }

  reload() {
    window.location.reload();
  }
}
