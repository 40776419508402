import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ListingService } from '../../../../shared/services/listing/listing.service';
import { BusinessType } from './enums/business.enum';
import { LightboxComponent } from '../../../../ui/carousel-lightboxes/lightbox/lightbox.component';
import { Listing, SocialLink } from '../../../../shared/services/listing/interfaces';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers/app.reducer';
import { appActions } from '../../../../store/actions/app.actions';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
  isLoading: Observable<boolean>;
  isError = false;
  uuid: string;
  listing: Listing;
  public termsOfUseUrl = environment.termsOfUseUrl;

  businessType: string;

  @ViewChild(LightboxComponent) lightbox: LightboxComponent;
  mouseX = 0;
  imageSelected = 0;

  socialLinks: SocialLink[] = [];

  constructor(
    public listingService: ListingService,
    private route: ActivatedRoute,
    private router: Router,
    private _store: Store<AppState>,
    @Inject(DOCUMENT) readonly document: Document
  ) {}

  ngOnInit(): void {
    this.isLoading = this._store.select(selectApp.getIsLoading);
    this.uuid = this.route.snapshot.paramMap.get('uuid');
    this.getListing();
  }

  goBack(): void {
    this.router.navigate(['../..'], {
      relativeTo: this.route
    });
  }

  goToListingReview(uuid?: string): void {
    const opt = uuid ? { fragment: 'review-' + uuid } : {};
    this.router.navigate(['/listing', this.uuid, 'reviews'], opt);
  }

  toggleReviewCallback(uuid: string): () => void {
    return () => this.goToListingReview(uuid);
  }

  getListing(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.isError = false;

    this.listingService.getOneListing(this.uuid).subscribe({
      next: data => {
        this._store.dispatch(appActions.DecrementLoadingCount());
        this.listing = data;

        this.businessType = BusinessType[this.listing.businessType];

        this.generateSocialLinks();
      },
      error: msg => {
        console.error(msg);
        this._store.dispatch(appActions.DecrementLoadingCount());
        this.isError = true;
      }
    });
  }

  openImageLightbox(image: number, e): void {
    this.imageSelected = image;
    const action = e.changedTouches ? e.changedTouches[0] : e;

    this.mouseX = action.clientX;
  }

  onMouseUp(e) {
    const action = e.changedTouches ? e.changedTouches[0] : e;

    if (Math.abs(action.clientX - this.mouseX) < 100) {
      this.lightbox.selectImage(this.imageSelected);
    }
  }

  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }

  generateSocialLinks(): void {
    const social = this.listing?.socialLinks;
    if (!this.listing?.socialLinks) {
      return;
    }

    const websiteLink = social.findIndex(value => value.type === 'website');

    // if website link exists push to the top
    if (websiteLink) {
      const topWebsite = { ...social[websiteLink] };
      social.splice(websiteLink, 1);
      this.socialLinks = [topWebsite, ...social];
      return;
    }

    this.socialLinks = social;
  }
}
