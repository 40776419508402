import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RoutingFilter } from './models/filter.interface';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnChanges {
  @Input() filters: RoutingFilter[] = [];
  @Output() selectedFilter: EventEmitter<RoutingFilter> = new EventEmitter<RoutingFilter>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters) {
      this.setIds(this.filters);
      this.filters.forEach(el => {
        el.active = !(!el.counter || (el.counter && el.counter === 0));
      });
    }
  }

  setIds(filters: RoutingFilter[]) {
    filters.forEach(filter => {
      filter.id = `btn-list-${filter.name.toLowerCase().replace(/\s/g, '-')}`;
    });
  }

  emitFilter(filter: RoutingFilter) {
    this.selectedFilter.emit(filter);
  }
}
