import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appActions } from '../../../store/actions/app.actions';
import { AppState } from '../../../store/reducers/app.reducer';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  constructor(
    private _router: Router,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this._store.dispatch(appActions.DecrementLoadingCount());
  }

  goToHome(): void {
    this._router.navigate(['/']);
  }
}
