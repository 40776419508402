/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog-base/dialog.component';
import { Dialog } from '../dialog.interface';
import { FcmNotificationsService } from '../../../services/fcm-notifications/fcm-notifications.service';
import { first } from 'rxjs';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss']
})
export class NotificationsDialogComponent implements Dialog, OnInit {
  @Output() closeDialog = new EventEmitter<void>();

  dialogRef: MatDialogRef<DialogComponent>;
  data: { [key: string]: unknown };

  constructor(private fcmNotificationsService: FcmNotificationsService) {}

  ngOnInit() {
    this.fcmNotificationsService.setModalShownFlag();
  }

  /**
   * @todo Implement this method.
   */
  requestNotificationPermission() {
    this.fcmNotificationsService
      .registerForNotifications()
      .pipe(first())
      .subscribe({
        next: () => {
          console.log('Saved registration token successfully');
        },
        error: err => {
          console.error(err);
        }
      });

    this.dialogRef.close();
  }
}
