import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-quote-accordion',
  templateUrl: './quote-accordion.component.html',
  styleUrls: ['./quote-accordion.component.scss']
})
export class QuoteAccordionComponent {
  @Input() title = '';
  @Input() count = 0;
  @Input() open = false;

  @ViewChild('mainSection', { static: true, read: ElementRef })
  mainContent: ElementRef;

  maxHeight = '0';

  public togglePanel(open?: boolean): void {
    this.open = open ? open : !this.open;

    if (this.open) {
      this.maxHeight = this.mainContent.nativeElement.scrollHeight + 'px';
    } else {
      this.maxHeight = null;
    }
  }
}
