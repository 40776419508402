/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, HostListener, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogTypes } from '../dialog-config';
import { DialogType } from '../../../models/dialogs';

/**
 * The base dialog component containing shared styling and logic for all dialogs.
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, AfterViewInit {
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true }) modalContainer!: ViewContainerRef;

  innerHeight: number;
  innerWidth: number;
  tags: string[];

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.setDimensions(this.data);
  }

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { [key: string]: any }
  ) {
    this.onResize();
  }

  ngOnInit() {
    this.setDimensions(this.data);
  }

  ngAfterViewInit(): void {
    if (this.data.modalComponentRef) {
      this.loadInnerModalComponent();
    }
  }

  setDimensions(data) {
    if (data) {
      if (data.type && (data.type === DialogType.SUGGESTED || data.type === DialogType.INTERESTED || data.type === DialogType.INVITED)) {
        this.dialogRef?.updateSize(this.innerWidth > 1023 ? '1000px' : '97vw', this.innerWidth > 1023 ? 'auto' : '97vh');
      } else {
        this.dialogRef?.updateSize(this.innerWidth > 600 ? '550px' : '95vw', 'auto');
      }
    }
  }

  closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }

  private loadInnerModalComponent() {
    const componentRef = this.modalContainer.createComponent<DialogTypes>(this.data?.modalComponentRef);
    componentRef.instance.data = this.data.data;
    componentRef.instance.dialogRef = this.dialogRef;
  }

  receiveEmmitedActionFromDialog(event) {
    this.closeDialog(event);
  }

  protected readonly DialogType = DialogType;
}
