<!-- Modal Header -->
<div class="modal">
  <div class="header">
    <span class="left"></span>
    <div class="center">
      <h1 header>{{ title }}</h1>
    </div>
    <div (click)="onClose.emit()" class="right">
      <!-- TODO change icon ??? -->
      <ate-icon slot="right" [name]="headerIcon" class="clear"></ate-icon>
    </div>
  </div>
  <!-- Modal Body -->
  <main>
    <div class="body">
      <div
        *ngIf="isLoading"
        class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 !tw-z-[9999] tw-flex tw-items-center tw-justify-center tw-backdrop-blur-sm lg:tw-bottom-20 lg:tw-top-16">
        <ate-icon
          class="tw-size-10 tw-animate-spin tw-rounded-full tw-bg-white tw-p-1 tw-text-white"
          name="loading-spinner-gradient-v3"
          slot="start"></ate-icon>
      </div>

      <div *ngIf="!isLoading" class="inner-container">
        <header>
          <h2>{{ stepTitle }}</h2>
          <p [innerHTML]="stepSubTitle"></p>
        </header>
        <ng-container [ngTemplateOutlet]="body$ | async"> </ng-container>
      </div>
    </div>
  </main>
</div>

<!-- Modal Footer -->
<div class="footer">
  <ate-button-control *ngIf="step > 2" (click)="goBack()" class="back-button" size="xsmall" color="back" fill="false"
    ><ate-icon name="chevron-left-v2" slot="start"></ate-icon>Back</ate-button-control
  >
  <div class="footer_button">
    <ng-container [ngTemplateOutlet]="footer$ | async"> </ng-container>
  </div>
</div>

<!-- Body Templates -->

<ng-template #bodyStep0>
  <section *ngIf="listingItems?.length > 0" class="outstanding-quotes" [ngClass]="{ center: listingItems?.length > 1 }">
    <p>
      {{ listingItems?.length }} Outstanding Quote{{ listingItems?.length > 1 ? 's' : '' }}
      from:
    </p>
    <app-listing-list [displayViewQuoteButton]="true" [listingItems]="listingItems" (listingItemClicked)="navigateToQuote($event)"></app-listing-list>
  </section>

  <section class="body-section-small" [attr.step]="0">
    <p class="cancel-text">The following service request will be cancelled:</p>
    <app-service-list-item
      [serviceTitle]="eventRequest?.primaryServiceTitle"
      [image]="eventRequest?.primaryServiceImageURI"
      [date]="eventRequest?.event?.startDate"
      [eventType]="eventRequest?.event?.eventType | eventType"></app-service-list-item>
  </section>
</ng-template>

<ng-template #bodyStep1>
  <section class="body-section-small">
    <app-service-list-item
      class="close"
      [serviceTitle]="eventRequest?.primaryServiceTitle"
      [image]="eventRequest?.primaryServiceImageURI"
      [date]="eventRequest?.event?.startDate"
      [eventType]="eventRequest?.event?.eventType | eventType"
      [closed]="true"></app-service-list-item>
  </section>
</ng-template>

<ng-template #bodyStep2>
  <section class="body-section-medium">
    <ate-radio-button-group-control
      [attr.model-key]="'selectReason'"
      [attr.options]="checkboxSelection"
      size="xsmall"></ate-radio-button-group-control>
    <div class="skip">
      <button (click)="listingItems.length > 0 ? updateStep(4) : onClose.emit()">
        <span>Skip</span>
        <ate-icon name="skip-arrow"></ate-icon>
      </button>
    </div>
  </section>
</ng-template>

<ng-template #bodyStep3>
  <section class="body-section-medium">
    <p class="form-label">Would you mind sharing who you went ahead with?</p>

    <ate-text-input-control
      class="somewhere-else-input"
      [attr.model-key]="'somewhereElse'"
      [attr.placeholder]="'Enter Name'"></ate-text-input-control>

    <p class="form-label form-label-margin">Reason for selecting:</p>

    <ate-checkbox-group-control
      [attr.model-key]="'somewhereElseReason'"
      [attr.options]="somewhereElseSelection"
      size="xsmall"></ate-checkbox-group-control>
    <div class="skip">
      <button (click)="listingItems?.length > 0 ? updateStep(4) : onClose.emit()"><span>Skip</span> <ate-icon name="skip-arrow"></ate-icon></button>
    </div>
  </section>
</ng-template>

<ng-template #bodyStep4>
  <section class="outstanding-quotes" [ngClass]="{ center: listingItems?.length > 1 }">
    <app-listing-list
      [listingItems]="getListingItemsWithActionButtons(listingItems)"
      (listingItemClicked)="emitQuoteAction($event)"></app-listing-list>
  </section>
</ng-template>

<!-- Footer Templates -->
<ng-template #footerStep0>
  <button class="red" (click)="updateStep(1, true)">Cancel Service Request</button>
</ng-template>

<ng-template #footerStep1>
  <button class="red-gradient" (click)="updateStep(2)">Continue</button>
</ng-template>

<ng-template #footerStep2>
  <button class="red-gradient" (click)="foundSomewhereElse()" [disabled]="!closeRequest?.selectReason">
    {{ closeRequest?.selectReason === 'I found someone elsewhere' ? 'Next' : 'Submit Reason' }}
  </button>
</ng-template>

<ng-template #footerStep3>
  <button class="red-gradient" [disabled]="!closeRequest?.somewhereElse || !closeRequest?.somewhereElseReason" (click)="submitFoundSomewhereElse()">
    Submit
  </button>
</ng-template>

<ng-template #footerStep4>
  <button class="basic" (click)="onClose.emit()">
    <span>I'll do this later</span>
  </button>
</ng-template>
