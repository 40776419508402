import { ActivatedRouteSnapshot, ParamMap } from '@angular/router';
import { Injectable } from '@angular/core';
import { first, Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { quotesActions } from '../../../store/actions/quotes.actions';
import { QuotesState } from '../../../store/reducers/quotes.reducer';
import { selectQuotes } from '../../../store/selectors/quotes.selectors';

@Injectable({ providedIn: 'root' })
export class QuoteResolver {
  constructor(private _store: Store<QuotesState>) {}

  /**
   * resolve Quote
   * call API to get Quote if quote matching route uuid does not exist in state
   *
   * @param route
   * @returns true
   */
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const quoteUuid = route.params.uuid || route.parent.params.uuid;

    return this._store.pipe(select(selectQuotes.getQuote), first()).pipe(
      switchMap(quote => {
        const viewSource = this.getViewSource(route.queryParamMap);
        if (!quote) {
          this.getQuote(quoteUuid, { viewSource });
        }

        if (quote && quoteUuid !== quote.uuid) {
          this.getQuote(quoteUuid, { viewSource });
        }

        return of(true);
      })
    );
  }

  /**
   * Gets the view source for this quote.
   * The priority is taking the viewSource param, if available,
   * otherwise we try to take the utm_x values
   * @param queryParamMap The query parameters for this route.
   * @returns
   */
  private getViewSource(queryParamMap: ParamMap) {
    const viewSourceParam = queryParamMap.get('viewSource');
    const utmCampaign = queryParamMap.get('utm_campaign');
    const utmMedium = queryParamMap.get('utm_medium');
    const utmSource = queryParamMap.get('utm_source');

    if (viewSourceParam) {
      return viewSourceParam;
    } else if (utmSource && utmCampaign && utmMedium) {
      return `${utmSource}__${utmCampaign}__${utmMedium}`;
    }
    return null;
  }

  /*
   * trigger fetching quote in effects
   * dispatch GetQuote action
   */
  private getQuote(id: string, queryParams: { [key: string]: string }) {
    this._store.dispatch(quotesActions.GetQuote({ payload: { id, queryParams } }));
  }
}
