import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './store/reducers';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthInterceptor, ErrorsInterceptor } from './shared/interceptors';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ListingModule } from './features/listings/listing.module';
import { QuotesModule } from './features/quotes/quotes.module';
import { EventModule } from './features/event-request/event.module';
import { LandingPagesModule } from './features/landing-pages/landing-pages.module';
import { SharedModule } from './shared/shared.module';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { browserLocalPersistence, browserSessionPersistence, initializeAuth, inMemoryPersistence, provideAuth } from '@angular/fire/auth';
import { UpdatesAvailableModalComponent } from './ui/components/updates-available/updates-available.component';
import { ErrorChunkDialogContainer } from './ui/containers/error-chunk-dialog/error-chunk-dialog.container';
import { GlobalErrorHandler } from './shared/services/errors/load-chunk-error.service';
import { ErrorHandlerService } from './shared/services/errors/error-handler.service';
import { FlashMessageService } from './shared/services/flash-message/flash-message.service';
import { ApiModule } from './api/api.module';
import { ApplicationEffects } from './store/effects/app.effects';
import { InvitesModule } from './features/invites/invites.module';
import { MaterialModule } from './shared/material/material.module';
import { ContainerModule } from './ui/containers/container.module';
import { FeatureFlagInterceptor } from './shared/interceptors/feature-flag.interceptor';
import { FeatureFlagConfigService } from './shared/services/feature-flag-config.service';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { SupplierModule } from './features/suppliers/supplier.module';
import { DialogComponent } from './shared/components/dialogs/dialog-base/dialog.component';
import { EventsModule } from './features/events/events.module';
import { EventEffects } from './store/effects/event.effects';
import { RecommendationsComponent } from './features/recommendations/components/recommendations/recommendations.component';

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FeatureFlagInterceptor, multi: true }
];

@NgModule({
  declarations: [AppComponent, UpdatesAvailableModalComponent, ErrorChunkDialogContainer, DialogComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers
    }),
    ApiModule.forRoot(environment),
    StoreDevtoolsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    ListingModule,
    QuotesModule,
    EventModule,
    EventsModule,
    LandingPagesModule,
    SharedModule,
    InvitesModule,
    EffectsModule.forFeature([ApplicationEffects, EventEffects]),
    BrowserAnimationsModule,
    MaterialModule,
    ContainerModule,
    SupplierModule,
    RecommendationsComponent
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    interceptorProviders,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ErrorHandlerService,
    FlashMessageService,
    FeatureFlagConfigService,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() =>
      initializeAuth(getApp(), {
        persistence: [browserLocalPersistence, browserSessionPersistence, inMemoryPersistence]
      })
    ),
    provideDatabase(() => getDatabase()),
    provideMessaging(() => getMessaging())
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [MaterialModule, SupplierModule, InvitesModule, DialogComponent]
})
export class AppModule {}
