export enum EntityType {
  QUOTE = 'quote'
}

export enum MessageType {
  ENTITY,
  MESSAGE,
  SYSTEM_EVENT
}

export enum SystemEventType {
  ORGANISER_VIEW_WEBSITE,
  ORGANISER_VIEW_ATTACHMENTS,
  ORGANISER_VIEW_PROFILE,
  ORGANISER_VIEW_PHONE,
  LISTING_USER_EDIT_QUOTE,
  ORGANISER_VIEW_TWITTER,
  ORGANISER_VIEW_FACEBOOK,
  ORGANISER_VIEW_INSTAGRAM,
  ORGANISER_SELECTED_LISTING,
  LISTING_USER_CONFIRMED_JOB,
  LISTING_USER_SENT_REVIEW_REQUEST,
  LISTING_USER_SENT_REVIEW_REMINDER,
  LISTING_USER_SENT_FINAL_REMINDER,
  WARNING_BANK_DETAILS,
  ORGANISER_DECLINED
}

export interface IMessage {
  id: string;
  entityType: EntityType;
  entityUuid?: string;
  type: MessageType;
  systemMessage?: SystemEventType;
  text?: string;
  timestamp: number | string;
  userId?: string;
  seen?: {
    userId: string;
    timestamp: number;
  };
  timestampSeen?: string;
}
