import { Component, Input } from '@angular/core';
import { DialogComponent } from '../dialog-base/dialog.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Dialog } from '../dialog.interface';

@Component({
  selector: 'app-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss']
})
export class TagsDialogComponent implements Dialog {
  data: { [key: string]: unknown };
  dialogRef: MatDialogRef<DialogComponent>;

  @Input() tags: string[];
}
