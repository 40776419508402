import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { apiActions } from '../../../store/actions/api.actions';
import { ApiState } from '../../../store/reducers/api.reducer';

export interface AxiosError {
  isAxiosError: boolean;
  response: {
    status: number;
    data: {
      statusCode: number;
      message: string;
    };
  };
}

@Injectable()
export class ApiErrorHandlerService {
  constructor(
    private _store: Store<ApiState>,
    private _authService: AuthService
  ) {}

  /**
   * Handles standard API errors setting them in the state
   * @param error
   */
  public handleError(error: AxiosError, entity: string, options = {}) {
    if (error.isAxiosError) {
      /** HANDLE 503 - Service Unavailable */
      /** ERROR HAS NO BODY - NO HEALTHY UPSTREAM */

      if (!error || !error.response) {
        this._store.dispatch(
          apiActions.SetError({
            payload: {
              data: null,
              status: 503,
              entity,
              options
            }
          })
        );

        return throwError(() => error);
      }

      const { data, status } = error.response;

      if (error.response.status === 401) {
        return this._authService.checkAuthorised$().pipe(
          mergeMap(user => {
            if (!user) {
              this._store.dispatch(
                apiActions.SetNotAuthorisedError({
                  payload: {
                    data: data,
                    status: status
                  }
                })
              );
              return EMPTY;
            }
            /** TRY TO GET REFRESHED TOKEN */
            return this._authService.refreshToken(user).pipe(
              catchError(() => {
                return throwError(() => error);
              }),
              /** ON SUCCESS API SERVICE STILL NEEDS TO PROCESS ERROR PIPE TO RETRY CALL */
              mergeMap(() => throwError(() => error))
            );
          }),
          first()
        );
      } else {
        this._store.dispatch(
          apiActions.SetError({
            payload: {
              data: data,
              status: status,
              entity,
              options
            }
          })
        );

        return throwError(() => error.response);
      }
    }
  }

  /**
   * Removes errors from state
   */
  public removeErrors(): void {
    this._store.dispatch(apiActions.RemoveError());
    this._store.dispatch(apiActions.RemoveNotAuthorisedError());
  }
}
