<form class="tw-flex">
  <div
    class="tw-relative tw-flex tw-w-full"
    [ngClass]="{
      'white-half-gradient': panelOpen | async
    }">
    <ate-icon
      [ngClass]="{ '!tw-text-brand-purple': panelOpen | async }"
      class="tw-absolute tw-left-4 tw-top-3 tw-z-10 tw-size-6 tw-text-slate-500"
      name="search-v2"></ate-icon>
    <div
      [ngClass]="{ 'tw-bg-gradient-to-r tw-from-brand-gradientfrom tw-to-brand-gradientto': panelOpen | async }"
      class="tw-flex tw-w-full tw-rounded-[100px] tw-bg-slate-300 before:tw-w-full">
      <input
        class="tw-relative tw-box-border tw-min-h-12 tw-w-full tw-rounded-[100px] tw-border-2 tw-border-solid tw-border-transparent tw-bg-clip-padding tw-px-[52px] tw-py-3 tw-text-sm tw-outline-none active:tw-bg-white"
        placeholder="Search for any service..."
        type="text"
        placeholder="Search for any service..."
        [formControl]="control"
        [matAutocomplete]="auto" />
    </div>

    <ate-icon
      *ngIf="control.value?.length > 0"
      (click)="clearInput()"
      class="tw-absolute tw-right-4 tw-top-3 tw-size-6 tw-text-slate-500"
      name="circle-cross-filled-v3"></ate-icon>
  </div>
  <mat-autocomplete
    disableRipple
    #auto="matAutocomplete"
    [classList]="'custom-autocomplete-panel'"
    (opened)="emitPanelOpened()"
    (closed)="emitPanelClosed()">
    <ng-container *ngIf="(filteredOptionGroup$ | async)?.length > 0; else noResults">
      <mat-optgroup *ngFor="let option of filteredOptionGroup$ | async" (click)="emitSelectedService(option)">
        <div [innerHtml]="highlight(option.name, control.value)"></div>
        <!-- If we only match an empty group, use a placeholder so that the mat-optgroup displays correctly -->
        <ng-container *ngIf="option.services.length > 0; else noServicesPlaceholder">
          <mat-option *ngFor="let service of option.services" class="!tw-text-slate-800" (click)="emitSelectedService(service)" [value]="service.name"
            ><div [innerHtml]="highlight(service.name, control.value)"></div>
            <ate-icon class="tw-mt-0.5 tw-size-4 tw-text-slate-800" name="arrow-right-v3"></ate-icon
          ></mat-option>
        </ng-container>

        <ate-icon class="tw-mt-0.5 tw-size-4 tw-text-slate-800" name="arrow-right-v3"></ate-icon>
      </mat-optgroup>
    </ng-container>

    <!-- No results placeholder -->
    <ng-template #noResults>
      <mat-option id="no-matches-select" class="tw-mb-4 tw-mt-3">
        <img class="tw-mt-0.5 tw-size-[80px]" src="/assets/icons/ate-search-failed.svg" alt="Failed Search" />
        <p class="tw-my-[6px] tw-text-slate-800">No matches found for "{{ control.value }}"</p>
      </mat-option>
    </ng-template>

    <ng-template #noServicesPlaceholder>
      <mat-option class="!tw-hidden"><div></div> </mat-option>
    </ng-template>
  </mat-autocomplete>
</form>
