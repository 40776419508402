import { createSelector, createFeatureSelector } from '@ngrx/store';
import { APP_FEATURE_KEY, AppState } from '../reducers/app.reducer';

const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

const getIsLoading = createSelector(getAppState, (state: AppState) => state.loadingCount > 0);

const getShowingFlashMessage = createSelector(getAppState, (state: AppState) => {
  return state.showingFlashMessage;
});

const getNotificationsMenuOpen = createSelector(getAppState, (state: AppState) => {
  return state.notificationsMenuOpen;
});

const getSidenavOpen = createSelector(getAppState, (state: AppState) => {
  return state.sidenavOpen;
});

const getHeaderOpen = createSelector(getAppState, (state: AppState) => {
  return state.headerOpen;
});

const getFlashMessage = createSelector(getAppState, (state: AppState) => {
  return state.flashMessage;
});

const getFeatureFlags = createSelector(getAppState, (state: AppState) => {
  return state.featureFlags;
});

const getEvents = createSelector(getAppState, (state: AppState) => {
  return state.events;
});

export const selectApp = {
  getIsLoading,
  getShowingFlashMessage,
  getFlashMessage,
  getFeatureFlags,
  getNotificationsMenuOpen,
  getSidenavOpen,
  getHeaderOpen,
  getEvents
};
