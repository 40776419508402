<div
  *ngIf="isInitialLoading"
  class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 !tw-z-[9999] tw-flex tw-items-center tw-justify-center tw-backdrop-blur-sm lg:tw-bottom-20 lg:tw-top-16">
  <ate-icon
    class="tw-size-10 tw-animate-spin tw-rounded-full tw-bg-white tw-p-1 tw-text-white"
    name="loading-spinner-gradient-v3"
    slot="start"></ate-icon>
</div>
<div class="inner-container" *ngIf="!isInitialLoading && !modalView">
  <header>
    <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
    <h1>All Reviews</h1>
  </header>

  <main>
    <section class="review-stats">
      <header>
        <ate-star color="orange"></ate-star>
        <span>{{ listingReview?.reviewRating }}</span>
        <span>({{ listingReview?.reviewCount }}) reviews</span>
      </header>
      <div class="core-stat">
        <p>Quality</p>
        <span>{{ (listingReview?.breakdown?.quality | number: '1.1') ?? '-' }}</span>
      </div>
      <div class="core-stat">
        <p>Communication</p>
        <span>{{ (listingReview?.breakdown?.communication | number: '1.1') ?? '-' }}</span>
      </div>
      <div class="core-stat">
        <p>Value</p>
        <span>{{ (listingReview?.breakdown?.value | number: '1.1') ?? '-' }}</span>
      </div>
    </section>

    <app-review-list-item
      *ngFor="let item of listingReview?.items"
      [readAll]="true"
      [review]="item"
      [attr.id]="'review-' + item.uuid"></app-review-list-item>

    <div class="loading-controller">
      <div
        *ngIf="isLoading"
        class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 !tw-z-[9999] tw-flex tw-items-center tw-justify-center tw-backdrop-blur-sm lg:tw-bottom-20 lg:tw-top-16">
        <ate-icon
          class="tw-size-10 tw-animate-spin tw-rounded-full tw-bg-white tw-p-1 tw-text-white"
          name="loading-spinner-gradient-v3"
          slot="start"></ate-icon>
      </div>
      <button *ngIf="!isLoading" (click)="getListingReviews()">
        <span>View More Reviews</span>
      </button>
    </div>
  </main>
</div>

<div *ngIf="modalView">
  <main>
    <section class="review-stats">
      <header>
        <ate-star color="orange"></ate-star>
        <span>{{ listingReview?.reviewRating }}</span>
        <span>({{ listingReview?.reviewCount }}) reviews</span>
      </header>
      <div class="core-stat-container">
        <div class="core-stat">
          <p>Quality</p>
          <span>{{ (listingReview?.breakdown?.quality | number: '1.1') ?? '-' }}</span>
        </div>
        <div class="core-stat">
          <p>Communication</p>
          <span>{{ (listingReview?.breakdown?.communication | number: '1.1') ?? '-' }}</span>
        </div>
        <div class="core-stat">
          <p>Value</p>
          <span>{{ (listingReview?.breakdown?.value | number: '1.1') ?? '-' }}</span>
        </div>
      </div>
    </section>

    <app-review-list-item
      *ngFor="let item of listingReview?.items"
      [readAll]="true"
      [review]="item"
      [attr.id]="'review-' + item.uuid"></app-review-list-item>
  </main>
</div>
