import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

@Component({
  selector: 'app-image-pill',
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './image-pill.component.html',
  styleUrl: './image-pill.component.scss'
})
export class ImagePillComponent {
  @Input() imageUrl = '';
  @Input() title = '';
}
