import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, catchError, of } from 'rxjs';
import { RemoveAuth } from '../../../store/actions/authentication.actions';
import { AuthService } from '../../services/auth/auth.service';
import { AuthenticationGuard } from './authentication.guard';
import { State } from '../../../store/reducers';
import { WindowService } from '../../services/window/window.service';

@Injectable()
export class AuthenticationWrapperGuard {
  constructor(
    @Inject('environment') private environment,
    private _authenticationGuard: AuthenticationGuard,
    private _authService: AuthService,
    private _store: Store<State>,
    private _windowService: WindowService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = route.queryParamMap.get('tk');

    if (token) {
      this._store.dispatch(RemoveAuth());

      return this._authService.signInWithCustomToken$(token).pipe(
        map(() => {
          return true;
        }),
        catchError(() => {
          this._windowService.href = this.environment.loginUrl;

          return of(false);
        })
      );
    }

    return this._authenticationGuard.canActivate();
  }
}
