import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileBaseComponent } from '../tile-base/tile-base.component';
import { EnhancedStatusPillComponent } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill.component';
import { Subscription } from 'rxjs';
import { EnhancedStatusPillConfig } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill-config.interface';
import { InterestedStatusConfig } from './interested-tile-config';
import { NotificationModel, NotificationsService, NotificationTypes } from '../../../../../shared/services/notifications/notifications.service';
import { SupplierInvitesService } from '../../../../../shared/services/supplier-invites/supplier-invites.service';
import { ListingAction } from '../../../../suppliers/components/supplier/supplier.component';
import { QuoteModel } from '../../../../../shared/models/quotes';
import { OrganiserInviteStatus } from '../../../../../shared/enums/organiser-invite-status.enum';

@Component({
  selector: 'app-interested-tile',
  templateUrl: './interested-tile.component.html',
  styleUrls: ['./interested-tile.component.scss'],
  standalone: true,
  imports: [CommonModule, TileBaseComponent, EnhancedStatusPillComponent],
  providers: [NotificationsService, SupplierInvitesService]
})
export class InterestedTileComponent implements OnChanges, OnDestroy {
  @Input() interest: QuoteModel;
  @Input() suggestedSupplierAction: ListingAction;
  @Input() eventRequestUuid = '';
  @Output() supplierInvited = new EventEmitter<QuoteModel>();
  @Output() supplierSkipped = new EventEmitter<QuoteModel>();

  loadingInvited: boolean = false;
  loadingSkipped: boolean = false;
  notification: NotificationModel;
  showButtons: boolean = false;
  statusPillConfig: EnhancedStatusPillConfig;
  subDeclineInterest$: Subscription;
  subInvite$: Subscription;
  subscriptionList: Subscription = new Subscription();

  constructor(
    private _notificationsService: NotificationsService,
    private _supplierInvitesService: SupplierInvitesService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.suggestedSupplierAction) {
        if (
          this.suggestedSupplierAction &&
          this.suggestedSupplierAction.listingUuid &&
          this.suggestedSupplierAction.listingUuid === this.interest.listingUuid
        ) {
          if (this.suggestedSupplierAction.action === 'invite') {
            this.inviteSupplier(this.interest.listingUuid);
          } else {
            this.declineInterest(this.eventRequestUuid, this.interest.listingUuid);
          }
        }
      }
      if (changes.interest) {
        this.showButtons = this.getButtonsShouldShow(this.interest);
        this.statusPillConfig = this.getStatusPillConfig(this.interest);
      }
    }
  }

  getButtonsShouldShow(interest: QuoteModel) {
    return interest?.organiserStatus === OrganiserInviteStatus.LIVE && !interest?.isDeclined;
  }

  getStatusPillConfig(interest: QuoteModel): EnhancedStatusPillConfig {
    return interest?.organiserStatus ? InterestedStatusConfig[interest.organiserStatus] : null;
  }

  inviteSupplier(listingUuid: string) {
    this.loadingInvited = true;

    this.subInvite$ = this._supplierInvitesService
      .inviteSupplier(this.eventRequestUuid, {
        listingUuid: listingUuid
      })
      .subscribe({
        next: response => {
          if (response.success) {
            this.subscriptionList.add(this.subInvite$);
            this.loadingInvited = false;
            this.interest.invited = true;
            this.notification = {
              type: NotificationTypes.SUCCESS,
              message: `You have invited ${this.interest.listingTitle} to send a quote`,
              action: 'Close'
            };
            this._notificationsService.openSnackBar(this.notification);
            this.supplierInvited.emit(this.interest);
          }
        },
        error: err => {
          this.loadingInvited = false;
          this.notification = {
            type: NotificationTypes.ERROR,
            message: `Sorry, we couldn’t send an invite to ${this.interest.listingTitle}. Please try again`,
            action: 'Close'
          };
          this._notificationsService.openSnackBar(this.notification);
          console.error(err);
        }
      });
  }

  declineInterest(eventRequestUuid: string, listingUuid: string) {
    this.loadingSkipped = true;

    this.subDeclineInterest$ = this._supplierInvitesService.declineInterest(eventRequestUuid, listingUuid).subscribe({
      next: response => {
        if (response.success) {
          this.subscriptionList.add(this.subDeclineInterest$);
          this.loadingSkipped = false;
          this.supplierSkipped.emit(this.interest);
        }
      },
      error: err => {
        this.loadingSkipped = false;
        this.notification = {
          type: NotificationTypes.ERROR,
          message: `Sorry, we couldn’t skip this invite. Please try again`,
          action: 'Close'
        };
        this._notificationsService.openSnackBar(this.notification);
        console.error(err);
      }
    });
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
  }
}
