<section class="tw-mx-5 tw-my-8 tw-flex tw-flex-col tw-items-center tw-justify-center">
  <div
    class="tw-flex tw-size-16 tw-shrink-0 tw-items-center tw-justify-center tw-rounded-full tw-border-2 tw-border-solid tw-border-slate-200 tw-bg-slate-50">
    <ate-icon class="tw-size-8 tw-text-slate-800" name="notification-bell-active-v3"></ate-icon>
  </div>
  <h2 class="tw-mt-5 tw-text-center tw-text-xl tw-text-slate-800">Would you like to be notified when you receive more new quotes?</h2>
  <p class="tw-mb-0 tw-mt-3 tw-text-center tw-text-sm tw-text-slate-600">
    Choosing ‘Yes, turn on notifications’ will open a browser permissions message where you can opt-in to receive browser notifications.
  </p>
</section>

<footer
  class="tw-box-border tw-flex tw-w-full tw-flex-wrap tw-justify-center tw-gap-4 tw-border-0 tw-border-t tw-border-solid tw-border-slate-300 tw-p-4">
  <button
    id="opt-in-notifications"
    (click)="requestNotificationPermission()"
    class="gradient-button !tw-mt-0 tw-w-full tw-grow !tw-px-8 md:tw-w-fit md:tw-grow-0">
    Yes, turn on notifications
  </button>
  <button
    id="close-opt-in-notifications"
    (click)="dialogRef.close()"
    class="tw-relative tw-box-border tw-flex tw-h-12 tw-w-full tw-grow tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-lg tw-border-2 tw-border-solid tw-border-slate-300 tw-bg-white tw-px-6 tw-text-base tw-font-bold tw-text-slate-600 tw-duration-200 hover:tw-bg-slate-50 md:tw-mx-0 md:tw-w-fit md:tw-grow-0">
    Maybe later
  </button>
</footer>
