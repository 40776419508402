/* eslint-disable @typescript-eslint/no-explicit-any */
import { catchError, first, switchMap } from 'rxjs/operators';
import { Observable, throwError, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { WindowService } from '../services/window/window.service';
import { environment } from 'src/environments/environment';
import { User } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _windowService: WindowService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this._authService.getIdToken$().pipe(
      first(),
      switchMap(user => {
        if (user) {
          return from(user.getIdToken()).pipe(
            switchMap((token: string) => {
              if (token) {
                req = this.addTokenHeader(req, token);
              }
              return next.handle(req).pipe(
                catchError(error => {
                  if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401(req, next, user);
                  } else {
                    return throwError(() => error);
                  }
                })
              );
            })
          );
        }
        return this._authService.unauthenticate().pipe(
          switchMap(() => {
            this._windowService.href = `${environment.loginUrl}&reason=notoken`;
            return next.handle(req);
          })
        );
      })
    );
  }

  private handle401(request: HttpRequest<any>, next: HttpHandler, user: User) {
    return from(user.getIdToken(true)).pipe(
      switchMap((token: string) => {
        if (token) {
          request = this.addTokenHeader(request, token);
        }
        return next.handle(request);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    request = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
    return request;
  }
}
