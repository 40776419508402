import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitesRoutingModule } from './invites-routing.module';
import { SupplierInvitesService } from '../../shared/services/supplier-invites/supplier-invites.service';
import { InvitesComponent } from './components/request-invites/invites.component';
import { SuggestedTileComponent } from '../quotes/components/tiles/suggested-tile/suggested-tile.component';

@NgModule({
  declarations: [InvitesComponent],
  imports: [CommonModule, InvitesRoutingModule, SuggestedTileComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SupplierInvitesService]
})
export class InvitesModule {}
