<ng-container>
  <div
    *ngIf="isLoading | async"
    class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 !tw-z-[9999] tw-flex tw-items-center tw-justify-center tw-backdrop-blur-sm">
    <ate-icon
      class="tw-size-10 tw-animate-spin tw-rounded-full tw-bg-white tw-p-1 tw-text-white"
      name="loading-spinner-gradient-v3"
      slot="start"></ate-icon>
  </div>
</ng-container>

<updates-available-modal></updates-available-modal>

<router-outlet></router-outlet>
