import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTypePipeline } from './pipes/eventType.pipeline';
import { FilenamePipeline } from './pipes/filename.pipeline';
import { LinebreakPipeline } from './pipes/linebreak.pipeline';
import { LongFormDatePipeline } from './pipes/longFormDate.pipeline';
import { ReplaceStringPipe } from './pipes/replaceString.pipeline';
import { ShortFormDatePipeline } from './pipes/shortFormDate.pipeline';
import { ShortNamePipeline } from './pipes/shortName.pipeline';
import { AuthenticationWrapperGuard } from './guards/auth/authentication-wrapper.guard';
import { AuthenticationGuard } from './guards/auth/authentication.guard';
import { MaintenanceGuard } from './guards/maintenance/maintenance.guard';
import { AuthService } from './services/auth/auth.service';
import { MaintenanceService } from './services/maintence/maintenance.service';
import { JWTService } from './services/jwt/jwt.service';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { AppversionService } from './services/version/appversion_service';
import { DatalayerService } from './services/datalayer/datalayer.service';
import { PageContextService } from './services/page-context/page-context.service';
import { SwipeService } from './services/swipe/swipe.service';
import { FeatureToggleDirective } from './directives/feature-toggle/feature-toggle.directive';
import { EventRequestSheetComponent } from './sheets/event-request/event-request-sheet.component';
import { TagsDialogComponent } from './components/dialogs/tags-dialog/tags-dialog.component';
import { UtilityMessageComponent } from './components/utility-message/utility-message.component';
import { NotificationsService } from './services/notifications/notifications.service';
import { TimeAgoPipeline } from './pipes/timeAgo.pipeline';
import { StringToTitleCasePipe } from './pipes/transform-string-to-uppercase/transform-string-to-uppercase.pipe';
import { NotificationsDialogComponent } from './components/dialogs/notifications-dialog/notifications-dialog.component';
import { FcmNotificationsService } from './services/fcm-notifications/fcm-notifications.service';
import { OrdinalDatePipe } from './pipes/ordinal-date/ordinal-date.pipe';
import { StatusIndicatorComponent } from './components/status-indicator/status-indicator.component';
import { CarouselLightboxModule } from '../ui/carousel-lightboxes/carousel-lightbox.module';
import { ImageCarouselComponent } from '../ui/carousel-lightboxes/image-carousel/image-carousel.component';
import { ImageGridComponent } from '../ui/image-grid/image-grid.component';
import { InviteCarouselSlideComponent } from '../ui/invite-carousel-slide/invite-carousel-slide.component';
import { EventRequestTileComponent } from '../features/event-request/components/event-request-tile/event-request-tile.component';
import { EmptyMessageComponent } from '../features/event-request/components/empty-message/empty-message.component';
import { ChangeParentEventModalComponent } from './standalone/modals/change-parent-event-modal/change-parent-event-modal.component';

@NgModule({
  declarations: [
    EventTypePipeline,
    FilenamePipeline,
    LinebreakPipeline,
    LongFormDatePipeline,
    ReplaceStringPipe,
    ShortFormDatePipeline,
    ShortNamePipeline,
    TimeAgoPipeline,
    OutsideClickDirective,
    FeatureToggleDirective,
    EventRequestSheetComponent,
    TagsDialogComponent,
    UtilityMessageComponent,
    StringToTitleCasePipe,
    NotificationsDialogComponent,
    OrdinalDatePipe,
    StatusIndicatorComponent,
    ImageGridComponent,
    ImageCarouselComponent,
    EventRequestTileComponent,
    EmptyMessageComponent
  ],
  providers: [
    MaintenanceGuard,
    MaintenanceService,
    AuthenticationGuard,
    AuthenticationWrapperGuard,
    AuthService,
    JWTService,
    AppversionService,
    DatalayerService,
    AppversionService,
    PageContextService,
    SwipeService,
    NotificationsService,
    FcmNotificationsService
  ],
  imports: [CommonModule, CarouselLightboxModule, InviteCarouselSlideComponent, ChangeParentEventModalComponent],
  exports: [
    ShortNamePipeline,
    ShortFormDatePipeline,
    ReplaceStringPipe,
    LongFormDatePipeline,
    LinebreakPipeline,
    FilenamePipeline,
    EventTypePipeline,
    TimeAgoPipeline,
    OutsideClickDirective,
    FeatureToggleDirective,
    UtilityMessageComponent,
    StringToTitleCasePipe,
    OrdinalDatePipe,
    StatusIndicatorComponent,
    CarouselLightboxModule,
    InviteCarouselSlideComponent,
    ImageGridComponent,
    ImageCarouselComponent,
    EventRequestTileComponent,
    EmptyMessageComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
