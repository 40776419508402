<div class="filters tw-flex tw-flex-auto tw-flex-wrap tw-gap-x-4 tw-gap-y-3" *ngIf="filters">
  <ng-container *ngFor="let filter of filters">
    <button
      class="filter tw-flex tw-items-center tw-justify-between tw-gap-2"
      *ngIf="filter.show"
      [attr.data-cy]="filter?.id"
      [routerLink]="[filter.url]"
      [class.enhanced]="filter.enhanced"
      [class.gradient]="filter.gradient"
      [class.hasCounter]="filter.showCounter"
      [matTooltip]="filter.tooltip"
      matTooltipClass="ate-tooltip"
      routerLinkActive="active"
      (click)="emitFilter(filter)">
      <ate-icon [name]="filter?.icon" *ngIf="filter.icon"></ate-icon>
      <p class="filter-text tw-m-0">{{ filter?.name }}</p>
      <span class="counter tw-flex tw-size-6 tw-items-center tw-justify-center" *ngIf="filter.showCounter">
        <p class="tw-m-0" *ngIf="filter?.counter">{{ filter?.counter }}</p>
        <p class="tw-m-0" *ngIf="!filter.counter">0</p>
      </span>
    </button>
  </ng-container>
</div>
