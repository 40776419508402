import { createReducer, on, Action } from '@ngrx/store';
import { appActions } from '../actions/app.actions';
import { FlashMessage } from '../models/flash-message.model';
import { FirebaseFeatureFlags } from '../../shared/enums/firebase-custom-claims.enum';
import { IMainEventData } from '../../shared/models/events';

export const APP_FEATURE_KEY = 'app';

export interface AppState {
  loadingCount: number;
  showingFlashMessage: boolean;
  flashMessage: FlashMessage;
  featureFlags: FirebaseFeatureFlags[];
  notificationsMenuOpen: boolean;
  sidenavOpen: boolean;
  headerOpen: boolean;
  events: IMainEventData[];
}

export const initialState: AppState = {
  loadingCount: 0,
  showingFlashMessage: false,
  flashMessage: {
    flashMessageType: undefined,
    message: undefined,
    timeout: undefined
  },
  featureFlags: [],
  notificationsMenuOpen: false,
  sidenavOpen: false,
  headerOpen: false,
  events: null
};

const reducer = createReducer(
  initialState,
  on(appActions.IncrementLoadingCount, state => ({
    ...state,
    loadingCount: state.loadingCount + 1
  })),
  on(appActions.DecrementLoadingCount, state => ({
    ...state,
    loadingCount: state.loadingCount === 0 ? 0 : state.loadingCount - 1
  })),
  on(appActions.ToggleShowingFlashMessage, (state, { payload }) => ({
    ...state,
    showingFlashMessage: payload
  })),
  on(appActions.SetFlashMessage, (state, payload) => ({
    ...state,
    flashMessage: payload.flashMessage
  })),
  on(appActions.SetFeatureFlags, (state, payload) => ({
    ...state,
    featureFlags: payload.featureFlags
  })),
  on(appActions.RemoveFlashMessage, state => ({
    ...state
  })),
  on(appActions.ToggleShowingNotificationsMenu, (state, { payload }) => ({
    ...state,
    notificationsMenuOpen: payload
  })),
  on(appActions.ToggleShowingSidenav, (state, { payload }) => ({
    ...state,
    sidenavOpen: payload
  })),
  on(appActions.ToggleShowingHeader, (state, { payload }) => ({
    ...state,
    headerOpen: payload
  })),
  on(appActions.SetEvents, (state, payload) => ({
    ...state,
    events: payload.events
  }))
);

export function appReducer(state: AppState, action: Action) {
  return reducer(state, action);
}
