<!-- ******************************* -->
<!-- ******* REQUESTS HEADER ******* -->
<!-- ******************************* -->
<div *ngIf="event" class="animated fadeIn main-event tile-hover tw-relative tw-flex tw-flex-auto tw-overflow-hidden">
  <div class="tw-flex tw-flex-auto tw-gap-4">
    <div class="tw-flex" *ngIf="innerWidth > 768">
      <ate-icon (click)="goBack()" name="chevron-left-v2" class="btn-icon"></ate-icon>
    </div>

    <div class="event-icon-container eventTypeIcon tw-z-[2] tw-flex tw-flex-col tw-items-center tw-justify-center" [ngClass]="event?.iconClass">
      <ate-icon *ngIf="event.iconName" class="tw-h-24 tw-w-24" [attr.name]="event?.iconName"></ate-icon>
    </div>
    <div class="event-item-details tw-z-[2] tw-flex tw-flex-auto tw-flex-col tw-justify-center">
      <h3>{{ event?.mainEventType | stringToTitleCase }}</h3>
      <p class="tw-mb-0">
        <span>{{ event?.mainEventDate | ordinalDate: 'long' }}</span>
        <span>&nbsp;•&nbsp;</span>
        <span>{{ event?.mainEventLocation | trimText: maxLengthLocation }}</span>
      </p>
    </div>
  </div>
  <div class="tw-absolute tw-right-[16px] tw-top-[50%] tw-z-[1] tw-translate-y-[-50%] tw-transform tw-overflow-hidden sm:tw-right-[72px]">
    <ate-icon *ngIf="event.iconName" class="tw-size-[100px] sm:tw-size-[190px]" [ngClass]="iconColour" [attr.name]="event.iconName"></ate-icon>
  </div>
  <!--  <div *ngIf="isMobile" class="tw-absolute tw-bottom-6 tw-right-3 tw-z-[1] tw-h-24 tw-w-24 tw-overflow-hidden">-->
  <!--    <ate-icon *ngIf="event.iconName" class="tw-h-24 tw-w-24" [ngClass]="iconColour" [attr.name]="event.iconName"></ate-icon>-->
  <!--  </div>-->
</div>
<div class="event-requests">
  <ng-container *ngIf="!newRequestSectionOpen; else newRequestSection">
    <app-event-request-inbox *ngIf="event" [event]="event" class="tw-z-[2]"></app-event-request-inbox>
  </ng-container>
</div>

<ng-template #newRequestSection>
  <div class="lg:tw-p-[32px]">
    <app-new-request
      [showSidebar]="true"
      [eventType]="event?.mainEventType"
      [mainEventUuid]="event?.mainEventUuid"
      [event]="event"
      class="tw-relative tw-overflow-hidden !tw-py-4 lg:tw-rounded-3xl lg:!tw-pt-16"></app-new-request>
  </div>
</ng-template>
