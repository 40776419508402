import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '../../../shared/services/window/window.service';
import { QuoteResponse } from '../../../features/quotes/services/quote.type';
import { SupplierListingModalComponent } from '../../../shared/standalone/modals/supplier-listing-modal/supplier-listing-modal.component';

@Component({
  selector: 'app-subheader',
  templateUrl: './subheader.component.html',
  styleUrls: ['./subheader.component.scss']
})
export class SubheaderComponent {
  @Input() public quote: QuoteResponse;
  @Input() public showBackButton = true;
  @Input() public showReviews = true;
  @Input() public quoteUuid = '';
  @Input() public listingUuid: string;
  @Output() listingClick = new EventEmitter<void>();
  @Output() goBack = new EventEmitter<void>();
  @ViewChild(SupplierListingModalComponent)
  supplierListingModalComponent: SupplierListingModalComponent;

  constructor(
    private router: Router,
    private windowService: WindowService
  ) {}

  public goToListing(): void {
    if (this.windowService.isMobile()) {
      this.router.navigate(['/listing/', this.quote?.listing?.uuid]);
    } else {
      this.listingClick.next();
    }
  }
}
