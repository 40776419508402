/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { NEVER, Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { AppState } from '../../store/reducers/app.reducer';
import { FeatureFlagConfigService } from '../services/feature-flag-config.service';
import { FirebaseFeatureFlags } from '../enums/firebase-custom-claims.enum';
import { AuthService } from '../services/auth/auth.service';
import { appActions } from '../../store/actions/app.actions';

export interface IFeatureFlagConfig {
  url: string;
  urlChanged: boolean;
  params?: HttpParams;
  contentType?: string;
  cancelled?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private featureFlagConfigService: FeatureFlagConfigService,
    private _store: Store<AppState>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getFeatureFlaggedUrl(req).pipe(
      switchMap(featureFlaggedUrl => {
        const { urlChanged, url, contentType, params } = featureFlaggedUrl;

        if (!urlChanged) {
          return next.handle(req);
        }

        if (featureFlaggedUrl.cancelled) {
          return NEVER;
        }

        const clonedReq = req.clone({
          url,
          headers: this.getHeaders(req, contentType)
        });

        if (params) {
          const modifiedReqParams = clonedReq.clone({
            params
          });
          return next.handle(modifiedReqParams);
        }
        return next.handle(clonedReq);
      })
    );
  }

  private getHeaders(req: HttpRequest<any>, contentType?: string): HttpHeaders {
    if (!contentType) {
      return req.headers;
    }

    return new HttpHeaders({
      'content-type': contentType,
      authorization: req.headers.get('authorization')
    });
  }

  private setFeatureFlags(req: HttpRequest<any>) {
    const token = req.headers.get('authorization');

    let featureFlags: FirebaseFeatureFlags[] = [];

    if (token) {
      const decodedToken = this.authService.decodeToken(token);
      featureFlags = (decodedToken?.flags as FirebaseFeatureFlags[]) ?? [];
    }

    this._store.dispatch(appActions.SetFeatureFlags({ featureFlags: featureFlags }));

    return featureFlags;
  }

  private getFeatureFlaggedUrl(req: HttpRequest<any>): Observable<IFeatureFlagConfig> {
    this.setFeatureFlags(req);
    return this.featureFlagConfigService.getUrl(req);
  }
}
