<div
  class="invites-body-header tw-relative tw-m-auto tw-box-border tw-flex tw-flex-auto tw-items-center tw-justify-start tw-gap-5 tw-rounded-2xl tw-px-4 tw-pb-[88px] tw-pt-5 md:tw-gap-10 md:tw-p-8 md:tw-py-5">
  <div class="bg-left animated fadeIn tw-z-[1]"></div>
  <div class="bg-right tw-z-[1]"></div>
  <img class="tw-z-[2] tw-h-fit tw-max-w-24 tw-flex-shrink-0 md:tw-max-w-60" [src]="image" />
  <div class="invites-body-header-details tw-z-[2] tw-flex tw-flex-col tw-content-center tw-justify-start tw-text-start">
    <h3 class="tw-mb-1 tw-text-sm tw-font-normal tw-text-white md:tw-text-base">{{ title }}</h3>
    <h2 class="tw-mb-0 tw-text-lg tw-font-bold tw-text-white md:tw-mb-5 md:tw-text-2xl">{{ message }}</h2>
    <div class="tw-absolute tw-bottom-4 tw-left-0 tw-flex tw-w-full md:tw-static md:tw-justify-stretch">
      <ng-content></ng-content>
    </div>
  </div>
</div>
