export class RoutingFilter {
  url?: string;
  name: string;
  icon?: string;
  id?: string;
  counter?: number;
  active?: boolean;
  showCounter?: boolean;
  enhanced?: boolean;
  gradient?: boolean;
  show: boolean;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
}

export enum TooltipPosition {
  ABOVE = 'above',
  BELOW = 'below',
  LEFT = 'left',
  RIGHT = 'right',
  BEFORE = 'before',
  AFTER = 'after'
}
