import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AppState } from './store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import { asyncScheduler, combineLatest, filter, first, Observable, observeOn, of, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { environment } from '../environments/environment';
import { authenticationQuery } from './store/selectors/authentication.selectors';
import * as Sentry from '@sentry/angular-ivy';
import { DatalayerService } from './shared/services/datalayer/datalayer.service';
import { FlashMessageService } from './shared/services/flash-message/flash-message.service';
import { FlashMessage } from './store/models/flash-message.model';
import { userQuery } from './store/selectors/user.selectors';
import { UserState } from './store/reducers/user.reducer';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { EventRequestOverviewService } from './shared/services/event-request-overview/event-request-overview.service';
import { NotificationsService } from './shared/services/notifications/notifications.service';
import { SetInterestedState, SetUserNotifications } from './store/actions/user.actions';
import { selectApp } from './store/selectors/app.selectors';
import { appActions } from './store/actions/app.actions';
import { IUserNotification } from './shared/services/notifications/notification.interface';
import { EventRequestTypeEnum } from './shared/services/event-request/event-request-type.enum';
import { IMainEventData } from './shared/models/events';
import { EventsService } from './shared/services/events/events.service';
import { WindowService } from './shared/services/window/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  private events: IMainEventData[];
  isLoading: Observable<boolean>;
  loadingEvents: boolean;
  notifications$: Observable<IUserNotification[]>;
  subEvents$: Subscription;
  routeData: NavigationEnd;
  subscriptionList: Subscription = new Subscription();

  private _destroy$ = new Subject();

  constructor(
    private _store: Store<AppState>,
    private _dataLayer: DatalayerService,
    private _snackBar: MatSnackBar,
    private _flashMessageService: FlashMessageService,
    private _userStore: Store<UserState>,
    private _router: Router,
    private _eventRequestOverviewService: EventRequestOverviewService,
    private _notificationsService: NotificationsService,
    private _eventsService: EventsService,
    private _windowService: WindowService
  ) {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize() {
    if (this.routeData) {
      this.setHeader(this.routeData);
    }
  }

  ngOnInit(): void {
    this.isLoading = this._store.select(selectApp.getIsLoading).pipe(observeOn(asyncScheduler));
    console.info('AddToEvent Marketplace Version: v' + environment.version);
    this.onRoute();
    this.sendUserIdToSentry();
    this.handleFlashMessages();
    this.getEvents();
    this.notifications$ = this._store.select(userQuery.getUserNotifications);

    this._router.events.pipe(takeUntil(this._destroy$)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this.getNotifications();
      }
    });
  }

  ngAfterViewInit(): void {
    this._dataLayer.sendEvent('pageInitialised');
  }

  onRoute(): void {
    const subRoute$ = this._router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe({
      next: (event: NavigationEnd) => {
        this.routeData = event;
        this.setHeader(event);
      },
      error: err => {
        console.error('Route error: ', err);
      }
    });
    this.subscriptionList.add(subRoute$);
  }

  private setHeader(event: NavigationEnd) {
    const headerOpen =
      (!event.urlAfterRedirects.match(/invites|new-request/) && !this._windowService.isMobile()) ||
      !event.urlAfterRedirects.match(/invites|new-request/);
    this._store.dispatch(appActions.ToggleShowingHeader({ payload: headerOpen }));
  }

  private getCurrentRoute() {
    return window.location.pathname.split('/').filter(el => el !== '');
  }

  private getNotifications() {
    let showInterestedIndicator: boolean = false;
    this._store.select(authenticationQuery.getIsLoggedIn).subscribe({
      next: isLoggedIn => {
        if (isLoggedIn) {
          this._notificationsService
            .getNotifications()
            .pipe(takeUntil(this._destroy$))
            .subscribe({
              next: response => {
                showInterestedIndicator = response.some(el => el.type === 'unresolved_interested');
                this._userStore.dispatch(
                  SetInterestedState({
                    payload: showInterestedIndicator
                  })
                );

                this._store.dispatch(
                  SetUserNotifications({
                    payload: response
                  })
                );
              },
              error: err => {
                console.error(err);
              }
            });
        }
      }
    });
  }

  /**
   * Handle App wide Flash Messages
   */
  private handleFlashMessages() {
    this._flashMessageService
      .flashMessage$()
      .pipe(
        takeUntil(this._destroy$),
        switchMap(flashMessage => {
          if (flashMessage.message) {
            return combineLatest([
              of(flashMessage),
              this._snackBar
                .open(flashMessage.message, flashMessage.action || 'Close', {
                  duration: flashMessage.timeout,
                  verticalPosition: 'bottom',
                  horizontalPosition: 'start',
                  panelClass: ['snack-action']
                })
                .afterDismissed()
            ]);
          } else {
            return of([{}, {}]);
          }
        })
      )
      .subscribe(([flashMessage, event]: [FlashMessage, MatSnackBarDismiss]) => {
        if (event.dismissedByAction && flashMessage.actionCallback) {
          flashMessage.actionCallback(event);
        }
      });

    this._flashMessageService
      .isShowingFlashMessage$()
      .pipe(takeUntil(this._destroy$))
      .subscribe(isShowing => {
        if (!isShowing) {
          this._snackBar.dismiss();
        }
      });
  }

  sendUserIdToSentry() {
    this._store
      .select(authenticationQuery.getUser)
      .pipe(first())
      .subscribe({
        next: data => {
          if (data?.userId) {
            Sentry.setUser({
              id: data?.userId
            });
          }
        },
        error: msg => {
          console.error(msg);
        }
      });
  }

  private getEvents() {
    this.loadingEvents = true;
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.events = [];
    this.subEvents$ = this._store
      .select(authenticationQuery.getIsLoggedIn)
      .pipe(
        filter(isLoggedIn => isLoggedIn),
        first(),
        switchMap(() => this._eventsService.getEvents('upcoming'))
      )
      .subscribe({
        next: response => {
          this.subscriptionList.add(this.subEvents$);
          if (response) {
            this.events = response.data ? response.data : response;
            this.events.forEach(el => {
              const { name, icon_class } = this._eventRequestOverviewService.getEventIconConfig(el.mainEventType);
              el.iconName = name;
              el.iconClass = icon_class;
              el.mainEventState = 'upcoming';
              if (el.mainEventType === EventRequestTypeEnum.UNSPECIFIED_EVENT) {
                el.mainEventType = 'EVENT';
              }
            });
            this.loadingEvents = false;
            this._store.dispatch(appActions.DecrementLoadingCount());
            this._store.dispatch(appActions.SetEvents({ events: this.events }));
          }
        },
        error: err => {
          console.error('Events could not be retrieved: ', err);
          this.loadingEvents = false;
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      });
  }

  ngOnDestroy() {
    this.subscriptionList.unsubscribe();
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
