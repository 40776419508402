import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _galleryStateSource = new BehaviorSubject<boolean>(false);
  galleryState = this._galleryStateSource.asObservable();

  constructor() {}

  publishGalleryState(state: boolean) {
    this._galleryStateSource.next(state);
  }
}
