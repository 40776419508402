import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

import { EventRequestSingleType, FormResponse, IFormResponse } from '../../../../shared/services/event-request/event-request.type';
import { EventRequestService } from '../../../../shared/services/event-request/event-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { filter, Observable, Subscription } from 'rxjs';
import { appActions } from '../../../../store/actions/app.actions';
import { selectApp } from '../../../../store/selectors/app.selectors';
import { AppState } from '../../../../store/reducers/app.reducer';
import { EmptyMessageConfig } from '../../../../shared/models/misc';
import { selectEvent } from '../../../../store/selectors/event.selectors';

@Component({
  selector: 'app-event-request-details',
  templateUrl: './event-request-details.component.html',
  styleUrls: ['./event-request-details.component.scss']
})
export class EventRequestDetailsComponent implements OnInit, OnDestroy {
  apiKey = environment.googleMapsApiKey;
  emptyMessageConfig: EmptyMessageConfig;
  isLoading: Observable<boolean>;
  eventRequest: EventRequestSingleType;
  eventDescription: string;
  isError: boolean = false;
  private eventSubscription: Subscription;

  formResponses: FormResponse[];
  eventStartDate: string;
  eventStartTime: string;
  eventDuration: string;

  constructor(
    public eventRequestService: EventRequestService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _store: Store<AppState>
  ) {}

  public ngOnInit(): void {
    this.isLoading = this._store.select(selectApp.getIsLoading);
    this.subscribeToEvent();
    this.emptyMessageConfig = {
      icon: {
        name: 'ate-alert-v2',
        class: 'icon_alert'
      },
      title: 'Unknown Error: Something went wrong'
    };
  }

  public ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  goBack(): void {
    this._router.navigate(['..'], { relativeTo: this._route });
  }

  public subscribeToEvent(): void {
    this._store.dispatch(appActions.IncrementLoadingCount());
    this.isError = false;
    this.eventSubscription = this._store
      .select(selectEvent.getEvent)
      .pipe(filter(value => value !== undefined))
      .subscribe({
        next: data => {
          this._store.dispatch(appActions.DecrementLoadingCount());
          if (data) {
            this.eventRequest = data;
            const { startDate, startTime, duration, description } = this.eventRequest.event;

            this.eventDescription = description;
            this.eventStartDate = this.processStartDate(startDate);
            this.eventStartTime = this.processStartTime(startTime);
            this.eventDuration = this.processDuration(duration);

            this.formResponses = this.handleFormResponse(this.eventRequest.responses);
          }
        },
        error: msg => {
          this.isError = true;
          console.error(msg);
          this._store.dispatch(appActions.DecrementLoadingCount());
        }
      });
  }

  /**
   * Returns the time as an internationalised string.
   * @param startTime The string representation of the time
   */
  private processStartTime(startTime: string): string {
    if (!startTime) {
      return '';
    }
    const time = new Date(startTime);
    const intlTime = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(time);

    return intlTime;
  }

  /**
   * Returns the time as an internationalised string. (e.g. 2 hours 20 minutes)
   * @param duration The number of seconds
   */
  private processDuration(duration: number): string {
    if (!duration) {
      return '';
    }
    return formatDuration(intervalToDuration({ start: 0, end: duration * 1000 }), { format: ['days', 'hours', 'minutes'] });
  }

  /**
   * Returns the date as an internationalised string.
   * @param startDate The string representation of the date
   */
  private processStartDate(startDate: string): string {
    const date = new Date(startDate);

    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'medium'
    }).format(date);
  }

  /**
   * Parses the form response so that we can display the responses easily in
   * the view.
   *
   * @param formResponse The form response object
   */
  private handleFormResponse(formResponse: IFormResponse): FormResponse[] {
    return Object.values(formResponse).reduce<FormResponse[]>((responses, answer) => {
      if (!answer.value) {
        return;
      }

      return [...responses, { ...answer, isArray: Array.isArray(answer.value) }];
    }, []);
  }
}
