import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../../../../ui/components/components.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { StarRatingComponent } from '../../../../../ui/components/star-rating/star-rating.component';
import { QuoteModel } from '../../../../../shared/models/quotes';
import { TrimTextPipe } from '../../../../../shared/pipes/trim-text/trim-text.pipe';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tile-base',
  templateUrl: './tile-base.component.html',
  styleUrls: ['./tile-base.component.scss'],
  standalone: true,
  imports: [CommonModule, ComponentsModule, SharedModule, StarRatingComponent, TrimTextPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TileBaseComponent {
  @Input() item: QuoteModel;

  constructor(private _router: Router) {}

  goToQuote(): void {
    this._router.navigate(['/quote/', this.item.uuid]);
  }
}
