/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { pluck } from 'rxjs/operators';
import { Buffer } from 'buffer';
import { authenticationQuery } from '../../../store/selectors/authentication.selectors';
import { AuthenticationState } from '../../../store/reducers/authentication.reducer';
import { captureException } from '@sentry/angular-ivy';

@Injectable()
export class JWTService {
  public accessTokenTemp$: Observable<string> = this._store.pipe(select(authenticationQuery.getAuth), pluck('accessToken'));

  constructor(private _store: Store<AuthenticationState>) {}

  /**
   * Accessor method for AccessToken
   */
  public accessToken$(): Observable<string> {
    return this._store.pipe(select(authenticationQuery.getAuth), pluck('accessToken'));
  }

  /**
   * Parses a JSON Web Token to a object
   * @param jwt
   */
  // @TODO: Write return type, type definition
  public parseJWT(jwt: string): any {
    try {
      if (jwt === 'Bearer undefined') {
        return;
      }
      return JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString('binary'));
    } catch (e) {
      captureException({ e, jwt });
      throw e;
    }
  }
}
