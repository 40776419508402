<div *ngIf="dialogData?.record?.inviteData" class="supplier-container" [class.no-footer]="hideFooter">
  <div
    class="animated fadeIn supplier-details-body tw-flex tw-flex-auto"
    [ngClass]="innerWidth < 960 ? 'tw-flex-col tw-gap-4 tw-p-4' : 'tw-flex-row tw-gap-8 tw-p-8'">
    <div class="tw-flex" [ngClass]="innerWidth > 960 ? 'tw-w-1/2' : 'tw-h-2/5'">
      <app-image-grid
        class="tw-flex tw-flex-auto"
        *ngIf="dialogData?.record?.inviteData && dialogData?.record?.inviteData?.images"
        [imageUrls]="dialogData?.record?.inviteData?.images"></app-image-grid>
    </div>

    <div class="border-right-1px tw-flex tw-w-[2px]"></div>

    <div class="tw-flex" [ngClass]="innerWidth > 960 ? 'tw-w-1/2' : 'tw-h-fit'">
      <app-invite-carousel-slide [invite]="dialogData?.record?.inviteData"></app-invite-carousel-slide>
    </div>
  </div>

  <div class="supplier-details-footer tw-flex tw-flex-auto tw-grow-0 tw-items-center tw-justify-center tw-gap-4 tw-p-4" *ngIf="!hideFooter">
    <button class="btn-action btn-action-md btn-secondary btn-reject-invite" (click)="emitAction('reject')">
      <ate-icon name="ate-close-v2"></ate-icon>
      <span class="tw-mb-0" *ngIf="innerWidth > 768">Not Interested</span>
    </button>
    <button class="btn-action btn-action-md btn-primary" (click)="emitAction('invite')">
      <ate-icon name="ate-hearts"></ate-icon>
      <span class="tw-mb-0">Invite to Quote</span>
    </button>
  </div>
</div>
