<ng-container *ngIf="notifications">
  <app-notification-item
    [ngClass]="large ? 'tw-px-6' : 'tw-px-5'"
    class="tw-cursor-pointer tw-border-0 tw-border-b tw-border-solid tw-border-slate-300 tw-py-4"
    *ngFor="let notification of notifications"
    [title]="notification?.title"
    [image]="notification?.image"
    [date]="notification?.date"
    [url]="notification?.url"
    [type]="notification?.type"
    [large]="large"></app-notification-item>
</ng-container>
