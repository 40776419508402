import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { EnhancedStatusPillConfig } from './enhanced-status-pill-config.interface';

@Component({
  selector: 'app-enhanced-status-pill',
  templateUrl: './enhanced-status-pill.component.html',
  styleUrls: ['./enhanced-status-pill.component.scss'],
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EnhancedStatusPillComponent {
  @Input() config: EnhancedStatusPillConfig = {
    statusText: '',
    iconName: '',
    iconColour: '',
    textColour: '',
    backgroundColour: '',
    rightSideText: ''
  };
}
