import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { QuoteResponse } from '../../services/quote.type';
import { WindowService } from '../../../../shared/services/window/window.service';
import { environment } from '../../../../../environments/environment';
import { QuoteStatus } from '../../../../api/interfaces';

@Component({
  selector: 'app-quote-events',
  templateUrl: './quote-events.component.html',
  styleUrls: ['./quote-events.component.scss']
})
export class QuoteEventsComponent implements OnInit, OnChanges {
  @Input() quote: QuoteResponse;

  public isMobile: boolean;
  public eventPassed = false;
  public selectedSupplier = false;
  public acceptedText = '';
  public declinedText = '';

  @HostBinding('class.full-heigth') get fullHeigth() {
    return this.quote?.quoteStatus === QuoteStatus.PUBLISHED_VIEWED;
  }

  constructor(private windowService: WindowService) {}

  ngOnInit(): void {
    this.isMobile = this.windowService.isMobile();
    this.processQuote();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quote.currentValue) {
      this.processQuote();
    }
  }

  processQuote(): void {
    this.checkEventPassed();
    // @TODO
    // current API does not support dates, only booleans
    // this.handleAcceptedDate();
    // this.handleDeclinedDate();
    this.hasSelectedSupplier();
  }

  hasSelectedSupplier(): void {
    const quoteStatus = this.quote.quoteStatus;
    if (quoteStatus === QuoteStatus.ACCEPTED_FUTURE || quoteStatus === QuoteStatus.ACCEPTED_PAST || quoteStatus === QuoteStatus.PUBLISHED_REVIEWED) {
      this.selectedSupplier = true;
      return;
    }

    this.selectedSupplier = false;
  }

  // handleAcceptedDate(): void {
  //   if (this.quote?.acceptedDate) {
  //     this.acceptedText = `${
  //       this.quote?.organiser?.firstName
  //     } accepted the quote - ${format(
  //       new Date(this.quote?.acceptedDate),
  //       'EEEE d MMM - HH:mm',
  //     )}`;
  //     return;
  //   }

  //   // reset to default
  //   this.acceptedText = '';
  // }

  // handleDeclinedDate(): void {
  //   if (this.quote?.declinedDate) {
  //     const declinedBy = `${this.quote?.organiser?.firstName} declined the quote`;

  //     // TODO: designs suggest auto decline but status' don't exist

  //     this.declinedText = `${declinedBy} - ${format(
  //       new Date(this.quote?.declinedDate),
  //       'EEEE d MMM - HH:mm',
  //     )}`;
  //     return;
  //   }

  //   // reset to default
  //   this.declinedText = '';
  // }

  checkEventPassed(): void {
    if (new Date(this.quote?.event?.startTime).getTime() < new Date().getTime()) {
      this.eventPassed = true;
      return;
    }

    this.eventPassed = false;
  }

  viewReview() {
    this.windowService.open(this.quote?.review?.url, '_self');
  }

  leaveReview(): void {
    this.windowService.open(`${environment.webUrl}/node/${this.quote.uuid}/review/submit`, '_self');
  }
}
