<div class="tw-flex tw-h-full tw-flex-col">
  <div class="tw-mb-auto">
    <app-tile-base [item]="suggestedInvite"></app-tile-base>
  </div>
  <p class="tw-m-0 tw-mt-0.5 tw-text-sm tw-text-slate-600">{{ suggestedInvite?.subtitle }}</p>
  <div class="tw-mt-4 tw-flex tw-justify-stretch tw-gap-4 lg:tw-mt-5">
    <button
      (click)="skipSupplier(suggestedInvite.uuid); $event.stopPropagation()"
      class="btn-action btn-action-md btn-secondary tw-w-11 lg:tw-w-12"
      [disabled]="loadingSkipped">
      <ate-icon class="tw-size-5 tw-shrink-0 tw-animate-spin lg:tw-size-6" name="loading-spinner-v3" *ngIf="loadingSkipped"></ate-icon>
      <ate-icon class="tw-size-5 tw-shrink-0 lg:tw-size-6" name="close-v2" *ngIf="!loadingSkipped"></ate-icon>
    </button>

    <button
      (click)="inviteSupplier(suggestedInvite.uuid); $event.stopPropagation()"
      class="btn-action btn-action-md btn-outline tw-w-full"
      [disabled]="loadingInvited || suggestedInvite.invited">
      <ate-icon class="tw-size-6 tw-animate-spin tw-text-brand-gradientfrom" name="loading-spinner-v3" *ngIf="loadingInvited"></ate-icon>
      <ate-icon class="btn-outline-icon-left" name="hearts" *ngIf="!loadingInvited"></ate-icon>
      <span class="tw-mb-0" *ngIf="!suggestedInvite.invited">Invite to Quote</span>
      <span class="tw-mb-0" *ngIf="suggestedInvite.invited">Supplier Invited</span>
    </button>
  </div>
</div>
