<app-action-panel [open]="open">
  <ul>
    <li *ngFor="let social of socials">
      <a [href]="social.url" target="_blank">
        <ate-icon [attr.name]="social.type === 'website' ? 'website-v2' : social.type"></ate-icon>
        <span>{{ social.type === 'website' ? 'Supplier Website' : social.type + ' Profile' }}</span>
      </a>
    </li>
  </ul>
</app-action-panel>
