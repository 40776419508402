import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { WindowService } from '../../services/window/window.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InformationBoxComponent {
  @Input() title = '';
  @Input() showNewRequestButton = false;
  @Input() linkHref = '';
  @Input() linkText = '';
  @Input() showDismissButton = false;

  public visible = true;

  constructor(private _windowService: WindowService) {}

  openRequestQuotes() {
    this._windowService.open(environment.requestQuotesUrl, '_self');
  }
}
