import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceToNowStrict } from 'date-fns';

@Pipe({ name: 'timeAgo' })
export class TimeAgoPipeline implements PipeTransform {
  transform(value: string) {
    if (!value) return '';
    return `${formatDistanceToNowStrict(new Date(value), {})} ago`;
  }
}
