<!-- Modal Header -->
<div class="header">
  <div class="left"></div>
  <div class="center">
    <h1>{{ title }}</h1>
  </div>
  <div class="right">
    <ate-icon (click)="onClose.emit()" slot="right" [name]="headerIcon" class="clear"></ate-icon>
  </div>
</div>
<!-- Modal Body -->
<main>
  <div class="body">
    <div
      *ngIf="isLoading"
      class="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 !tw-z-[9999] tw-flex tw-items-center tw-justify-center tw-backdrop-blur-sm lg:tw-bottom-20 lg:tw-top-16">
      <ate-icon
        class="tw-size-10 tw-animate-spin tw-rounded-full tw-bg-white tw-p-1 tw-text-white"
        name="loading-spinner-gradient-v3"
        slot="start"></ate-icon>
    </div>

    <div class="inner-container">
      <header>
        <ate-icon *ngIf="step === 1" name="close-v2"></ate-icon>
        <h2 class="step-title">{{ stepTitle }}</h2>
        <p [innerHTML]="stepSubTitle"></p>
      </header>

      <ng-container [ngTemplateOutlet]="body$ | async"> </ng-container>
    </div>
  </div>
</main>
<!-- Modal Footer -->
<footer class="footer">
  <div></div>
  <div class="footer_button">
    <ng-container [ngTemplateOutlet]="footer$ | async"> </ng-container>
  </div>
</footer>

<!-- Body Templates -->
<ng-template #bodyStep0>
  <section class="body-section-small">
    <!-- TODO change to current  -->
    <app-service-list-item
      [serviceTitle]="quote?.event?.serviceName"
      [image]="quote?.primaryServiceImageURI"
      [date]="quote?.event?.startDate"
      [eventType]="quote?.event?.eventType | eventType"></app-service-list-item>

    <p class="listing-item-subtitle">Decline the following supplier:</p>

    <app-listing-list-item *ngIf="currentListing" [listing]="currentListing"> </app-listing-list-item>
  </section>
</ng-template>

<ng-template #bodyStep1>
  <section class="body-section-small">
    <app-listing-list-item *ngIf="currentListing" [listing]="currentListing"> </app-listing-list-item>
  </section>
</ng-template>

<ng-template #bodyStep2>
  <section class="body-section-medium">
    <ate-radio-button-group-control
      [attr.model-key]="'selectReason'"
      [attr.options]="checkboxSelection"
      size="xsmall"></ate-radio-button-group-control>

    <div class="skip">
      <button (click)="isOutOfBudget()"><span>Skip</span> <ate-icon name="skip-arrow"></ate-icon></button>
    </div>
  </section>
</ng-template>

<ng-template #bodyStep3>
  <section class="body-section-medium">
    <div class="suppliers-number">
      <p>Supplier’s Phone Number:</p>
      <a [href]="'tel:' + quote?.listing?.phoneNumber">{{ quote?.listing?.phoneNumber }}</a>
    </div>
    <div class="skip">
      <button (click)="submitDecline()">
        <span>I still want to decline</span>
        <ate-icon name="skip-arrow"></ate-icon>
      </button>
    </div>
  </section>
</ng-template>

<ng-template #bodyStep4>
  <div class="other_listings">
    <app-listing-list-item
      *ngFor="let listingItem of listingItems"
      (SendAction)="listingItemClick($event, listingItem)"
      [listing]="listingItem"
      [actionButtons]="true">
    </app-listing-list-item>
  </div>
</ng-template>

<!-- Footer Templates -->
<ng-template #footerStep0>
  <button class="red" (click)="updateStep(1)">
    <ate-icon name="close"></ate-icon>
    Decline Supplier
  </button>
</ng-template>

<ng-template #footerStep1>
  <button class="red-gradient" (click)="updateStep(2)">Continue</button>
</ng-template>

<ng-template #footerStep2>
  <button class="red-gradient" (click)="isOutOfBudget()" [disabled]="!confirmRequest?.selectReason">Submit Reason</button>
</ng-template>

<ng-template #footerStep3>
  <button class="red-gradient" (click)="dontDecline = true; onClose.emit()">Don’t Decline Quote</button>
</ng-template>

<ng-template #footerStep4>
  <button class="basic" (click)="dontDecline = true; onClose.emit()">
    <span>I'll do this later</span>
  </button>
</ng-template>
