import { createReducer, on, Action } from '@ngrx/store';
import { ISetError, ISetNotAuthorisedError, apiActions } from '../actions/api.actions';

export const API_FEATURE_KEY = 'api';

export interface ApiState {
  error: ISetError;
  notAuthorisedError: ISetNotAuthorisedError;
}

export const initialState: ApiState = {
  error: undefined,
  notAuthorisedError: undefined
};

const reducer = createReducer(
  initialState,
  on(apiActions.SetError, (state, { payload }) => ({
    ...state,
    payload
  })),
  on(apiActions.RemoveError, state => ({
    ...state
  })),
  on(apiActions.SetNotAuthorisedError, (state, payload) => ({
    ...state,
    payload
  })),
  on(apiActions.RemoveNotAuthorisedError, state => ({
    ...state
  }))
);

export function apiReducer(state: ApiState, action: Action) {
  return reducer(state, action);
}
