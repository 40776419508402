import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TileBaseComponent } from '../tile-base/tile-base.component';
import { EnhancedStatusPillComponent } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill.component';
import { EnhancedStatusPillConfig } from '../../../../../ui/components/enhanced-status-pill/enhanced-status-pill-config.interface';
import { InviteStatusConfig } from './invite-status-config';
import { DeclineReason } from '../../../../../shared/enums/decline-reason.enum';
import { QuoteModel } from '../../../../../shared/models/quotes';

@Component({
  selector: 'app-invite-tile',
  templateUrl: './invite-tile.component.html',
  styleUrls: ['./invite-tile.component.scss'],
  standalone: true,
  imports: [CommonModule, TileBaseComponent, EnhancedStatusPillComponent]
})
export class InviteTileComponent implements OnInit {
  @Input() invite: QuoteModel;
  statusPillConfig: EnhancedStatusPillConfig;

  ngOnInit() {
    this.setStatusPillConfig();
  }

  private setStatusPillConfig() {
    if (this.invite?.organiserStatus) {
      const inviteStatusConfig = InviteStatusConfig[this.invite.organiserStatus];

      this.statusPillConfig = { ...inviteStatusConfig, secondaryText: this.getDeclineReason() || inviteStatusConfig.secondaryText };
    }
  }

  /**
   * Maps the decline reason enum to a human-readable string.
   */
  private getInviteDeclineReason(declineReason: DeclineReason) {
    const declineReasons = ['Not available', 'Too far to travel', 'Not providing service', 'Not enough information', 'Inadequate budget', 'Other'];
    return declineReasons[declineReason];
  }

  /**
   * Returns the secondary text for the status pill.
   * Returns the default text for the status for non-declined statuses.
   */
  private getDeclineReason() {
    const { declineReason, isDeclined } = this.invite;

    return declineReason && isDeclined ? this.getInviteDeclineReason(declineReason) : '';
  }
}
