<div
  class="tw-grid tw-h-full tw-w-full tw-grid-cols-2 tw-grid-rows-2 tw-gap-4 tw-overflow-hidden"
  [ngClass]="{
    one: imageCount === 1,
    two: imageCount === 2,
    three: imageCount === 3,
    'five parent-5': imageCount === 5
  }"
  *ngIf="imageUrls">
  <div class="tw-overflow-hidden tw-rounded-lg" *ngFor="let imageUrl of imageUrls; let i = index" [ngClass]="'image' + '-' + i">
    <img class="tw-h-full tw-w-full tw-object-cover" [src]="imageUrl | replace: '{style}' : 'x_large'" alt="Supplier image" />
  </div>

  <button
    class="view-all-button tw-absolute tw-bottom-4 tw-right-4 tw-z-10 tw-hidden tw-cursor-pointer tw-items-center tw-rounded tw-border tw-border-solid tw-border-slate-300 tw-bg-white tw-px-4 tw-py-2 tw-font-default tw-text-base tw-font-bold tw-text-gray-800 lg:tw-flex"
    (click)="selectImage()"
    *ngIf="imageUrls.length">
    <ate-icon class="tw-h-5 tw-w-5 tw-shrink-0 tw-pr-2 tw-text-gray-800" name="image"></ate-icon>
    View All
  </button>
</div>

<app-lightbox>
  <app-lightbox-image *ngFor="let imageUrl of imageUrls" [url]="imageUrl | replace: '{style}' : 'x_large'"></app-lightbox-image>
</app-lightbox>
