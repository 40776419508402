import { Injectable } from '@angular/core';
import { FlashMessageService } from '../flash-message/flash-message.service';
import { FlashMessageType } from '../../enums/flash-message-type.enum';

const GENERIC_ERRORS = new Map<number, string>([
  [400, "400, We couldn't complete your action. Please try again, or refresh the page"],
  [403, '403, You are not authorised to view this page.'],
  [404, '404, Page not found.'],
  [409, '409, Unable to save. Please try again. If the issue persists please contact customer support'],
  [500, '500, There has been an API error. Please try reloading the page. If the issue persists please contact customer support.']
]);

@Injectable()
export class ErrorHandlerService {
  constructor(private _flashMessageService: FlashMessageService) {}

  handleError(status: number) {
    const message = this.getErrorMessage(status);

    if (message) {
      this._flashMessageService.show({
        flashMessageType: FlashMessageType.Error,
        message,
        ...(status === 500 && { timeout: 0 })
      });
    }
  }

  private getErrorMessage(statusCode: number) {
    return GENERIC_ERRORS.get(statusCode);
  }
}
