import { Pipe, PipeTransform } from '@angular/core';
import { EventRequestTypeEnum } from '../services/event-request/event-request-type.enum';

@Pipe({ name: 'eventType' })
export class EventTypePipeline implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return 'Event';
    }
    if (value === EventRequestTypeEnum.UNSPECIFIED_EVENT) {
      return 'Event';
    }
    const replaced = value.replace(/_/g, ' ');
    return replaced
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
}
