import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Messaging, getToken } from '@angular/fire/messaging';
import { first, from, switchMap } from 'rxjs';

@Injectable()
export class FcmNotificationsService {
  localStorageFlagKey = 'hide-fcm-notification-modal';

  constructor(
    private _http: HttpClient,
    private _messaging: Messaging
  ) {}

  setModalShownFlag() {
    localStorage.setItem(this.localStorageFlagKey, Date.now().toString());
  }

  /**
   * We should only display the modal to the user if the flag is not set in LocalStorage.
   */
  shouldDisplayModal() {
    return localStorage.getItem(this.localStorageFlagKey) === null;
  }

  /**
   * @todo Add analytics to determine if users are getting to the native permission
   * dialog and hitting yes or no.
   */
  registerForNotifications() {
    return this.getFirebaseToken().pipe(
      first(),
      switchMap(token => this.saveTokenToDB(token))
    );
  }

  private getFirebaseToken() {
    return from(getToken(this._messaging, { vapidKey: environment.vapidPublicKey }));
  }

  private saveTokenToDB(token: string) {
    return this._http.post(
      `${environment.apiUrl}/org/notification/registration-token`,
      { token },
      {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json'
        }
      }
    );
  }
}
